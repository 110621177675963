import Header from "../Header";
import Footer from "../Footer";
import Video_Junior from "../Video_Junior";
import React, { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';


function InspireChurches() {

  const [displayData, setDisplayData] = useState('data1');

  const showData1 = () => {
    setDisplayData('data1');
  };

  const showData2 = () => {
    setDisplayData('data2');
  };
  // ======================================================


  const paperback = 34.99;
  const ebook = 19.99;


  const navigate = useNavigate();
  const [input, setInput] = useState({
    qty_book1: "10",
    qty_ebook1: "10",
    qty_book3: "500",
    qty_book4: "1000",
  });


  let paperback1;


  // =============Book1==================
  if (input.qty_book1 < 10) {
    paperback1 = paperback;
  }
  if (input.qty_book1 >= 10 && input.qty_book1 <= 49) {
    paperback1 = 31.49;
  }
  if (input.qty_book1 >= 50 && input.qty_book1 <= 99) {
    paperback1 = 29.74;
  }
  if (input.qty_book1 >= 100 && input.qty_book1 <= 499) {
    paperback1 = 27.99;
  }
  if (input.qty_book1 >= 500) {
    paperback1 = 26.24;
  }
  // =============Ebook==================
  let ebook1;

  if (input.qty_ebook1 < 10) {
    ebook1 = ebook;
  }
  if (input.qty_ebook1 >= 10 && input.qty_ebook1 <= 49) {
    ebook1 = 17.99;
  }
  if (input.qty_ebook1 >= 50 && input.qty_ebook1 <= 99) {
    ebook1 = 16.99;
  }
  if (input.qty_ebook1 >= 100 && input.qty_ebook1 <= 499) {
    ebook1 = 15.99;
  }
  if (input.qty_ebook1 >= 500) {
    ebook1 = 14.99;
  }

  // =============Ebook3==================

  if (input.qty_book3 < 10) {
    paperback1 = paperback;
  }
  if (input.qty_book3 >= 10 && input.qty_book3 <= 49) {
    paperback1 = 31.49;
  }
  if (input.qty_book3 >= 50 && input.qty_book3 <= 99) {
    paperback1 = 29.74;
  }
  if (input.qty_book3 >= 100 && input.qty_book3 <= 499) {
    paperback1 = 27.99;
  }
  if (input.qty_book3 >= 500) {
    paperback1 = 26.24;
  }
  // =============Ebook4==================

  if (input.qty_book4 < 10) {
    paperback1 = paperback;
  }
  if (input.qty_book4 >= 10 && input.qty_book4 <= 49) {
    paperback1 = 31.49;
  }
  if (input.qty_book4 >= 50 && input.qty_book4 <= 99) {
    paperback1 = 29.74;
  }
  if (input.qty_book4 >= 100 && input.qty_book4 <= 499) {
    paperback1 = 27.99;
  }
  if (input.qty_book4 >= 500) {
    paperback1 = 26.24;
  }




  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value >= 1) { // Validar que el valor sea mayor o igual a 10
      setInput((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      // Manejar el caso en el que el valor es menor que 10, si es necesario
      setInput((prevState) => ({
        ...prevState,
        [name]: 1,
      }));
    }
  };

  // ====Book1
  const [error, setError] = useState("");
  const Checkbook1 = (e) => {
    e.preventDefault();
    setError("");
    if (input.qty_book1) {
      try {
        navigate('/Buy_inspire',
          {
            state: {
              qty: input.qty_book1,
              price_unite: paperback1,
              type: 'HARDCOVER',
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Error: Missing information");
    }
  }
  // ====Book2

  const [error2, setError2] = useState("");
  const Checkbook2 = (e) => {
    e.preventDefault();
    setError2("");
    if (input.qty_ebook1) {
      try {
        navigate('/Buy_inspire',
          {
            state: {
              qty_ebook: input.qty_ebook1,
              price_unite_ebook: ebook1,
              type_ebook: 'EBOOK',
            }
          });
      } catch (error2) {
        console.log(error2);
      }
    } else {
      setError2("Error: Missing information");
    }
  }

  // ====Book3
  const Checkbook3 = (e) => {
    e.preventDefault();
    setError("");
    if (input.qty_book3) {
      try {
        navigate('/Buy_inspire',
          {
            state: {
              qty: input.qty_book3,
              price_unite: paperback1,
              type: 'HARDCOVER',
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Error: Missing information");
    }
  }
  // ====Book4
  const Checkbook4 = (e) => {
    e.preventDefault();
    setError("");
    if (input.qty_book3) {
      try {
        navigate('/Buy_inspire',
          {
            state: {
              qty: input.qty_book4,
              price_unite: paperback1,
              type: 'HARDCOVER',
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Error: Missing information");
    }
  }

  // ======================================================
  return (
    <div>
      <Header />


      <div className="speaking_slider_all d-flex justify-content-center px-4">
        <div className="col-sm-10 py-4">
          <div className="row">

            <div className="col-sm-6">
              <img src={'/images/inspire/1.png'} className="w-100" />
            </div>

            <div className="col-sm-6 align-self-center">
              <center>
                <div className="">
                  <div className="text2_speaking_slider">BUY IN BULK - MAKE AN IMPACT ON YOUR CHURCH COMMUNITY</div>
                  <div className="text3_speaking_slider col-sm-8  mt-2 ">
                    What do you do when you feel lost and forgotten? And yet, you know you're meant for something greater? Brace yourself for a life-changing testimony.
                  </div>
                  <br />

                  <center>
                    <a className="btn_home_jett_7864_3  " href="#PRE-ORDER">PRE-ORDER NOW</a>
                    &nbsp;&nbsp;&nbsp;
                    <a className="btn_home_jett_7864_4" href="#CUSTOMIZED">CUSTOMIZED EDITIONS</a>

                  </center>

                </div>
              </center>
            </div>

          </div>
        </div>
      </div>

      <hr />
      <div className="container inspire_space2">

        <div className="inspire_space_btn mb-5">
          <center>
            <button className="inspire_btn1 mx-1" onClick={showData1}>HARDCOVER</button>
            <button className="inspire_btn2 mx-1" onClick={showData2}>E-BOOK</button>
          </center>
        </div>

        <div id="PRE-ORDER">
          {/* ===========HARDCOVER======= */}
          {displayData === 'data1' &&
            <div className="">
              <div className="row">
                {/* ====== */}
                <div className="col-sm-3">
                  <img src={'/images/book/4.png'} className="w-100" />
                </div>
                {/* ====== */}
                <div className="col-sm-4">
                  <center>
                    <div className="mt-3">
                      <div className="h2 py-3 border985758">PERSIST, PERSIST, PERSIST.</div>


                      <table className="w-100 text-center table9888">
                        <tbody>
                          <tr>
                            <td colSpan={3}></td>
                          </tr>
                          <tr>
                            <td>
                              <div className="inspire_bar_sep">
                                Retail Price <br />
                                Per Unit<br />
                                <span className="inspire_text_tache">${paperback}</span>
                              </div>
                            </td>
                            <td>
                              <div className="inspire_bar_sep px-3">
                                Special Price<br />
                                Per Unit<br />
                                <span className="inspire_text">${paperback1}</span>
                              </div>
                            </td>
                            <td>
                              <div className="px-3">
                                Your Current<br />
                                Discount<br />
                                <span className="inspire_text">{Number(100 - (paperback1 * 100 / paperback)).toFixed(0)}%</span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="mt-5">
                        <div className="h5">$ {Number(paperback1 * input.qty_book1).toFixed(2)} <span className="inspire_text_small">({input.qty_book1} Units)</span></div>
                        <div>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <input className="inspire_input" name="qty_book1" value={input.qty_book1} type="number" min="10" onChange={handleChange} />
                                </td>
                                <td><button onClick={(e) => { Checkbook1(e) }} className="inspire_btn_order">PRE-ORDER NOW</button></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="save_text"> YOU SAVE:&nbsp;
                        <span className="save_text1">
                          ${Number((paperback * input.qty_book1) - (paperback1 * input.qty_book1)).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </center>

                </div>
                {/* ====== */}
                <div className="col-sm-5 align-self-center">
                  <div className="bg_sec123">
                    <table className="w-100">
                      <tbody>
                        <tr>
                          <td><div className="title_sec123">QUANTITY</div></td>
                          <td><div className="title_sec123">UNIT PRICE</div></td>
                          <td><div className="title_sec123">DISCOUNT</div></td>
                        </tr>

                        <tr>
                          <td><div className="text_sec123">10-49</div></td>
                          <td><div className="text_sec123">$31.49</div></td>
                          <td><div className="text_porc_sec123">10%</div></td>
                        </tr>

                        <tr>
                          <td><div className="text_sec123">50-99</div></td>
                          <td><div className="text_sec123">$29.74</div></td>
                          <td><div className="text_porc_sec123">15%</div></td>
                        </tr>

                        <tr>
                          <td><div className="text_sec123">100-499</div></td>
                          <td><div className="text_sec123">$27.99</div></td>
                          <td><div className="text_porc_sec123">20%</div></td>
                        </tr>
                        <tr>
                          <td className="bg87574"><div className="text_sec1234">500+</div></td>
                          <td className="bg87574"><div className="text_sec1234">$26.24</div></td>
                          <td className="bg87574"><div className="text_porc_sec1234">25%</div></td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                  <div className="col-sm-10 m-auto text-center">
                    Exciting news! Purchase 500+ hardcover copies to access customized editions at no extra cost!<br />
                    <a className="more_jett001" href="#CUSTOMIZED">Learn more.</a>
                  </div>


                </div>
              </div>
            </div>
          }





          {/* ===========E_BOOK======= */}
          {displayData === 'data2' &&
            <div className="">
              <div className="row">
                {/* ====== */}
                <div className="col-sm-3">
                  <img src={'/images/book/1.png'} className="w-100" />
                </div>
                {/* ====== */}
                <div className="col-sm-4">
                  <center>
                    <div className="mt-3">
                      <div className="h2 py-3 border985758">PERSIST, PERSIST, PERSIST.</div>


                      <table className="w-100 text-center table9888">
                        <tbody>
                          <tr>
                            <td colSpan={3}></td>
                          </tr>
                          <tr>
                            <td>
                              <div className="inspire_bar_sep">
                                Retail Price <br />
                                Per Unit<br />
                                <span className="inspire_text_tache">${ebook}</span>
                              </div>
                            </td>
                            <td>
                              <div className="inspire_bar_sep px-3">
                                Special Price<br />
                                Per Unit<br />
                                <span className="inspire_text">${ebook1}</span>
                              </div>
                            </td>
                            <td>
                              <div className="px-3">
                                Your Current<br />
                                Discount<br />
                                <span className="inspire_text">{Number(100 - (ebook1 * 100 / ebook)).toFixed(0)}%</span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="mt-5">
                        <div className="h5">$ {Number(ebook1 * input.qty_ebook1).toFixed(2)} <span className="inspire_text_small">({input.qty_ebook1} Units)</span></div>
                        <div>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <input className="inspire_input" name="qty_ebook1" value={input.qty_ebook1} type="number" min="10" onChange={handleChange} />
                                </td>
                                <td><button onClick={(e) => { Checkbook2(e) }} className="inspire_btn_order">PRE-ORDER NOW</button></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="save_text"> YOU SAVE:&nbsp;
                        <span className="save_text1">
                          ${Number((ebook * input.qty_ebook1) - (ebook1 * input.qty_ebook1)).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </center>

                </div>
                {/* ====== */}
                <div className="col-sm-5 align-self-center">
                  <div className="bg_sec123">
                    <table className="w-100">
                      <tbody>
                        <tr>
                          <td><div className="title_sec123">QUANTITY</div></td>
                          <td><div className="title_sec123">UNIT PRICE</div></td>
                          <td><div className="title_sec123">DISCOUNT</div></td>
                        </tr>

                        <tr>
                          <td><div className="text_sec123">10-49</div></td>
                          <td><div className="text_sec123">$17.99</div></td>
                          <td><div className="text_porc_sec123">10%</div></td>
                        </tr>

                        <tr>
                          <td><div className="text_sec123">50-99</div></td>
                          <td><div className="text_sec123">$16.99</div></td>
                          <td><div className="text_porc_sec123">15%</div></td>
                        </tr>

                        <tr>
                          <td><div className="text_sec123">100-499</div></td>
                          <td><div className="text_sec123">$15.99</div></td>
                          <td><div className="text_porc_sec123">20%</div></td>
                        </tr>
                        <tr>
                          <td className="bg87574"><div className="text_sec1234">500+</div></td>
                          <td className="bg87574"><div className="text_sec1234">$14.99</div></td>
                          <td className="bg87574"><div className="text_porc_sec1234">25%</div></td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                  <div className="col-sm-10 m-auto text-center">
                    Exciting news! Purchase 500+ hardcover copies to access customized editions at no extra cost!<br />
                    <a className="more_jett001" href="#MORE">Learn more.</a>
                  </div>


                </div>
              </div>
            </div>


          }
        </div>
      </div>


      {/* ============================Bar-shipping*/}
      <br />  <br />  <br />
      <div className="bar_jett_sep001 d-none d-sm-block"></div>
      <div className="ship_sec mx-2">
        <div className="ship_sec_box col-sm-4 m-auto">
          <div className="row">
            <div className="col-sm-4">
              <center>
                <img src={'/images/inspire/3.png'} className="shipping_img" />
              </center>
            </div>
            <div className="col-sm-8 align-self-center">
              <center>FREE SHIPPING on all domestic orders within the USA.</center>
            </div>

          </div>
        </div>
      </div>
      {/* ============================Bar2*/}

      <div className="mt-5" id="CUSTOMIZED">
        <div className="sec_bg001">
          <div className="h2 text-white">ELEVATE YOUR CHURCH COMMUNITY WITH A CUSTOMIZED COPY!</div>
          <div className="">
            Add your church logo and/or name to the cover of "Persist, Persist, Persist." to share<br /> with your members, staff, or at fundraising events!          </div>
        </div>
      </div>
      {/* ============================ */}
      <div className="spread_box_video">
        {/*  */}
        <div className="container py-4">
          <div className="">
            <div className="col-sm-6 m-auto mb-5">
              <center>
                <img src={'/images/inspire/gift.png'} className="img_inspire_book1" />
                <div className="px-3">
                <div className="text1_inspire_book1 mt-2">PRE-ORDER 500+ COPIES & GET 25% OFF</div>
                  <div className="text2_inspire_book1 py-2">
                    Pre-order 500 copies or more and receive a VIP<br /> sticker with your brand's logo on the book cover.
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <input className="inspire_input" name="qty_book3" value={input.qty_book3} type="number" min="10" onChange={handleChange} />

                        </td>
                        <td>
                          <button onClick={(e) => { Checkbook3(e) }} className="inspire_btn_order2">PRE-ORDER NOW</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </center>
            </div>

            {/* <div className="col-sm-6 mb-5">
              <center>
                <img src={'/images/inspire/Book_Brand2.png'} className="img_inspire_book1" />
                <div className="px-3">
                  <div className="text1_inspire_book1 mt-2">PRE-ORDER 1000+ COPIES & GET 25% OFF</div>
                  <div className="text2_inspire_book1 py-2">Pre-order 1,000+ copies and access our VIP sticker<br /> with your brand's logo on the book cover.</div>

                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <input className="inspire_input" name="qty_book4" value={input.qty_book4} type="number" min="10" onChange={handleChange} />

                        </td>
                        <td><button onClick={(e) => { Checkbook4(e) }} className="inspire_btn_order2">PRE-ORDER NOW</button></td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </center>
            </div> */}


          </div>
        </div>
        {/*  */}
      </div>














      {/* ============================Part 2 */}

      <div className="cont_social_about" id="WHY">
        <div className="col-sm-6 social_about py-3 mt-4">
          <div className="text1_social_about h3 text-white">WHY CHOOSE THIS BOOK FOR YOUR </div>
          <div className="text1_social_about h3 text-white">COMMUNITY MEMBERS?</div>
        </div>
      </div>

      {/* ==============================Part 3==================== */}


      <div className="d-flex justify-content-center py-4 cont_about_part3">
        <div className="col-sm-11 about_part3 ">
          <div className=' px-5 py-5'>
            <div className="d-none d-sm-block"></div>


            <div className="part2_text_about_78599 mb-4 text-center">
              <div className="row">

                <div className="col-sm-4 border_img1_part1_2">
                  <img src={"/images/inspire/7.png"} className="new_img1_part1_2" />
                  <div className="text_2_endors_105 text-white">CAPTIVATING AND INSIGHTFUL</div>
                  <div className="text_3_endors_101 text-white">
                    "Persist, Persist, Persist." transcends being merely a book—it's a profound experience. Through its electrifying narrative and raw, emotional storytelling, this memoir captures readers' attention, encouraging deep reflection on personal challenges, resilience, and the transformative journey of discovering purpose.                  </div>
                </div>

                <div className="col-sm-4 border_img1_part1_2">
                  <img src={"/images/inspire/15.png"} className="new_img1_part1_2" />
                  <div className="text_2_endors_105 text-white">CALL TO ACTION</div>
                  <div className="text_3_endors_101 text-white">
                    The narrative serves as a call to action for church communities to actively engage in compassion and support for those in need, both locally and globally. It prompts reflection on how faith can empower individuals to overcome hardships and make a positive impact on the world.                  </div>
                </div>

                <div className="col-sm-4 ">
                  <img src={"/images/inspire/5.png"} className="new_img1_part1_2" />
                  <div className="text_2_endors_105 text-white">SHARED EXPERIENCE</div>
                  <div className="text_3_endors_101 text-white">
                    The book's compelling narrative makes it ideal for group discussions and book clubs within church communities. It provides a platform for sharing personal experiences, discussing faith journeys, and exploring how individuals can support one another through life's challenges.                  </div>
                </div>

              </div>
            </div>


          </div>
        </div>
      </div>



      <div className="py-4">
        <center>
          <a className="btn_preorder_jett001" href={'/BuyDetails'} >PRE-ORDER BOOK NOW</a>
        </center>
      </div>



      {/* ================================================= */}
      <br />
      <div className="spread_box_video">
        <div className="spread_box_video_title pt-4">WHAT PEOPLE ARE SAYING</div>
        {/*  */}
        <div className="container col-sm-9 mt-4">
          <div className="row">

            <div className="col-sm-4">
              <div className="video_part_cont mb-4">
                <iframe className='iframe_part3' src="https://player.vimeo.com/video/956163210" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                <div className="part2_video_cont py-4">
                  <div className="text1_part_cont">Review from Former Professor</div>
                  <div className="text2_part_cont ">Book review from university professor.</div>
                </div>
              </div>
            </div>


            <div className="col-sm-4">
              <div className="video_part_cont mb-4">
                <iframe className='iframe_part3' src="https://player.vimeo.com/video/957777985" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                <div className="part2_video_cont py-4">
                  <div className="text1_part_cont">Readers' Reactions </div>
                  <div className="text2_part_cont ">"It's an absolute masterpiece."</div>
                </div>
              </div>
            </div>


            <div className="col-sm-4">
              <div className="video_part_cont mb-4">
                <iframe className='iframe_part3' src="https://player.vimeo.com/video/950825473" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                <div className="part2_video_cont py-4">
                  <div className="text1_part_cont">University Student</div>
                  <div className="text2_part_cont ">Serenity's impression of 'Persist, Persist, Persist.'</div>
                </div>
              </div>
            </div>


          </div>
          <br />  <br />
        </div>
        {/*  */}
      </div>



      <Footer />
    </div>
  )
}



export default InspireChurches