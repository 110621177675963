
import React, { useEffect, useRef, useState } from 'react';

const Cloudinary = () => {
  const [uploadedUrl, setUploadedUrl] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget({
      cloudName: 'dzpvitjve',
      uploadPreset: 'eeohdbag',
      multiple: false // Enable multiple file uploads
    }, function (error, result) {
      if (!error && result && result.event === "success") {
        console.log('Uploaded image URL: ', result.info.url);
        setUploadedUrl(result.info.url);
        setSuccessMessage('Your file has been successfully added.');
      }
    });
  }, []);

  return (
    <div className='container'>
      Hello
      <br />
      <button className='btn btn-primary' onClick={() => widgetRef.current.open()}>
        Upload
      </button>
      {successMessage && (
        <div>
          <p>{successMessage}</p>
          <a href={uploadedUrl} target="_blank" rel="noopener noreferrer">{uploadedUrl}</a>
        </div>
      )}
    </div>
  );
};

export default Cloudinary;
