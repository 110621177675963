import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';

const stripePromise = loadStripe('pk_test_51N0lzfGfLs9icUEPaCwlvUIKThZdKjYqDmXcC8aXlDgf8ePccnKdTBqalQcMFbn4GnqhZfAsAmfCUK0Ezt0FwpGR00sIzdr6uz');

const StripeApp = () => {
  return (
    <Elements stripe={stripePromise}>
      <StripeCheckoutForm />
    </Elements>
  );
}

export default StripeApp;
 