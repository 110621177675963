import { Link } from 'react-router-dom'

function Subscribe_layout() {



  return (
    // ========Start Join
    <div>
      <div className="bg-black mb-1">
        <div className="container py-5">

          <div className="col-sm-6 m-auto">
            <center>
              <div className="mil_text1 mb-2">SUBSCRIBE TO</div>
              <div className="mil_text2">THE 1-MILLION COPY JOURNEY</div>
              <div className="mil_text1 mt-2">VIDEO SERIES</div>
              <div className="bar_mil my-2"></div>

              <div className="mil_text2-2"></div>
              <p className="videoSerie_text3">
                Hi, I am Junior. It took me seven years to bring “Persist, Persist, Persist.” to life.
                Today, my dream is to place this book in a million hands and make an impact in others' lives.<br />
                <span className="videoSerie_text3-3"> Will you join me in making this dream a reality?</span>
              </p>

              <div className="py-4 row col-sm-11 m-auto">
                <div className="col-sm-6 mb-4 p-0">
                  <a href="https://lp.constantcontactpages.com/sl/nibt8d2/persistbook" className="btn_login_jett px-3">JOIN THE JOURNEY FOR FREE</a>
                </div>
                <div className="col-sm-6 mb-4 p-0">
                  <a className="btn_donate_jett px-4" href="/Buy" >GET A BOOK FOR A FRIEND</a>
                </div>
              </div>


            </center>
          </div>

          <img className="main_picture_mill d-none d-sm-block" src={'/images/new2/main_picture.png'} />
          <img className="main_picture_mill d-block d-sm-none" src={'/images/new2/main_picture_mobile.png'} />

          <div className=" col-sm-7 m-auto">

            <div className="videoSerie_box1">
              <div className="row">
                <div className="col-sm-6 text-center videoSerie_text4 bar_sep1001 opacity-50">
                  Insightful - Motivating - Unpredictable
                </div>
                <div className="col-sm-6 text-center videoSerie_text4 opacity-50">
                  <img src={'/images/icons/star.png'} className="icon_star" /> Exclusive to Subscribers Only
                </div>
              </div>
            </div>

          </div>
          {/* ======= */}
          <div className="jett_scrollmenu bg_scroll_jett mt-2">
            <a href="https://lp.constantcontactpages.com/sl/nibt8d2/persistbook">
              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont">
                  <img src={'/images/new2/3.png'} className="img_podcast_recent" />
                </div>
              </div>

              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont">
                  <img src={'/images/new2/4.png'} className="img_podcast_recent" />
                </div>
              </div>

              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont">
                  <img src={'/images/new2/2.png'} className="img_podcast_recent" />
                </div>
              </div>

              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont">
                  <img src={'/images/new2/5.png'} className="img_podcast_recent" />
                </div>
              </div>
            </a>

          </div>

          <center>
            <div className=" mt-5">
              <a href="https://lp.constantcontactpages.com/sl/nibt8d2/persistbook" className="btn_Video_serie1">JOIN THE JOURNEY FOR FREE</a>
            </div>
          </center>








        </div>
      </div>

    </div>
    // ========End header
  )
}

export default Subscribe_layout