import Header from "../Header";
import Footer from "../Footer";
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";

function Profil() {
  const user = JSON.parse(localStorage.getItem('user-info'));
  const navigate = useNavigate();


  useEffect(() => {
    if (!localStorage.getItem('user-info')) {
      navigate('/login')
    }
  })

  return (
    <div>
      <Header />


      <div className=" row mt-5">

        <div className="profil_jett col-sm-8">
          <div className="row">

            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img src="/images/icons/avatar.png" alt="Admin" className="rounded-circle" width="150" />
                    <div className="mt-3">
                      <h4>{user && user.firstname + ' ' + user.lastname}</h4>
                      <p className="text-secondary mb-1">{user && user.email}</p>
                      <p className="text-muted font-size-sm"><strong>Phone:</strong> {user && user.phone}</p>

                      <a href="" className="btn btn-outline-primary" ><i className="bi bi-pencil"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div className="col-md-8">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">First Name</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {user && user.firstname}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Last Name</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {user && user.lastname}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Email</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {user && user.email}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Phone</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {user && user.phone}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Status</h6>
                    </div>
                    <div className="col-sm-9 text-success">
                      {user && user.status}
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>



      <div className="profil_jett card col-sm-8 mb-5">
        <div className="row">
          <div className="col-sm-7"><h3>You Influencer Link to Buy the Book:</h3></div>
          <div className="col-sm-5"><a href="">https://juniorpersist.com/Jett1923</a></div>
        </div>
        
        
      </div>








      <Footer />
    </div>
  )
}


export default Profil