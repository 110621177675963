import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function Tour() {


  return (
    <div>
      <Header />
      <div className="book">
        <center>
          <div className="mt-4 slider_book_text1">Event Calendar</div>
          <div className="tour_text_1">Stalkers welcome <span className="slider_book_text2">no, not really</span></div>
        </center>
      </div>


      <div className="py-5">
        <div className="text-center tour_title_text1">Interested in joining Junior Bernard in a Mastermind?</div>
      </div>




      <div className="container col-sm-9 mb-5">
        <div className="tour_bar">
          <span className="px-3 tour_box_date">March 16, 2024</span>
          <span className="px-3 tour_box_event">Online</span>
        </div>

        <div className="py-3">
          <div className="text_enter_event1">The Camp Annuel Convention</div>
          <div className="text_enter_event2">March 16, 2024  6:30 am EDT - 7:30 am EDT</div>
          <div className="text_enter_event2">Rancho Mirage, CA 92270, USA</div>
          <div className="text_enter_event2">Mike Michalowicz, Author of Profit First, speaks at The Camp Annual Convention</div>
        </div>
      </div>



      <div className="container col-sm-9 mb-5">
        <div className="tour_bar">
          <span className="px-3 tour_box_date">March 16, 2024</span>
          <span className="px-3 tour_box_event">Online</span>
        </div>

        <div className="py-3">
          <div className="text_enter_event1">The Camp Annuel Convention</div>
          <div className="text_enter_event2">March 16, 2024  6:30 am EDT - 7:30 am EDT</div>
          <div className="text_enter_event2">Rancho Mirage, CA 92270, USA</div>
          <div className="text_enter_event2">Mike Michalowicz, Author of Profit First, speaks at The Camp Annual Convention</div>
        </div>
      </div>


      <Footer />
    </div>
  )
}


export default Tour