import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";


function Speaking() {

  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");

  const [name_company, setName_company] = useState("");
  const [phone_company, setPhone_company] = useState("");
  const [website_company, setWebsite_company] = useState("");
  const [address_company, setAddress_company] = useState("");

  const [name_event, setName_event] = useState("");
  const [theme_event, setTheme_event] = useState("");
  const [date_event, setDate_event] = useState("");
  const [budget_event, setBudget_event] = useState("");
  const [venue_name_address, setVenue_name_address] = useState("");
  const [closest_airport, setClosest_airport] = useState("");

  const [tickets_be_sold, setTickets_be_sold] = useState("");
  const [number_attendees, setNumber_attendees] = useState("");
  const [open_public, setOpen_public] = useState("");
  const [event_recorded, setEvent_recorded] = useState("");

  const [event_start_time, setEvent_start_time] = useState("");
  const [speaking_time, setSpeaking_time] = useState("");
  const [expected_duration, setExpected_duration] = useState("");
  const [presentation_topic, setPresentation_topic] = useState("");
  const [additional_information, setAdditional_information] = useState("");

  async function addData(e) {
    e.preventDefault();
    setError("");

    if (firstname && lastname && email && position) {
      console.warn(firstname, lastname, email, position,
        name_company, phone_company, website_company, address_company,
        name_event, theme_event, date_event, budget_event, venue_name_address, closest_airport,
        tickets_be_sold, number_attendees, open_public, event_recorded,
        event_start_time, speaking_time, expected_duration, presentation_topic, additional_information

      )

      const formData = new FormData();
      formData.append('firstname', firstname);
      formData.append('lastname', lastname);
      formData.append('email', email);
      formData.append('position', position);

      formData.append('name_company', name_company);
      formData.append('phone_company', phone_company);
      formData.append('website_company', website_company);
      formData.append('address_company', address_company);

      formData.append('name_event', name_event);
      formData.append('theme_event', theme_event);
      formData.append('date_event', date_event);
      formData.append('budget_event', budget_event);
      formData.append('venue_name_address', venue_name_address);
      formData.append('closest_airport', closest_airport);

      formData.append('tickets_be_sold', tickets_be_sold);
      formData.append('number_attendees', number_attendees);
      formData.append('open_public', open_public);
      formData.append('event_recorded', event_recorded);

      formData.append('event_start_time', event_start_time);
      formData.append('speaking_time', speaking_time);
      formData.append('expected_duration', expected_duration);
      formData.append('presentation_topic', presentation_topic);
      formData.append('additional_information', additional_information);

      try {
        // let result = await fetch("http://127.0.0.1:8000/api/addSpeaking", {
        let result = await fetch("https://juniorpersist.com/public/public/api/addSpeaking", {

          method: 'POST',
          body: formData
        });
        navigate('/Success_message')
      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Error: (Firstname, Lastname, Position, Email) field required")
    }
  }

  return (
    <div>
      <Header />

      <div className="speaking_slider_all d-flex justify-content-center px-4">
        <div className="col-sm-9 py-4">
          <div className="row">
            <div className="col-sm-6 align-self-center">
              <div className="px-2">
                <div className="text1_speaking_slider">SPEAKING & FUNDRAISING</div>
                <div className="text2_speaking_slider"> INSPIRE YOUR AUDIENCE WITH A UNIQUE STORY </div>
                <div className="text3_speaking_slider  mt-2">Invite Junior to speak at your next event and inspire your audience like never before!</div>
                <br />
                <div className="">
                  <a className="btn_home_jett_7864_3 px-5" href="#GETSTARTED">GET STARTED</a>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <img src={'./images/img/persist_img1.png'} className="img_84774" />
            </div>
          </div>
        </div>
      </div>
      {/* ==================================== */}
      <div className="py-1">
      <div className="spread_box_video">
        <div className="spread_box_video_title pt-4">WATCH JUNIOR’S VIDEOS</div>
        {/*  */}
        <div className="container col-sm-9 mt-4">
          <div className="row">

            <div className="col-sm-4">
              <div className="video_part_cont mb-4">
                <iframe className='iframe_part3' src="https://player.vimeo.com/video/983640057" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                <div className="part2_video_cont py-4">
                  <div className="text1_part_cont">Hire Junior for Your Next Event</div>
                  <div className="text2_part_cont ">A must-hear unique story.</div>
                </div>
              </div>
            </div>


            <div className="col-sm-4">
              <div className="video_part_cont mb-4">
                <iframe className='iframe_part3' src="https://player.vimeo.com/video/983352557" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                <div className="part2_video_cont py-4">
                  <div className="text1_part_cont">The Old Dictionary </div>
                  <div className="text2_part_cont ">How this old book changed my life.</div>
                </div>
              </div>
            </div>


            <div className="col-sm-4">
              <div className="video_part_cont mb-4">
                <iframe className='iframe_part3' src="https://player.vimeo.com/video/985183455" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                <div className="part2_video_cont py-4">
                  <div className="text1_part_cont">Junior's Graduation Speech (2014)</div>
                  <div className="text2_part_cont ">Have the guts to persist.</div>
                </div>
              </div>
            </div>


          </div>
          <br />  <br />
        </div>
        {/*  */}
      </div>
      </div>
      {/* ============================Part 2 */}

      <div className="cont_social_about" id="WHY">
        <div className="col-sm-6 social_about py-3">
          <div className="text1_social_about">ARE YOU READY?</div>
          <div className="text2_social_about">
            Junior is available to speak at your company, church, or school to inspire your community.
          </div>
        </div>
      </div>


      {/* ==============================Part 3==================== */}


      <div className="d-flex justify-content-center py-4 cont_about_part3">
        <div className="col-sm-10 about_part3 ">
          <div className=' px-5 py-5'>
            <div className="d-none d-sm-block"><br /><br /><br /><br /></div>


            <div className="part2_text_about_78599 mb-4 text-center">
              <div className="row">

                <div className="col-sm-4 border_img1_part1_2">
                  <img src={"./images/speaking/3.png"} className="img1_part1_2" />
                  <div className="text_2_endors_101 text-white">SCHOOLS</div>
                  <div className="text_3_endors_101 text-white">
                    Are you a college student grappling with aimlessness, lacking motivation, or seeking insights on making the most of your time? Get ready for an unforgettable experience!
                  </div>
                </div>

                <div className="col-sm-4 border_img1_part1_2">
                  <img src={"./images/speaking/2.png"} className="img1_part1_2" />
                  <div className="text_2_endors_101 text-white">COMPANIES</div>
                  <div className="text_3_endors_101 text-white">
                    In the ever-changing business landscape, Junior’s story provides an unconventional approach to building persistence for success in both business and personal lives.
                  </div>
                </div>

                <div className="col-sm-4 ">
                  <img src={"./images/speaking/1.png"} className="img1_part1_2" />
                  <div className="text_2_endors_101 text-white">CHURCHES</div>
                  <div className="text_3_endors_101 text-white">
                    Discover your life's calling through Junior's transformative testimony and unique journey—get ready for a life-changing experience.
                  </div>
                </div>

              </div>
            </div>


          </div>
        </div>
      </div>


      <div className="container"><hr /></div>


      <div className="content_form_endor" id="GETSTARTED">

        <div className=" d-flex justify-content-center">
          <div className="col-sm-4  book_down2 "> <span className="jett_text_7464">GET STARTED!</span></div>
        </div>
        <div className="black_bar_book_down new_cartact_jett_001"></div>
        <div className="d-none d-sm-block"> <br /><br /></div>
        <br />


        <div className="form_endor col-sm-7 mt-5 px-4">


          <div className="cont_form_speaking">
            <div className="row_form_speaking">
              <h4 className="">ABOUT YOU</h4>
              {error && <div className='error_text py-4'>{error}</div>}
              <div className="row">
                <div className="col-sm-3">
                  <label className="label_speaking_form">First Name</label>
                  <input className="input_form_endor jett_back_input_speaking" type="text" id="firstname" required="" onChange={(e) => setFirstname(e.target.value)} />
                </div>
                <div className="col-sm-3">
                  <label className="label_speaking_form">Last Name</label>
                  <input className="input_form_endor jett_back_input_speaking" type="text" id="lastname" required="" onChange={(e) => setLastname(e.target.value)} />
                </div>
                <div className="col-sm-3">
                  <label className="label_speaking_form">Position</label>
                  <input className="input_form_endor jett_back_input_speaking" type="text" id="position" required="" onChange={(e) => setPosition(e.target.value)} />
                </div>
                <div className="col-sm-3">
                  <label className="label_speaking_form">Email</label>
                  <input className="input_form_endor jett_back_input_speaking" type="email" id="email" required="" onChange={(e) => setEmail(e.target.value)} />
                </div>
              </div>
            </div>
          </div>


          <div className="cont_form_speaking mt-4">
            <div className="row_form_speaking">
              <h4 className="">COMPANY INFORMATION</h4>
              <div className="row">
                <div className="col-sm-4">
                  <label className="label_speaking_form">Company Name</label>
                  <input className="input_form_endor jett_back_input_speaking" type="text" id="name_company" required="" onChange={(e) => setName_company(e.target.value)} />

                </div>
                <div className="col-sm-4">
                  <label className="label_speaking_form">Company Phone</label>
                  <input className="input_form_endor jett_back_input_speaking" type="text" id="phone_company" required="" onChange={(e) => setPhone_company(e.target.value)} />

                </div>
                <div className="col-sm-4">
                  <label className="label_speaking_form">Company Website</label>
                  <input className="input_form_endor jett_back_input_speaking" type="text" id="website_company" required="" onChange={(e) => setWebsite_company(e.target.value)} />

                </div>


                <div className="col-sm-12">
                  <label className="label_speaking_form">Company Address</label>
                  <textarea className="input_form_endor jett_back_input_speaking" required="" id="address_company" onChange={(e) => setAddress_company(e.target.value)}  ></textarea>

                </div>

              </div>
            </div>
          </div>


          <div className="cont_form_speaking mt-4">
            <div className="row_form_speaking">
              <h4 className="">EVENT DETAILS</h4>
              <div className="row">
                <div className="col-sm-4">
                  <label className="label_speaking_form">Event Name</label>
                  <input className="input_form_endor jett_back_input_speaking" type="text" id="name_event" required="" onChange={(e) => setName_event(e.target.value)} />
                </div>
                <div className="col-sm-4">
                  <label className="label_speaking_form">Event Theme</label>
                  <input className="input_form_endor jett_back_input_speaking" type="text" id="theme_event" required="" onChange={(e) => setTheme_event(e.target.value)} />
                </div>
                <div className="col-sm-4">
                  <label className="label_speaking_form">Event Date(s) of Interest</label>
                  <input className="input_form_endor jett_back_input_speaking" type="date" id="date_event" required="" onChange={(e) => setDate_event(e.target.value)} />
                </div>
                <div className="col-sm-6">
                  <label className="label_speaking_form">Event Budget for Speaker</label>
                  <input className="input_form_endor jett_back_input_speaking" type="text" id="budget_event" required="" onChange={(e) => setBudget_event(e.target.value)} />
                </div>

                <div className="col-sm-6">
                  <label className="label_speaking_form">Venue Name and Address</label>
                  <textarea className="input_form_endor jett_back_input_speaking" id="venue_name_address" required="" onChange={(e) => setVenue_name_address(e.target.value)}></textarea>
                </div>
                <div className="col-sm-12">
                  <label className="label_speaking_form">Closest Airport</label>
                  <textarea className="input_form_endor jett_back_input_speaking" id="closest_airport" required="" onChange={(e) => setClosest_airport(e.target.value)} placeholder="(Include travel time from airpot to venue)"></textarea>
                </div>

              </div>

              <div className="row">
                <div className="col-sm-6">
                  <label className="label_speaking_form">Will Tickets Be Sold for This Event?</label>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="tickets_be_sold" id="tickets_be_sold_Yes" value={'Yes'} onChange={(e) => setTickets_be_sold(e.target.value)} />
                    <label className="form-check-label" for="tickets_be_sold_Yes">Yes</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="tickets_be_sold" id="tickets_be_sold_No" value={'No'} onChange={(e) => setTickets_be_sold(e.target.value)} />
                    <label className="form-check-label" for="tickets_be_sold_No">No</label>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-sm-3">
                  <label className="label_speaking_form">Number of Attendees</label>
                  <input className="input_form_endor jett_back_input_speaking" type="text" id="number_attendees" required="" onChange={(e) => setNumber_attendees(e.target.value)} />

                </div>
                <div className="col-sm-4">
                  <label className="label_speaking_form">Will This Event Be Open to the Public?</label>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" id="open_public_Yes" name="open_public" value={'Yes'} onChange={(e) => setOpen_public(e.target.value)} />
                    <label className="form-check-label" for="open_public_Yes">Yes</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" id="open_public_No" name="open_public" value={'No'} onChange={(e) => setOpen_public(e.target.value)} />
                    <label className="form-check-label" for="open_public_No">No</label>
                  </div>
                </div>

                <div className="col-sm-5">
                  <label className="label_speaking_form">Will This Event Be Recorded?</label>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" id="event_recorded_Yes" name="event_recorded" value={'Yes'} onChange={(e) => setEvent_recorded(e.target.value)} />
                    <label className="form-check-label" for="event_recorded_Yes">Yes</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" id="event_recorded_No" name="event_recorded" value={'No'} onChange={(e) => setEvent_recorded(e.target.value)} />
                    <label className="form-check-label" for="event_recorded_No">No </label>
                  </div>
                </div>

              </div>





              <div className="cont_form_speaking mt-4">
                <div className="row_form_speaking">
                  <h4 className="">PRESENTATION/SPEECH DETAILS</h4>
                  <div className="row">
                    <div className="col-sm-3">
                      <label className="label_speaking_form">Event Start Time</label>
                      <input className="input_form_endor jett_back_input_speaking" type="text" id="event_start_time" required="" onChange={(e) => setEvent_start_time(e.target.value)} />
                    </div>
                    <div className="col-sm-3">
                      <label className="label_speaking_form">Speaking Time</label>
                      <input className="input_form_endor jett_back_input_speaking" type="text" id="speaking_time" required="" onChange={(e) => setSpeaking_time(e.target.value)} />
                    </div>
                    <div className="col-sm-3">
                      <label className="label_speaking_form">Expected Duration</label>
                      <input className="input_form_endor jett_back_input_speaking" type="text" id="expected_duration" required="" onChange={(e) => setExpected_duration(e.target.value)} />
                    </div>
                    <div className="col-sm-3">
                      <label className="label_speaking_form">Presentation Topic</label>
                      <input className="input_form_endor jett_back_input_speaking" type="text" id="presentation_topic" required="" onChange={(e) => setPresentation_topic(e.target.value)} />
                    </div>

                    <div className="col-sm-12">
                      <label className="label_speaking_form">Additional Information</label>
                      <textarea className="input_form_endor jett_back_input_speaking" id="additional_information" required="" onChange={(e) => setAdditional_information(e.target.value)}></textarea>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

          <hr />


          <center>
            <button className="btn_home_jett_7864 px-5" onClick={addData} >Submit</button>
          </center>

        </div>
      </div>

      <br />  <br />

      <Footer />
    </div>
  )
}



export default Speaking