import Header from "../Header";
import Footer from "../Footer";
import '../css/read.css';


function Blog() {

  // =================================
  return (
    <div className="content_blog">

      <div className="row ">
        {/* ==============Sidebar================ */}
        <div className="col-sm-3">
          <div className="content_sidebar">
            <div className="bar1_sidebar"></div>
            <div className="bar2_sidebar"></div>
            <div className="d-none d-sm-block">
              <div className="content_sidebar_contenue">

                <table>
                  <tr>
                    <td>
                      <img className="image_circle_blog" src={'/images/users/user1.png'} />
                    </td>
                    <td>
                      <div className="title1_blog">JUNIOR'S BLOG</div>
                    </td>
                  </tr>
                </table>


                <div className="content2_sidebar mt-3">

                  <div className="search_text">Search</div>
                  <div className="">
                    <table className="table_blog">
                      <tr>
                        <td> <input className="input_blog input2" type="search" name="" /></td>
                        <td> <button className="btn_blog btn1"><i className="bi bi-search"></i></button></td>
                      </tr>
                    </table>
                    <div className="py-2 text2_blog">Or try my new Al powered search bot</div>
                  </div>


                  <div className="search_text mt-4">Subscribe</div>
                  <div className="">
                    <table className="table_blog">
                      <tr>
                        <td> <input className="input_blog2 input2" type="search" name="" placeholder="me@email.com" /></td>
                        <td> <button className="btn_blog2 btn1">Sign Up</button></td>
                      </tr>
                    </table>
                  </div>
                  <div className="py-2 text2_blog">Email Terms & Privacy</div>


                  <div className="search_text mt-4">Get the weekly digest</div>
                  <div className="">
                    <table className="table_blog">
                      <tr>
                        <td> <input className="input_blog3 input2" type="search" name="" placeholder="type your email..." /></td>
                        <td> <button className="btn_blog3 btn1">Subcribe</button></td>
                      </tr>
                    </table>
                  </div>

                  <div className="search_text mt-4">Learn</div>
                  <div className="">Which workshop</div>
                  <div className="">AlMBA</div>

                </div>


              </div>
            </div>
          </div>
        </div>








        <div className="col-sm-9">
          <div className="blog_scrol_jett">

            {/* ================================================= */}
            <div className="row">
              <div className="col-sm-8">
                <div>
                  <div className="blog_title1_main">Explaining yourself</div>
                  <div className="blog_text1_main">
                    Au cœur des Caraïbes, sur l'île d'Haïti,
                    Se trouve un trésor d'histoire, de lutte et de vie.
                    La Citadelle Laferrière, forteresse de renom,
                    Émerge des montagnes, telle une vision.
                    <br /><br />
                    Elle se tient là, depuis des siècles,
                    Gardienne des souvenirs et des récits épiques.
                    Construite par les mains des hommes libres,
                    Elle représente un passé qui enivre.
                    <br /><br />
                    La Citadelle Laferrière, bastion de l'histoire,
                    Se dresse au nord d'Haïti, couverte de gloire.
                    Construite pour protéger la jeune nation,
                    Elle symbolise la force et la détermination.
                  </div>

                </div>
                <br />
                <div className="bar_post_date">March 15,2024</div>
                <div className="">
lll
                </div>
                {/* ================================================= */}
              </div>
              <div className="col-sm-4">
                dfsdf sdfsdf
              </div>

            </div>




          </div>
        </div>
      </div>
    </div>
  )
}


export default Blog;








