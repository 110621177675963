import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom';

function Donate_payment1000() {

  // ==========Input============
  const [val, setVal] = useState("1000")
  const change = event => {
    setVal(event.target.value)
  }
  //  =========================


  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Sunflower",
            amount: {
              currency_code: "USD",
              value: val,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
      navigate('../Donate_Success')
    });
  };
  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
    navigate('../Donate')
  };




  return (
    <PayPalScriptProvider
      options={{
        "client-id": "AVRfkzj1lNDb1NLTdQBgf2qLz6jppRDP3AG8lDF31MKm22LTJie4kAT_KC_vQYIgfITBwMD228DqJt1a&disable-funding=paylater,venmo,card",
      }}
    >
      <div>
        <Header />











        <div className="container py-5">
          <div className="donate_bar3">
            <div className="h3 text-white cont763658 ">PAYMENT METHOD </div>
          </div>
          <div className="mt-4">
            <center>

              <div className="pb-2">Enter your amount</div>
              <div className="donate_bg_99  py-2 px-2">
                {/* ===================== */}
                <input type="number" required step="any"  className="input_somme_jett_new"  onChange={change} value={val} />
                {/* ===================== */}
              </div>




              <hr />

              <div className="d-flex justify-content-center">
                <div className="">
                  <PayPalButtons style={{ layout: "vertical" }} createOrder={createOrder} onApprove={onApprove} />
                </div>
                <div className=""> </div>
              </div>



            </center>
          </div>



        </div>








        <Footer />
      </div>
    </PayPalScriptProvider>
  )
}


export default Donate_payment1000