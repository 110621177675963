import { Link } from 'react-router-dom'

function Supporters() {

  
  

  return (
    // ========Start Join
    <div>
<center>


      <div className='support_home'>
        <div className='jett_scrollmenu'>

          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/M_MANOUCHEKA.png'} />
            <div>A. Manoucheka</div>
          </div>

          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/B_DANA.png'} />
            <div>B. Dana</div>
          </div>

          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/C_ALEX.png'} />
            <div>C. Alex</div>
          </div>

          {/* <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/B_WILLIAM.png'} />
            <div>B. William</div>
          </div> */}

          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/C_CARLOS.png'} />
            <div>C. Carlos</div>
          </div>

          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/R_FLORE.png'} />
            <div>R. Flore</div>
          </div>


          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/B_ERIC.png'} />
            <div>B. Eric</div>
          </div>

          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/D_CIENDY.png'} />
            <div>D. Ciendy</div>
          </div>

          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/G_EVENS.png'} />
            <div>A. Ceide</div>
          </div>
          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/2.jpeg'} />
            <div>JP. Jeffson</div>
          </div>

          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/1.jpeg'} />
            <div>M. Aleks</div>
          </div>
        
        
          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/3.jpeg'} />
            <div>D. Mansi</div>
          </div>
        
          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/4.jpeg'} />
            <div>B. Mark</div>
          </div>
        
         <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/B_Bernardo.png'} />
            <div>B. Bernardo</div>
          </div>
        
          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/B_Brian.png'} />
            <div>B. Brian</div>
          </div>
        
          <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/B_Alcide.png'} />
            <div>B. Alcide</div>
          </div>
         
        
        <div className='col-sm-2 mb-2 jett_scrollmenu_in'>
            <img className='img_support_home px-1' src={'./images/supporters/5.jpeg'} />
            <div>A. Justin</div>
          </div>
        
         

      

        </div>
      </div>
      </center>
    </div>
    // ========End header
  )
}

export default Supporters