import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";

function Podcast_ayisyen_kapab() {
  const navigate = useNavigate();
  const [firstname, setFistname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");

  const [expertise, setExpertise] = useState("");
  const [main_topics_are_you_excited, setMain_topics_are_you_excited] = useState("");
  const [anything_off_limits, setAnything_off_limits] = useState("");
  const [more_about_any_programs, setMore_about_any_programs] = useState("");
  const [include_bio_introduce, setInclude_bio_introduce] = useState("");
  const [list_any_social_handles, setList_any_social_handles] = useState("");
  const [any_previously_recorded_podcasts, setAny_previously_recorded_podcasts] = useState("");

  const [error, setError] = useState("")

  async function addData(e) {
    e.preventDefault();
    setError("")
    if (firstname && lastname && email && expertise && main_topics_are_you_excited && anything_off_limits && more_about_any_programs && include_bio_introduce && list_any_social_handles && any_previously_recorded_podcasts) {

      console.warn(firstname, lastname, email, expertise, main_topics_are_you_excited, anything_off_limits, more_about_any_programs, include_bio_introduce, list_any_social_handles, any_previously_recorded_podcasts)
      const formData = new FormData();
      formData.append('firstname', firstname);
      formData.append('lastname', lastname);
      formData.append('email', email);

      formData.append('expertise', expertise);
      formData.append('main_topics_are_you_excited', main_topics_are_you_excited);
      formData.append('anything_off_limits', anything_off_limits);
      formData.append('more_about_any_programs', more_about_any_programs);
      formData.append('include_bio_introduce', include_bio_introduce);
      formData.append('list_any_social_handles', list_any_social_handles);
      formData.append('any_previously_recorded_podcasts', any_previously_recorded_podcasts);
      formData.append('type_podcast', 'Podcast Ayisyen Kapab');
      try {
        let result = await fetch("https://juniorpersist.com/public/public/api/addPodcast", {
        // let result = await fetch("http://127.0.0.1:8000/api/addPodcast", {
          method: 'POST',
          body: formData
        });
        navigate('/Success_message')
      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Erè: tout bwa teks yo obligatwa");
    }
  }


  return (
    <div>
      <Header />

      <div className="podcast_all">
        <div className="podcast_ayi_kapab">

          <div className="row py-5">
            <center>
              <div className="col-sm-5 py-5">
                <div className="text_slider">

                  <div className="podcast_box">
                    <div className="new_about_part1">
                      <div className="new_about_text1">AYISYEN KAPAB SHOW</div>
                      <div className="new_about_text2 ">Vin swiv istwa ayisyen kap fè pwogrè<br /> atravè lemond antye!</div>
                    </div>
                    <div className="new_about_part2 mt-3">
                      <div className="bg-black py-3">
                        <div className="new_about_part2_box1  ">DEVNI YON ENVITE SOU PODCAST LA</div>
                        <div className="mb-3"><a href="#INTERVIEW"  className="btn1_podcast_box px-3 py-1">ANN ALE</a></div>
                        <img src={'/images/icons/ayisyenkapab.png'} className="img_7464" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </center>
          </div>

          {/* ================ */}
          <div className="new_about_bar_menu ">
            <center>
              <div className="col-sm-10 ">
                <div className="row">
                  <div className="col-sm-4 py-3">
                    <a href={'#RECENT'} className="new_about_bar_menu_btn"><img className="bg-white rounded-circle icon_0098" src={'/images/icons/micro.png'} /> TANDE EPIZOD YO</a>
                  </div>
                  <div className="col-sm-4 py-3">
                    <a href="https://www.youtube.com/@juniorpersist" target="_blank" className="new_about_bar_menu_btn"><img className="icon_0098" src={'/images/icons/youtube.png'} /> SWIV SHOW A SOU YOUTUBE</a>
                  </div>
                  <div className="col-sm-4 py-3">
                    <a href={'#INTERVIEW'} className="new_about_bar_menu_btn"><img className="bg-white rounded-circle icon_0098" src={'/images/icons/interview.png'} /> KESYON POU ENVITE YO</a>
                  </div>
                </div>
              </div>
            </center>
          </div>



        </div>







        {/* ==============================Part 1_2==================== */}
        <div id="RECENT" className="py-4">
          <div className="part2_text_about_78588 mb-2 text-black text-center">DÈNYE EPIZÒD YO</div>


          <div className="container">
            <div className="jett_scrollmenu">


              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <img src={'/images/podcast/ayisyen_kapab/1.png'} className="img_podcast_recent" />
                  <center>
                    <img src={'/images/icons/micro.png'} className="icon_podcast_recent" />
                    <div className="bar_podcast_recent"></div>
                  </center>
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">Sante Mantal: Youn Nan Pi Gwo Problem an Ayiti</div>
                    <div className="text2_podcast_recent">Konsèy Yon Sikològ</div>
                  </div>
                  <br />
                </div>
              </div>

              

              
 


 




              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <img src={'/images/podcast/ayisyen_kapab/2.png'} className="img_podcast_recent" />
                  <center>
                    <img src={'/images/icons/micro.png'} className="icon_podcast_recent" />
                    <div className="bar_podcast_recent"></div>
                  </center>
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">Pi Gwo Kle Chanjman Lavi Yon Malere Se Edikasyon</div>
                    <div className="text2_podcast_recent">Dr. Boutroce</div>
                  </div>
                  <br />
                </div>
              </div>

              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <img src={'/images/podcast/ayisyen_kapab/3.png'} className="img_podcast_recent" />
                  <center>
                    <img src={'/images/icons/micro.png'} className="icon_podcast_recent" />
                    <div className="bar_podcast_recent"></div>
                  </center>
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">Men Poukisa Ti Tan Ou Genyen An Se Pi Gwo Riches</div>
                    <div className="text2_podcast_recent">Mallaid Magloire</div>
                  </div>
                  <br />
                </div>
              </div>

              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <img src={'/images/podcast/ayisyen_kapab/4.png'} className="img_podcast_recent" />
                  <center>
                    <img src={'/images/icons/micro.png'} className="icon_podcast_recent" />
                    <div className="bar_podcast_recent"></div>
                  </center>
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">Yon Entèvyou Espesyal Avèk Chef Cassy</div>
                    <div className="text2_podcast_recent">Istwa Chef Cassy</div>
                  </div>
                  <br />
                </div>
              </div>


            </div>
          </div>
        </div>

        {/* ==============================Part 1_2==================== */}
        <div className="bar_separation1_podcast"></div>
        {/* ==============================Part 1_2==================== */}
        {/* ==============================Part 1_2==================== */}


        <div className="podcast_part2 d-flex justify-content-center">

          <div className="row col-sm-10 ">
            <div className="col-sm-5 align-self-center">
              <div className="part2_text_about_78588 mb-2 text-black">BYENVENI NAN EMISYON<br /> AYISYEN KAPAB LA!</div>
              <p className="part2_text2_about_78588 text-black">
                Vin tande istwa pozitif ak gwo ide ayisyen kap viv atravè lemond ap pataje pou enspire'w. Abòne ak emisyon sa epi
                prepare’w pou’w transfome lavi’w.
                <br /><br />
                Welcome to the Ayisyen Kapab Show: Bringing you breath-taking stories and insights from Haitians living across the
                world.
              </p>
            </div>

            <div className="col-sm-7">
              <div className="px-5">
                <img src={'/images/podcast/1.png'} className="img_podcast1" />
              </div>
            </div>
          </div>
        </div>
        {/* ============================Part 2 */}

        <div className="podcast_part3">
          <center>
            <div className="podcast_part3_box1 col-sm-5">VIN TANDE SHOW AYISYEN KAPAB LA</div>
            <img src={'/images/icons/ayisyenkapab2.png'} className="img_podcast2 py-4" />
          </center>
        </div>

        <center>
          <div className="row bg-black col-sm-5">
            <div className="col-sm-4">
              <div className="podcast_part3_text1">
                <a className="bi_jett" href="https://open.spotify.com/show/673jFDD5YYnohsVpSzXosK" target="_blank" >
                  <table>
                    <tr>
                      <td><i className="bi bi-spotify"></i></td>
                      <td><div className="podcast_part3_text2 px-2">SPOTIFY</div></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="podcast_part3_text1">
                <a className="bi_jett" href="https://podcasts.apple.com/us/podcast/ayisyen-kapab/id1703700576" target="_blank" >
                  <table>
                    <tr>
                      <td><i className="bi bi-broadcast-pin"></i></td>
                      <td><div className="podcast_part3_text2 px-2">APPLE</div></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="podcast_part3_text1">
                <a className="bi_jett" href="https://www.youtube.com/@juniorpranzell" target="_blank" >
                  <table>
                    <tr>
                      <td><i className="bi bi-youtube"></i></td>
                      <td><div className="podcast_part3_text2 px-2">YOUTUBE</div></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>
          </div>
        </center>

        {/* ============================Part 2 */}
        <center>
          <div className="col-sm-4 py-4 mt-2">
            <div className="new_about_double_box_1_text1 text-black">Junior's Social Media in Creole</div>
            <div className="new_about_part2_box1 mt-1 py-3">
              <div className="d-flex justify-content-center jett_scrollmenu">
                <div className="col-sm-2">
                  <a className="media_fa" href="https://www.facebook.com/juniorpranzel/ " target="_blank">
                    <i className=" fa fa-facebook fa-lg"></i>
                  </a>
                </div>
                <div className="col-sm-2">
                  <a className="media_fa" href=" https://www.instagram.com/juniorpranzel" target="_blank">
                    <i className=" fa fa-instagram fa-lg"></i>
                  </a>
                </div>
                <div className="col-sm-2">
                  <a className="media_fa" href="https://twitter.com/juniorpranzel" target="_blank">
                    <i className=" fa fa-twitter fa-lg"></i>
                  </a>
                </div>

                <div className="col-sm-2">
                  <a className="media_fa" href="https://www.youtube.com/@juniorpranzell" target="_blank">
                    <i className=" fa fa-youtube fa-lg"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </center>
        {/* ============================Part 2 */}

        <div className="bg-black py-3 mt-5" id="INTERVIEW">
          <div className="part2_text_about_78599 text-center">FÒM POU ENVITE YO</div>
          <p className="part2_text2_about_78588 text-white text-center opacity-75">
            Tanpri ranpli fòm sa a si ou ta renmen prezante nan emisyon an.
          </p>
        </div>
        {/* ==============================Part 3==================== */}

        <div className="d-flex justify-content-center">
          <div className="podcast_form_content shadow-sm  col-sm-6">
            <div className="podcast_form shadow">
              <div className="podcast_form_text1">Kèk enfòmasyon pou asire nou gen yon bon anrejistreman!</div>
              <div className="podcast_form_text2 py-3">* Enfòmasyon obligatwa</div>
              {error && <div className='error_text'>{error}</div>}

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Non *</div>
                <input className="input_podcast" type="text" placeholder="Non" onChange={(e) => setFistname(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Siyati *</div>
                <input className="input_podcast" type="text" placeholder="Siyati" onChange={(e) => setLastname(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Ki Imèl  ou? *</div>
                <input className="input_podcast" type="text" placeholder="Imel" onChange={(e) => setEmail(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Ki espesyalite ou, èske ou kapab di kiyès ou ye ?</div>
                <input className="input_podcast py-4" type="text" placeholder="" onChange={(e) => setExpertise(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Sou ki sijè prensipal ou vle pale ?</div>
                <input className="input_podcast py-4" type="text" placeholder="" onChange={(e) => setMain_topics_are_you_excited(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Eskè ou gen yon bagay ou vle evite pou w pa pale de li ?</div>
                <input className="input_podcast py-4" type="text" placeholder="" onChange={(e) => setAnything_off_limits(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Dim plis bagay sou nenpòt program, lansman oubyen òf ke ou vle pale sou li !</div>
                <input className="input_podcast py-4" type="text" placeholder="" onChange={(e) => setMore_about_any_programs(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Tanpri ajoute enfòmasyon pèsonèl ou pou nou kapab prezante </div>
                <input className="input_podcast py-4" type="text" placeholder="" onChange={(e) => setInclude_bio_introduce(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Tanpri di nenpòt bagay pètinan sosyal ke ou vle fè parèt</div>
                <input className="input_podcast py-4" type="text" placeholder="" onChange={(e) => setList_any_social_handles(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Èske w gen nenpòt podcasts, entèvyou, posts blog, pòs sosyal ki te deja anrejistre ke ou panse mwen ta dwe li anvan entèvyou a pou w konnen w ak ekspètiz ou pi byen?</div>
                <input className="input_podcast py-4" type="text" placeholder="" onChange={(e) => setAny_previously_recorded_podcasts(e.target.value)} />
              </div>

              <center>
                <div className="col-sm-4">
                  <button type='submit' onClick={(e) => addData(e)} className='btn_part2_jett mt-2 py-1'>SOUMET</button>
                </div>
              </center>

            </div>
          </div>
        </div>

        {/* ============================Part 2 */}

        <div className="bg-black py-3 mt-5">
          <div className="part2_text_about_78599 text-center">FÒM KI GEN KESYON POU ENVITE YO</div>
          <p className="part2_text2_about_78588 text-white text-center opacity-75">
            Eske ou gen yon bagay ki te difize avan, tankou entèvyou, pòs pou blog ak media sosyal ke ou panse mwen <br />
            ta dwe li avan entèvyou sa a, yon fason pou mwen ka konnen w pi byen? Telechaje fòm ki anba a.</p>
        </div>




        <center>
          <div className="py-5">
            <a href="/pdf/AYISYEN_KAPAB_KESYON_POU_ENVITE_YO.pdf" download className="btn_home_jett_7864 px-4 py-2">TELECHAJE FOM SA</a>
          </div>
        </center>


        <br /><br /><br /><br />











      </div>
      <Footer />
    </div>
  )
}


export default Podcast_ayisyen_kapab