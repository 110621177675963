import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Header from "../Header";
import { Footer } from "flowbite-react";
import Menu from "./Menu";

function AddProduct() {

const [name, setName] = useState("");
const [file, setFile] = useState("");
const [price, setPrice] = useState("");
const [description, setDescription] = useState("");

async function addProduct()
{
  console.warn(name,price,file,description)
  const formData = new FormData();
  formData.append('name', name);
  formData.append('price', price);
  formData.append('file', file);
  formData.append('description', description);
  // let result = await fetch("http://127.0.0.1:8000/api/addproduct",{
  let result = await fetch("https://juniorpersist.com/backend/public/api/addproduct",{
    method: 'POST',
    body: formData
  });
  navigate('../admin/products')
}




  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user-info'));

  useEffect(() => {
    if (!localStorage.getItem('user-info')) {
      navigate('/')
    }
  })

  


  return (
    <div>
      <Header />

      <div className="dash_body py-4">
        <div className="page-content container note-has-grid ">
          <Menu />
          <div className="tab-content bg-transparent">
            <div id="note-full-container" className="note-has-grid row">

              <div className="add_button">
                <h2 className='text-center'>Add New Product</h2>
              </div>

              <div className="container padding-bottom-3x mb-2">
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-10">

                    <div className="card mt-4">
                      <div className="card-body">
                        
                        <div className="row py-2">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label for="name">Name</label>
                              <input className="form-control my-2" type="text" id="name" required="" onChange={(e)=>setName(e.target.value)} />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label for="price">Price</label>
                              <input className="form-control  my-2" type="text" id="price" required="" onChange={(e)=>setPrice(e.target.value)} />
                            </div>
                          </div>
                        </div>

                        <div className="row py-2">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label for="image">Image</label>
                              <input className="form-control  my-2" type="file" id="image" required="" onChange={(e)=>setFile(e.target.files[0])} />
                            </div>
                          </div>
                        </div>

                        <div className="row py-2">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label for="description">Description</label>
                              <textarea className="form-control  my-2" id="description" required="" onChange={(e)=>setDescription(e.target.value)} ></textarea>
                            </div>
                          </div>
                        </div>
                        
                      <div className="card-footer">
                        <button className="btn_add px-3" onClick={addProduct}>Add Product</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>


        </div>

      </div>
      </div>



      <Footer />
    </div>
  )
}


export default AddProduct





