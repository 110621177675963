// import React, { useState } from 'react';
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

// const StripeCheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [price, setPrice] = useState('');
//   const [quantity, setQuantity] = useState('');

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements) {
//       return;
//     }

//     // Calculate total amount
//     const totalPrice = parseFloat(price) * parseInt(quantity);

//     // Use elements.getElement to get the CardElement
//     const cardElement = elements.getElement(CardElement);

//     // Create a payment method using the card element
//     const { error, paymentMethod } = await stripe.createPaymentMethod({
//       type: 'card',
//       card: cardElement,
//       billing_details: {
//         // You can add more billing details if needed
//       },
//     });

//     if (error) {
//       console.error('[error]', error);
//     } else {
//       console.log('[PaymentMethod]', paymentMethod);
//       // You can handle the successful payment here
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit} style={styles.form}>
//       <input 
//         type="number"
//         placeholder="Price"
//         value={price}
//         onChange={(e) => setPrice(e.target.value)}
//         style={styles.input}
//       />
//       <input 
//         type="number"
//         placeholder="Quantity"
//         value={quantity}
//         onChange={(e) => setQuantity(e.target.value)}
//         style={styles.input}
//       />
//       <div style={styles.cardElementContainer}>
//         <CardElement options={cardElementOptions} style={styles.cardElement} />
//       </div>
//       <button type="submit" disabled={!stripe} style={styles.button}>
//         Pay
//       </button>
//     </form>
//   );
// };

// const cardElementOptions = {
//   style: {
//     base: {
//       fontSize: '16px',
//       fontFamily: 'Arial, sans-serif',
//       '::placeholder': {
//         color: '#aab7c4',
//       },
//     },
//     invalid: {
//       color: '#dc3545',
//     },
//   },
// };

// const styles = {
//   form: {
//     display: 'flex',
//     flexDirection: 'column',
//     maxWidth: '300px',
//     margin: 'auto',
//   },
//   input: {
//     marginBottom: '10px',
//     padding: '8px',
//     borderRadius: '4px',
//     border: '1px solid #ccc',
//   },
//   cardElementContainer: {
//     marginBottom: '10px',
//   },
//   cardElement: {
//     base: {
//       fontSize: '16px',
//       fontFamily: 'Arial, sans-serif',
//     },
//   },
//   button: {
//     backgroundColor: '#007bff',
//     color: 'white',
//     border: 'none',
//     borderRadius: '4px',
//     padding: '10px',
//     cursor: 'pointer',
//   },
// };

// export default StripeCheckoutForm;
 


import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const PaymentSection = () => {
  const [error, setError] = useState(null);

  const handleClick = async () => {
    const stripe = await loadStripe('pk_test_51N0lzfGfLs9icUEPaCwlvUIKThZdKjYqDmXcC8aXlDgf8ePccnKdTBqalQcMFbn4GnqhZfAsAmfCUK0Ezt0FwpGR00sIzdr6uz');

    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        { price: 20, quantity: 1 }
      ],
      mode: 'payment',
      successUrl: `https://pranzel.com/buy_supports.store_stripe?idbuy=1`,
      cancelUrl: 'https://pranzel.com/cart_supports',
    });

    if (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <button onClick={handleClick}>Pay Now</button>
      {error && <div>{error}</div>}
    </div>
  );
};

export default PaymentSection;
