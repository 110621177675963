import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import '../css/read.css';

import { useState } from "react";
import Video_Junior from "../Video_Junior";

function Theletter() {

  return (
    <div>
      <Header />









      {/* ==================================================== */}
      <div className="bg-black px-4">
        <div className="col-sm-6 m-auto py-3">  </div>
      </div>

      <div className="col-sm-6 m-auto theletter_box1 ">
        <img src={'/images/img/theletter_img.png'} className="w-100" />
        <div className="px-3 py-3 mb-5">
          <div className="h4 text-black">DO YOU BELIEVE THAT HELL EXISTS?</div>
          <div className="theletter_box_text mt-3 ">
            Some people certainly do, while others never will.
            <br />
            As for me, I don't have to believe—I know. Hell is real, and it's here.
            <br /><br />
            January 12, 2010, at 4:53 pm—how can I ever forget that day? It was the day I survived one of the worst catastrophic earthquakes of the 21st century.
            <br /><br />
            It began like any other day, with street food vendors hustling, taxi drivers blaring their horns through the chaotic streets of Port-au-Prince (the capital of Haiti), and roosters crowing in the neighbors' yards.
            <br /><br />
            What if we had known that in just a matter of hours, without warning, over 100,000 of us were about to lose our lives? It was a terrifying moment that forced itself into our lives, making one thing painfully clear: Hell is real.
            <br /><br />
            I wasn’t even supposed to be in the capital that day. Growing up poor, I had always dreamed of moving to the United States to escape poverty. Let me rephrase that—I was obsessed with America and the idea of obtaining an education there, even though everyone around me ensured that I knew it was impossible.
            <br /><br />
            How could I blame them? We lived in an environment where jobs were nonexistent, finding food was a daily struggle, and only the wealthiest could afford a decent education. Not to mention witnessing children dying of hunger right before my eyes.
            <br /><br />
            Refusing to accept the circumstances around me as an excuse, it was that obsession with America that led me to learn English from an old dictionary I found in a neighbor’s trash and become an interpreter for American missionaries in my hometown. I spent years enduring hurtful words and being made fun of, hearing repeatedly, “You’re poor and a nobody, dummy. You’re wasting your time trying because you’re never gonna make it out of here.”
            <br /><br />
            Finally, at 20 years old, after over a decade of struggle, my dream was about to come true. That’s why I traveled to the capital that week and stayed with relatives so I could apply for my U.S. visa! It was supposed to be the best week of my life—the highlight of the ages—a young boy, laughed at by many, seeing his dream materialize right before the eyes of the naysayers and his enemies.
            <br /><br />
            Around 4 PM on January 12, I was sound asleep in the bedroom when a voice startled me awake, shouting, "THIS IS NO TIME TO BE IN BED! Get up, lazy boy!” I jumped up in terror and dashed to the front door without a second thought. Stepping onto the porch, before I could even question where the voice had come from, it happened.
            <br /><br />
            A deafening roar shattered the air. The whole world in front of me started to shake, or so it seemed. Dust blasted everywhere, and the ground beneath me, along with everything around me, shook violently and noisily! Concrete blocks hurtled from buildings, debris scattered chaotically. The screams of people could be heard from all over.
            <br /><br />
            If you were in a situation like this, what would you do? There was nowhere to go, no one to call for help, no place to hide, no safety. It was as if everything, the entire country, was collapsing into a gaping hole. In that moment, there was only one thing I wished I could do—fly, if only I had wings.

            <br /><br />

            <div className="h5 text-center mt-2">
              <a href="" className="text_aj1214">CONTINUE THE STORY BELOW</a>
            </div>



          </div>
        </div>
        <br />
      </div>

      <div className="theletter">
        <div className="col-sm-8 m-auto ">
          <div className="row py-5">
            <div className="col-sm-4 py-5"> <img src={'/images/book/4.png'} className="w-100 d-block d-sm-none" /></div>
            <div className="col-sm-8">
              <center>
                <div className="h3 text-white">Did You Know?</div>
                <p>
                  Junior’s book, ‘Persist, Persist.’ Persist, is now available for<br />
                  pre-order! Don’t miss out—secure your copy today!
                </p>
                <a href="/BuyDetails" className="btn_preoreder px-4 mobile_home_btn1  mx-4">PRE-ORDER NOW</a>
              </center>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-5 m-auto  d-none d-sm-block">
        <img src={'/images/book/4.png'} className="theletter_book_img" />
      </div>


      <div className="col-sm-6 m-auto">
        <div className="theletter_box">

          <p className="theletter_box_text mt-3">
            In life, there are times when no power on earth can help you—not your parents, friends, or resources. Knowing I could be hit by debris and die right where I stood, out of desperation, I closed my eyes and cried out, "Jesus, Jesus, Jesus, please save me!"
            <br /><br />
            Abruptly, the tremors ceased. The settling dust unveiled a scene of utter devastation. It was inconceivable how so much life could vanish in mere seconds. Men, women, children, rich and poor—all gone. Some trapped inside collapsing homes, others caught while fleeing. Words escaped me; it was as though my body was possessed. It seemed like everyone had perished. Friends, neighbors, familiar faces, strangers—all buried beneath ruins, strewn across streets and homes.
            <br /><br />
            For the next four days, I experienced what can only be described as being transported into hell. Under a gloomy sky filled with floating dust, we grappled with insatiable thirst, hunger, tears, cries of pain from open wounds, blood, and the stillness of bodies frozen in agony. No matter how much I pinched myself, hoping to wake from this nightmare, my body refused to obey.
            <br /><br />
            Making it to safety after countless attempts and unthinkable trials is a feeling beyond words. These trials encompassed street justice, violence, the pervasive smell of death, bodies of all ages hanging from collapsed buildings, armies of excited flies, and everything in between.

          </p>

        </div>
      </div>
      <div className="col-sm-6 m-auto mt-1">
        <img src={'/images/img/theletter_img3.png'} className="w-100" />
        <center>
          <div className="px-3 book_part3_text py-2">A USAF pararescueman searching through demolished buildings in Port-au-Prince for survivors</div>
        </center>
        <img src={'/images/img/theletter_img4.png'} className="w-100" />
        <center>
          <div className="px-3 book_part3_text py-2">Having lost their homes, many Haitians moved to live in precarious camps.
          </div>
        </center>
      </div>

      <div className="col-sm-6 m-auto theletter_box1 ">
        <div className="theletter_box_text px-4 py-4 ">
          <br />
          The earthquake changed something deep within me. My focus shifted from obsessing over my dream of moving to America, to a burning desire to share what I had witnessed and endured during those four days of chaos.
          <br /><br />
          Someone once said that no one goes through calamities in vain; there is hidden treasure in every tragedy. If that’s true, let my treasures be the lessons that became crystal clear to me. What exactly, you wonder?
          <br /><br />

          <ul>
            <li>
              Tomorrow is not guaranteed. Never heed those who tell you to sacrifice all of today for tomorrow. So many people sacrifice their present for a better future. We often hear motivational speakers urging us to work hard, forgo sleep, skip parties, and avoid fun in pursuit of success. While hard work is important, sacrificing all our todays for an uncertain tomorrow is misleading and unwise. Strive to find a balance between working hard and pursuing what truly makes you feel alive. Understand that the only day and moment promised to you is now—not tomorrow or the future, but now.
              <br /><br />
            </li>
            <li>
              You’ve heard it before, but it’s true that time is a precious gift. I urge you to rethink how you use your days, hours, and even your minutes. Make time not just to chase money and wealth, but to create moments that enrich your soul. When your life flashes before your eyes, it’s the good moments that will bring tears of joy—not your money, status, or accomplishments.
              <br /><br />
            </li>
            <li>
              When you find yourself worrying about a dozen things, remember you have over a thousand reasons to be grateful and find happiness. Consider the fresh air you breathe, a place to call home, access to clean water, and safety. Never forget that, no matter how challenging your life may seem, someone else out there is enduring greater hardship at this very moment—circumstances your mind may not even fathom.
              <br />
            </li>
          </ul>




          These are the words—and much more—that I was compelled to share with the world the moment I reached safety, to awaken people to the gravity of life. How in the overlooked details of everyday life lie the greatest treasures in the world. Despite rushing through the streets, shouting these revelations to neighbors and strangers alike, I still wasn’t content.
          <br /><br />
          I proceeded to write a letter, which I shared via email with everyone I knew, detailing my survival, discoveries, and how I called upon Jesus for help. I titled the letter: "Four Days in Hell, Four Days of Incredible Encounters with God, and Four Days in Heaven," reflecting the miraculous experiences I encountered during my escape.
          <br /><br />
          Would you believe that the letter traveled farther than I could have ever imagined? Those I emailed it to shared it widely, and it went viral across the internet. Strangers reached out, sharing how it profoundly transformed their lives and reshaped their perspectives.
          <br /><br />
          Thanks to that letter, my dream of pursuing education in America, which I had essentially thought dead, proved otherwise. Several colleges and universities extended their support, eager to lend a helping hand. A pivotal moment arrived when Alvernia University in Pennsylvania offered me a full-ride scholarship. What’s more? Another turning point occurred when, around the same time, an American family entered my life and completely transformed my world.
          <br /><br />
          Just the year before, in 2009, I was on the streets of the Dominican Republic, struggling to survive, begging under the relentless Caribbean sun. Imagine that—mere months earlier, I was sick, starving, and in tears, contemplating whether to give up, battling the darkness that tried to convince me it was all over and that ending my life might be the only way out.
          <br /><br />
          If you’re facing something that seems insurmountable, if you feel the world is against you, if you feel alone without a clear path forward, take my experience as proof that incredible things are possible for those who believe and dare to keep moving forward.
          <br />
          One of the biggest lessons I have learned is that when you give up, all hope is lost, and failure is guaranteed. On the contrary, if you persist as long as you have breath, just as I did, your surprise and miracle might be just around the corner!
          <br /><br />
          <span className="jett-font-italic">
            My book,<span className="jett_strong"> "Persist, Persist, Persist."</span> is coming out this October. It’s a memoir that delves deep into my life—fighting poverty, dodging bullets, the lessons I have learned along the way, achieving my wildest dreams, and much more that will astound you. This book reads like a novel, offering insights to empower and elevate your persistence to new heights.
          </span>
          <br /><br />
          <br /><br />
        </div>
      </div>






      <div className="theletter">
        <div className="col-sm-9 m-auto ">
          <div className="row py-5">
            <div className="col-sm-4 py-5"> <img src={'/images/book/4.png'} className="w-100 d-block d-sm-none" /></div>
            <div className="col-sm-8">
              <center>
                <div className="h3 text-white">Did you enjoy the story?</div>
                <div className="col-sm-9 mb-4 px-2">
                  There’s so much to Junior’s story. His new book, "Persist, Persist, Persist," 
                  is now available for pre-order! If you haven’t already, secure your copy today!
                </div>
                <a href="/BuyDetails" className="btn_preoreder px-4 mobile_home_btn1  mx-4">PRE-ORDER NOW</a>
              </center>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 m-auto  d-none d-sm-block">
        <img src={'/images/book/4.png'} className="theletter_book_img" />
      </div>








      {/* ================== */}
      <div className="col-sm-5 m-auto">
        <div id="JUNIORSPEECH" className="new_about_bar2 h3 text-center text-white">WATCH JUNIOR’S COMMENCEMENT SPEECH </div>
      </div>

      <center>
        <div className='new_about_double_part2 py-3'>
          <div className='py-2'>
            <br />
            <div className='home_new_jett_text1'>PERSIST, PERSIST, PERSIST.</div>
            <div className='home_new_jett_text1'>ALVERNIA UNIVERSITY - DECEMBER 2014</div>
          </div>
          <Video_Junior />
          <br /> <br /> <br />
        </div>
      </center>
      {/* ====================== */}
      <div className="pkl_908 mb-5">
        <div className=" d-flex justify-content-center">
          <div className="col-sm-5  book_down2">GAIN EARLY ACCESS TO THE BOOK</div>
        </div>
        <div className="black_bar_book_down"></div>
        <center>
          <div className="book_down_text2  mt-4">Receive exclusive updates and be the first to know when the book is ready to launch!</div>
          <div className="py-3">
            <a href="https://lp.constantcontactpages.com/sl/mt3B9Qg/persistbook" target='_blank' className="btn_home_jett_7864_2 px-3 py-1">SIGN UP NOW</a>
          </div>
        </center>
      </div>
      {/* ==================== */}



      {/* ==================================================== */}








    </div>
  )
}


export default Theletter