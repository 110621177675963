import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";


function Influencer() {


  // const user = JSON.parse(localStorage.getItem('user-info'));



  // useEffect(() => {
  //   if (!localStorage.getItem('user-info')) {
  //     navigate('/logininfluencer')
  //   }
  // })

  // const navigate = useNavigate();
  // const [error, setError] = useState("");
  // const [firstname, setFirstname] = useState("");
  // const [lastname, setLastname] = useState("");
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  // const [description, setDescription] = useState("");



  // async function addData(e) {
  //   e.preventDefault();
  //   setError("");

  //   if (firstname && lastname && email && phone && description) {
  //     console.warn(firstname, lastname, email, phone, description )

  //     const formData = new FormData();
  //     formData.append('user_id', 1);
  //     formData.append('firstname', firstname);
  //     formData.append('lastname', lastname);
  //     formData.append('email', email);
  //     formData.append('phone', phone);

  //     formData.append('description', description);

  //     try {
  //       let result = await fetch("http://127.0.0.1:8000/api/addInfluencer", {
  //         // let result = await fetch("https://juniorpersist.com/backend/public/api/addInfluencer", {

  //         method: 'POST',
  //         body: formData
  //       });
  //       navigate('/Success_message')
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     setError("Error: (Firstname, Lastname, Phone, Email) field required")
  //   }
  // }

  return (
    <div>
      <Header />

 
      <div className="speaking_slider_all d-flex justify-content-center px-4">
        <div className="col-sm-9 py-4">
          <div className="row">
            <div className="col-sm-6 align-self-center">
              <div className="px-2">
                <div className="text1_speaking_slider">SPEAKING & FUNDRAISING</div>
                <div className="text2_speaking_slider"> INSPIRE YOUR AUDIENCE WITH<br /> A UNIQUE STORY </div>
                <div className="text3_speaking_slider  mt-2">Invite Junior to speak at your next event and inspire your audience like never before!</div>
                <br />
                <div className="">
                  <a className="btn_home_jett_7864_3 px-5" href="http://127.0.0.1:8000/home">GET STARTED</a>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <img src={'./images/img/persist_img1.png'} className="img_84774" />
            </div>
          </div>
        </div>
      </div>

     
      {/* ============================Part 2 */}
<br />
 


      {/* ==============================Part 3==================== */}




 



      <Footer />
    </div>
  )
}



export default Influencer