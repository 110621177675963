import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function Press() {


  return (
    <div>
      <Header />
      <div className="reaction_header_2 mt-2 py-3">PRESS</div>
      <div className="contact_page ">
        <div className=" container">

          <div className="row py-3">

            <div className="col-sm-6 ">
              <div className="text_slider ">
                    <div className="new_about_text1 text-black px-2  mb-1"> Professional Bio:</div>
                <div className="new_contact2 py-5">
                  <div className="new_about_part1 text-white">

                    Junior was born and raised in Haiti. He moved to the United States in 2010 after surviving the devastating earthquake that claimed over 100,000 lives. He earned his degree from Alvernia University in December 2014 on a full scholarship.
                    <br /><br />
                    Driven by a passion to uplift his community, Junior founded several nonprofit organizations, including Haiti’s Heartbeat and Pranzel Elite School, which have transformed the lives of thousands of Haitian children and young adults.
                    <br /><br />
                    His unwavering commitment to making a difference earned him Alvernia University’s prestigious Four Under Forty Achievement Award, recognizing his exceptional philanthropic leadership and efforts to boost economic growth in Haiti.
                    <br /><br />
                    Through his memoir, "Persist, Persist, Persist," Junior aims to inspire people from all walks of life, demonstrating the transformative power of persistence and the importance of fighting for one's dreams against all odds. His journey is a powerful testament to resilience, courage, and the boundless potential within us all.
                    </div>
                    <br></br><br></br> <br></br><br></br>
                </div>
              </div>
            </div>



            <div className="col-sm-6 ">
              <div className="text_slider ">
                    <div className="new_about_text1 text-black px-2  mb-1"> Book Blurb:</div>
                <div className="new_contact py-5">
                  <div className="new_about_part1 text-white">


                    

                    In a world that often dismisses the pursuit of dreams as a mere luxury, for Junior, it was an all-out war between life and death.
                    <br /><br />
                    Growing up in the heart of poverty in Haiti, a world vividly described in this memoir, Junior embarked on an unprecedented journey—from surviving a heart-wrenching car crash to desperate street begging in the Dominican Republic. Then came the Haiti earthquake, a relentless force that snuffed out over 200k lives in less than 60 seconds.
                    <br /><br />
                    And if you think you've heard it all, think again. What lies ahead in these pages takes “persistence” to a whole new dimension—a story so gripping, so raw, that you need to read it to believe it.
                    <br /><br />
                    "Persist, Persist, Persist" is not just a memoir; it's an electrifying, can't-put-it-down chronicle that immerses you in Junior's thrilling journey and unveils Haiti's narrative in a revolutionary light. A timeless tale of obsession, survival, and self-invention, intricately woven with the unforgiving twists of fate.
                    <br /><br />
                    Brace yourself for a narrative that will make you lose track of time and demand to be shared with everyone you know.







                  </div>
                </div>
              </div>
            </div>

         
          </div>

          {/* ================ */}
        </div>


        {/* ==============================Part 1_2==================== */}

        <div className="bg-white">
          <div id="JUNIORSPEECH" className="new_about_bar2 h3 text-center bg-white mt-5">
            <span className="h6 opacity-50">HEAD SHOTS AND BOOK COVERS</span>  <br />
            Downloadable Media
          </div>

          <div className="container py-2">
            <center>
              <div className="row col-sm-10">
                <div className="col-sm-4 mb-5">
                  <a className="nav-link jett_download" href="/images/media/2.png" download>
                    <img className="w-100" src="/images/media/2.png" />
                    <div className="text-center mt-3">DOWNLOAD IMAGE</div>
                  </a>
                </div>
                <div className="col-sm-4 mb-5">
                  <a className="nav-link jett_download" href="/images/media/1.png" download>
                    <img className="w-100" src="/images/media/1.png" />
                    <div className="text-center mt-3">DOWNLOAD IMAGE</div>
                  </a>
                </div>
                <div className="col-sm-4 mb-5">
                  <a className="nav-link jett_download" href="/images/media/3.png" download>
                    <img className="w-100" src="/images/media/3.png" />
                    <div className="text-center mt-3">DOWNLOAD IMAGE</div>
                  </a>
                </div>


                <div className="col-sm-4 mb-5">
                  <a className="nav-link jett_download" href="/images/media/4.png" download>
                    <img className="w-100" src="/images/media/4.png" />
                    <div className="text-center mt-3">DOWNLOAD IMAGE</div>
                  </a>
                </div>
                <div className="col-sm-4 mb-5">
                  <a className="nav-link jett_download" href="/images/media/5.png" download>
                    <img className="w-100" src="/images/media/5.png" />
                    <div className="text-center mt-3">DOWNLOAD IMAGE</div>
                  </a>
                </div>
                <div className="col-sm-4 mb-5">
                  <a className="nav-link jett_download" href="/images/media/6.png" download>
                    <img className="w-100" src="/images/media/6.png" />
                    <div className="text-center mt-3">DOWNLOAD IMAGE</div>
                  </a>
                </div>


              </div>
            </center>
          </div>
        </div>

        <div id="CONTACT" className="d-flex justify-content-center py-4 cont_about_part2">
          <div className="col-sm-11 about_part2">
            <center>
              <div className='row px-5 py-4 mb-4 mt-4'>

                <div className="col-sm-3 py-3">
                  <div className="contact_part2_box1">
                    <h5 className="text-white">SPEAKING OPPORTUNITIES</h5>
                    <div className="contact_part2_text1">
                      Invite Junior to speak at your next event and inspire your audience!
                    </div>
                    <div className="contact_part2_text1 mt-2 email_contact009">
                      Email: speaking@juniorpersist.com
                    </div>
                  </div>
                </div>

                <div className="col-sm-3 py-3">
                  <div className="contact_part2_box1">
                    <h5 className="text-white">PRESS</h5>
                    <div className="contact_part2_text1">
                      For all interviews, media opportunities, and anything else press-related:
                    </div>
                    <div className="contact_part2_text1 mt-2 email_contact009">
                      Email: press@juniorpersist.com
                    </div>
                  </div>
                </div>

                <div className="col-sm-3 py-3">
                  <div className="contact_part2_box1">
                    <h5 className="text-white">BOOK ORDERS</h5>
                    <div className="contact_part2_text1">
                      Do you have book-related questions? Contact us today!
                    </div>
                    <div className="contact_part2_text1 mt-2 email_contact009">
                      Email: sales@juniorpersist.com
                    </div>
                  </div>
                </div>

                <div className="col-sm-3 py-3">
                  <div className="contact_part2_box2">
                    <h5 className="text-white">GENERAL INQUIRIES</h5>
                    <div className="contact_part2_text1">
                      Feel free to reach out about anything that interests you using the email below.
                    </div>
                    <div className="contact_part2_text1 mt-2 email_contact009">
                      Email: contact@juniorpersist.com
                    </div>
                  </div>
                </div>

              </div>
            </center>
          </div>
        </div> 




              {/* ====================== */}
      <div className="pkl_908 py-5">
        <div className=" d-flex justify-content-center">
          <div className="col-sm-6  book_down2">HIRE JUNIOR TO SPEAK AT YOUR EVENT</div>
        </div>
        <div className="black_bar_book_down"></div>
        <center>
          <div className="py-3 mt-4">
            <a href="https://lp.constantcontactpages.com/sl/mt3B9Qg/persistbook" target='_blank' className="btn_home_jett_7864_2 px-3 py-1">SIGN UP NOW</a>
          </div>
        </center>
      </div>
      {/* ==================== */}



      </div>
      <Footer />
    </div>
  )
}


export default Press

