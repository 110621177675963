import { Link } from 'react-router-dom'

function Video_student() {



  return (
    // ========Start Join
    <div>

<div className='part3 container col-sm-9 mt-5'>
				<div className='text2_part3003'>WHAT OUR STUDENTS ARE SAYING</div>

				<div className=''>
					<div className='row'>

						<div className='col-sm-4 py-3 jett_block_double_slider'>
							<div className='part3_video_box shadow'>
								<iframe className='iframe_part3' src="https://player.vimeo.com/video/903734045?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"></iframe>
								<div className='px-4 py-2'>
									<span>Student Testimonial</span><br />
									<span className='text_box_video_part3'>Pranzel's Impact on Students</span>
								</div>
							</div>
						</div>

						<div className='col-sm-4 py-3 jett_block_double_slider'>
							<div className='part3_video_box shadow'>
								<iframe className='iframe_part3' src="https://player.vimeo.com/video/902780598?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"></iframe>
								<div className='px-4 py-2'>
									<span>Student Scholarship Reveal!</span><br />
									<span className='text_box_video_part3'>Student expressing gratitude for scholarship.</span>
								</div>
							</div>
						</div>

						<div className='col-sm-4 py-3 jett_block_double_slider'>
							<div className='part3_video_box shadow'>
								<iframe className='iframe_part3' src="https://player.vimeo.com/video/903056801?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"></iframe>
								<div className='px-4 py-2'>
									<span>Student Testimonial </span><br />
									<span className='text_box_video_part3'>Pranzel's impact on students</span>
								</div>
							</div>
						</div>

					</div>
				</div>

			</div>


    </div>
    // ========End header
  )
}

export default Video_student