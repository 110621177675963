import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import '../css/read.css';

import { useState } from "react";

function Books() {

	// Function to change the image source
	const [imageSrc, setImageSrc] = useState('/images/book/3.png');
	const FrontImageSrc = () => {
		setImageSrc('/images/book/6.png');
	}
	const BackImageSrc = () => {
		setImageSrc('/images/book/3.png');
	}
	// =================================
	return (
		<div>
			<Header />




			<div className="main-wrapper read_jett_font_family">
				<div className="book px-3 px-lg-5">
					<div className=" container d-flex align-items-center justify-content-center">
						<div className="row ">
							<div className="col-sm-5">
								<div className="left_content">
									<img className='image_slider' src={'/images/book/3.png'} />
								</div>
							</div>
							<div className="col-sm-7 align-self-center">
								<div className="jett87454">

									<div className="mt-4 slider_book_text3">RELEASE DATE: OCTOBER 1st, 2024</div>
									<div className="slider_book_text4 py-2">A Crazy Good True Story You Won't Forget</div>
									<div className="slider_book_text5 col-sm-10 mt-4">
										In a world that often dismisses the pursuit of dreams as a mere luxury, for Junior, it was an all-out war between life and death. Growing up in the heart...
									</div>
								</div>
								<div className="py-3">
									<a href="#PERSIST" className="slider_book_text6 mobile_home_btn1 ">READ FULL BLURB BELOW</a>
									<Link className="slider_book_text_new px-3 mobile_home_btn1" to={'/BuyDetails'} >PRE-ORDER NOW</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>






			{/* =========================Part 3============================ */}


			<div className="sum_book_7645 px-4" id="PERSIST">

				<div className="d-flex align-items-center justify-content-center">
					<div className="col-sm-10 ">
						<div className="text_book_part3 pt-5">PERSIST, PERSIST, PERSIST.</div>
						<div className="row">
							<div className="col-sm-5">
								<div className="text_book_part4 ">The Haitian Boy, The American, </div>
								<div className="text_book_part4 ">and The Hidden Lesson</div>

								<div className="book_part3_text mt-3 ">

									In a world that often dismisses the pursuit of dreams as a mere luxury, for Junior, it was an all-out war between life and death.
									<br /><br />
									Growing up in the heart of poverty in Haiti, a world vividly described in this memoir, Junior embarked on an unprecedented journey—from surviving a heart-wrenching car crash to desperate street begging in the Dominican Republic. Then came the Haiti earthquake, a relentless force that snuffed out over 200k lives in less than 60 seconds.
									<br /><br />
									And if you think you've heard it all, think again. What lies ahead in these pages takes “persistence” to a whole new dimension—a story so gripping, so raw, that you need to read it to believe it.
									<br /><br />
									"Persist, Persist, Persist" is not just a memoir; it's an electrifying, can't-put-it-down chronicle that immerses you in Junior's thrilling journey and unveils Haiti's narrative in a revolutionary light. A timeless tale of obsession, survival, and self-invention, intricately woven with the unforgiving twists of fate.
									<br /><br />
									Brace yourself for a narrative that will make you lose track of time and demand to be shared with everyone you know.


								</div>

								<div className="mt-4">
									<img src={'/images/buy_footer1234.png'} className="persist_img_page8474" />
								</div>

								<div className='exclusive mt-1'>
									<span className='text1_part2'>
										<span className='exclusive_i px-2'><i className='bi bi-star-fill'></i></span>
										Exclusive Edition
									</span>
									: Available only on
									<span className='mobile_break'> juniorpersist.com</span>
								</div>


							</div>
							<div className="col-sm-7">
								<div className="d-none d-sm-block"><br /><br /><br /></div>
								<img className="book_part3_image848 mt-5 d-none d-sm-block" src="./images/img/persist_img2.png" />
								<img className="book_part3_image848_mobile mt-5 d-block d-sm-none" src="./images/img/persist_img2_mobile.png" />
							</div>
						</div>
					</div>
				</div>

			</div>




			{/* ====================== */}
			<div className="mb-5 new_jett_part_book_34">
				<div className=" d-flex justify-content-center ">
					<div className="col-sm-6  book_down2 jett_block_double_slider jett_new9848">FIRST CHAPTER ACCESS COMING SOON!</div>
				</div>
				<div className="black_bar_book_down"></div>
				<center>
					{/* <div className="book_down_text1 mt-4">DONATE NOW - GET EARLY ACCESS TO THE BOOK!</div> */}
					<div className="book_down_text2 col-sm-5 py-3 mt-4">Receive exclusive updates and be the first to know when the book is ready to launch!</div>
					<div className="py-2">
						<a href="https://lp.constantcontactpages.com/sl/mt3B9Qg/persistbook" target='_blank' className="btn_home_jett_7864_2 py-2 px-4">STAY UPDATED</a>
					</div>
				</center>
			</div>

			{/* ====================== */}


			<Footer />
		</div>
	)
}


export default Books