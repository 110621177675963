import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";

function Register() {
  useEffect(() => {
    if (localStorage.getItem('user-info')) {
      navigate('/')
    }
  })

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();


  async function signUp() {
    let item = { firstname, lastname, email, phone, password }

    // let result = await fetch("https://juniorpersist.com/backend/public/api/register", {
    let result = await fetch("http://127.0.0.1:8000/api/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(item)
    });
    result = await result.json();
    console.warn("result", result)
    localStorage.setItem("user-info", JSON.stringify(result));
    navigate('/');
  }

  return (
    <div>
      <Header />

      <section className="background-radial-gradient overflow-hidden">

        <div className="container px-4  px-md-5 text-center text-lg-start my-5">
          <div className="row gx-lg-5 align-items-center mb-5">
            <div className="col-lg-6 mb-5 mb-lg-0 text1_register" >
              <h1 className="my-5 display-5 fw-bold ls-tigh text3_register" >
                

                <div className="text2_register" > JOIN THE<span className="login_text_1"> 'PERSIST, PERSIST, PERSIST.'</span> MOVEMENT!</div>
              </h1>
              <p className="mb-4 opacity-70 text4_register">
                Whether you're purchasing books, endorsing our initiatives to bring about change in Haiti, or volunteering, we are thrilled and appreciative of your decision to express your support!
              </p>
            </div>

            <div className="col-lg-5 mb-5 mb-lg-0 position-relative">
              <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
              <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

              <div className="card bg-glass">
                <div className="card-body px-4 mt-2 px-md-5">

                  <h4 className="create_text1_jett">CREATE AN ACCOUNT</h4>
                  <h6 className="create_text2_jett">Proceed below to get started.</h6>
                  <hr />

                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <div className="form-outline">
                        <input type="text" id="form3Example1" value={firstname} onChange={(e) => setFirstname(e.target.value)} className="form-control" />
                        <label className="form-label" for="form3Example1">First name</label>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="form-outline">
                        <input type="text" id="form3Example2" value={lastname} onChange={(e) => setLastname(e.target.value)} className="form-control" />
                        <label className="form-label" for="form3Example2">Last name</label>
                      </div>
                    </div>
                  </div>

                  <div className="form-outline mb-4">
                    <input type="email" id="form3Example3" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" />
                    <label className="form-label" for="form3Example3">Email address</label>
                  </div>

                  <div className="form-outline mb-4">
                    <input type="phone" id="form3Example4" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" />
                    <label className="form-label" for="form3Example4">Phone number</label>
                  </div>

                  <div className="form-outline ">
                    <input type="password" id="form3Example5" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" />
                    <label className="form-label" for="form3Example5">Password</label>
                  </div>

                  <hr />
                  <center>
                    <div className="form_login">
                      <input onClick={signUp} type="submit" className="fadeIn fourth" value="Sign up" />
                    </div>
                  </center>
                  <hr />


                  <center>
                    <Link className="log_jett_text1" to="../login">
                      Do you already have an account? <span className="log_jett_text2">Log in</span>
                    </Link>
                  </center>




                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />



    </div>
  )
}


export default Register