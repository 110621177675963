import Header from "../Header";
import Footer from "../Footer";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'react';
import AudioPlayer from "./AudioPlayer";

function VIP() {
  // ===========CLOUDINARY======================
  const [uploadedUrl, setUploadedUrl] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget({
      cloudName: 'dzpvitjve',
      uploadPreset: 'eeohdbag',
      resourceType: 'image', // Accept only images
      multiple: false, // Only single image upload
    }, function (error, result) {
      if (!error && result && result.event === "success") {
        console.log('Uploaded image URL: ', result.info.url);
        setUploadedUrl(result.info.url);
        setSuccessMessage('Your file has been successfully added.');
      }
    });
  }, []);
  // =================================

  const [error1, setError1] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false); // New state for checkbox

  const navigate = useNavigate();

  async function addData1(e) {
    e.preventDefault();
    setError1("");
    if (name && phone && email && termsAccepted) { // Check for termsAccepted
      console.warn(name, phone, email);
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('link', uploadedUrl);

      try {
        // let result = await fetch("http://127.0.0.1:8000/api/addVipAccessSubmitEvidencia", {
        let result = await fetch("https://juniorpersist.com/public/public/api/addVipAccessSubmitEvidencia", {
          method: 'POST',
          body: formData
        });
        window.location.href = '/PreOrder_success';
      } catch (error1) {
        console.log(error1);
      }
    } else {
      setError1("Error: Complete all fields, accept terms before submission.");
    }
  }

  return (
    <div>
      <div className="success_access_IA pb-4">
        <div className="success_access_IA_border_down">
          <div className="text0_success_access_IA col-sm-4 m-auto py-2">FREE BOOK VIP ACCESS!</div>
        </div>

        {/* ========== */}
        <div className="speaking_slider_all d-flex justify-content-center px-4 py-5">
          <div className="col-sm-10">
            <div className="row">
              <div className="col-sm-6">
                <div class="container_video">
                  <iframe class="responsive-iframe" src="https://player.vimeo.com/video/1001246468" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen ></iframe>
                  <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </div>
              <div className="col-sm-6 align-self-center">
                <center>
                  <div className="px-2 py-4 ">
                    <div className="btn_home_jett_7864_4 mb-4">HURRY—NEW BOOK OFFER EXPIRING SOON!</div>
                    {/* <div className="text2_speaking_slider">PRE-ORDER NOW AND GAIN EARLY ACCESS</div> */}
                    <div className="text3_speaking_slider col-sm-10  mt-3">
                      Get Exclusive VIP Access to ‘Persist, Persist, Persist’ Before It’s Released to the Public! Help Us Reach 1,000 Reviews on Amazon by Launch Day!                    </div>
                    {/* <div className="text_spread_box004">This is a limited-time offer!</div> */}
                    <br />
                    <center>
                      <a className="btn_spread001 " href="#STARTED">UNLOCK FREE ACCESS NOW</a>
                    </center>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>

        {/* ========== */}

        {/* <div className="barTitle_success_access_IA px-2">
          CLAIM YOUR <span className="text1_barTitle_success_access_IA">FREE</span> VIP BOOK ACCESS NOW
        </div> */}

        <div className="mt-4">
          <div className="box2_success_access_IA col-sm-5 m-auto">
            <center>
              Already started the book? Keep reading using your VIP code
              <a href="https://juniorpersist.com/public/public/GoBook" className="btnAccess0085"> 'HERE'.</a>
            </center>
          </div>
        </div>


        <center>
          <div className="mt-2 py-3" id="STARTED">
            <div className="text2_success_access_IA">
              Complete the form below to get instant<br /> access to Persist, Persist, Persist.
            </div>
          </div>
        </center>

        <div className="mt-2">
          <div className="col-sm-5 m-auto mt-3 bg_vip" >
            <div className='justify-content-center px-5 py-4'>
              <div className='form'>
                <center>

                  {error1 && <div className='error_text2'>{error1}</div>}

                  {successMessage && (
                    <div>
                      <p className="text_succes_up_video">{successMessage}</p>
                    </div>
                  )}
                </center>
                <input className="input_part2_jett2" placeholder='Name' type="text" id="name" required="" onChange={(e) => setName(e.target.value)} />
                <input className="input_part2_jett2" placeholder='Email Address' type="text" id="email" required="" onChange={(e) => setEmail(e.target.value)} />
                <input className="input_part2_jett2" placeholder='Phone' type="text" id="phone" required="" onChange={(e) => setPhone(e.target.value)} />

                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    id="terms"
                    className="checkbox"
                    checked={termsAccepted}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                  />
                  <label htmlFor="terms" className="custom-checkbox"></label>
                  <label htmlFor="terms" className="checkbox-label">
                    I agree to be contacted on launch day (Oct. 15) to provide an Amazon review for Persist, Persist, Persist.
                  </label>
                </div>
                <div className="mt-3">

                  {/* <center>
                    {uploadedUrl ? null : (
                      <button className='btn_upload_video2' onClick={() => widgetRef.current.open()}>
                        <i className="bi bi-cloud-arrow-up-fill"></i> Attach file
                      </button>
                    )}
                  </center>
                  */}
                  <br />

                  <button onClick={(e) => addData1(e)} className='btn_part2_jett mt-2 py-1'>GAIN ACCESS NOW</button>
                </div>
              </div>
            </div>
          </div>
        </div>



        <br></br><br></br>
      </div>


      <div className="bg-black py-5">
        <div className="col-sm-5 m-auto">
          <center>
            <div className="btn_home_jett_7864_4" >READY FOR THE NEXT STEPS?</div>
            <div className="text_spread_box009 mt-3 text-white">
              HOW TO LEAVE <span className="text_spread_box004">A REVIEW ON AMAZON </span>
            </div>
            <div className="text0_spread mt-3 text-white">
              Note: To leave a review on Amazon, you must pre-order the book between today and our launch day, October 15. Reviews can be submitted starting on launch day. Don’t miss out—take advantage of this promotion before it expires!!
            </div>

            <div className="py-3">
              <span className="new_vip_text1">$9.99</span>&nbsp;&nbsp;
              <span className="new_vip_text2">$2.99</span>
            </div>


            <a className="btn_spread001" target="_blank" href="https://www.amazon.com/dp/B0DG4R698Z?ref_=pe_93986420_775043100">PRE-ORDER ON AMAZON NOW</a>
          </center>

        </div>
      </div>


      {/* <div className="bg97565 py-2 ">
        <div className="container">

          <div className="col-sm-8 m-auto py-3 ">
            <div className="row">

              <div className="col-sm-6 border_spread001 mb-4">
                <div className="px-3">
                  <div className="text0_spread">A Quick Note:</div>
                  <div className="text_spread_box009 mt-3">
                    IN THE WORLD OF BOOKS, <span className="text_spread_box004">AMAZON REVIEWS</span>  ARE EVERYTHING!
                  </div>
                  <div className="text0_spread mt-3">
                    Help Junior reach <strong>1000 reviews</strong>  by launch day—October 15th!
                    Your support would mean the world to us!
                  </div>
                  <br />
                </div>
                <div className="d-block d-sm-none">
                  <hr />
                </div>
              </div>
              <div className="col-sm-6 mb-4 ">
                <div className="px-3">
                  <div className="text_spread_box001 mb-2">HOW TO LEAVE A REVIEW ON AMAZON</div>
                  <div className="col-sm-8 text_spread_box010">
                    Note: To leave a review on Amazon, you must pre-order the book between today and Oct. 15.
                    Take advantage of this promotion before it expires.
                  </div>
                  <div className="py-3">
                    <span className="text1_spread_box001">$9.99</span>  <span className="text2_spread_box001 px-3">$2.99</span>
                    <a className="btn_spread003" target="_blank" href="https://www.amazon.com/dp/B0DG4R698Z?ref_=pe_93986420_775043100">PRE-ORDER EBOOK</a>
                    <div className="mt-4 col-sm-8">
                      <center>
                        <img src={'/images/testimonffyAmazon.png'} className="img_spread004" />
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div> */}




      {/* ===================================== */}
      <div className="px-2 py-4" id="BookBlurb" >
        <div className="col-sm-6 m-auto px-3 py-3 border857" >
          <h3 className="">Book Blurb</h3>
          <hr />
          <div className="sum_book_7645 " id="PERSIST">
            <div className="d-flex align-items-center justify-content-center">
              <div className="col-sm-10 ">
                <div className="buy_new_1 font_size0948 pt-2">PERSIST, PERSIST, PERSIST.</div>
                <div className="">
                  <div className="buy_new_2 ">The Haitian Boy, The American, </div>
                  <div className="buy_new_2 ">and The Hidden Lesson</div>
                  <div className="book_part3_text mt-3 ">
                    In a world that often dismisses the pursuit of dreams as a mere luxury, for Junior, it was an all-out war between life and death.
                    <br /><br />
                    Growing up in the heart of poverty in Haiti, a world vividly described in this memoir, Junior embarked on an unprecedented journey—from surviving a heart-wrenching car crash to desperate street begging in the Dominican Republic. Then came the Haiti earthquake, a relentless force that snuffed out over 200k lives in less than 60 seconds.
                    <br /><br />
                    And if you think you've heard it all, think again. What lies ahead in these pages takes “persistence” to a whole new dimension—a story so gripping, so raw, that you need to read it to believe it.
                    <br /><br />
                    "Persist, Persist, Persist" is not just a memoir; it's an electrifying, can't-put-it-down chronicle that immerses you in Junior's thrilling journey and unveils Haiti's narrative in a revolutionary light. A timeless tale of obsession, survival, and self-invention, intricately woven with the unforgiving twists of fate.
                    <br /><br />
                    Brace yourself for a narrative that will make you lose track of time and demand to be shared with everyone you know.
                  </div>
                  <div className="mt-4">
                    <img src={'/images/buy_footer1234.png'} className="persist_img_page8474" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================================== */}




    </div>
  );
}

export default VIP;
