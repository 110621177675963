import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import '../css/read.css';
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Video_Junior from "../Video_Junior";
import Board_members from "../Board_members";
import Video_student from "../Video_student";
import Slider_double from "../Slider_double";

function Church_contribution() {


	// =================================
	return (
		<div>
			<Header />



			<div className="main-wrapper read_jett_font_family">
				<div className="book px-3 px-lg-5">
					<div className=" container d-flex align-items-center justify-content-center">
						<div className="row col-sm-10">
							<div className="col-sm-4">
								<div className="left_content">
									<img className='slider_book_imager ' src={"/images/book/3.png"} />
								</div>
							</div>
							<div className="col-sm-8">
								<div className="mt-4 slider_book_text3">BOOK RELEASE DATE: OCTOBER 1st, 2024</div>
								<div className="slider_book_text4 py-2">Chapter Preview: What Happened at the River</div>
								<div className="slider_book_text5 col-sm-10">
									In a world that often dismisses the pursuit of dreams as a mere luxury, for Junior, it was an all-out war between life and death. Growing up in the heart of poverty in Haiti...
								</div>
								<div className="py-3">
									<div className="slider_book_text7 py-2">
										IMPORTANT NOTICE: The chapters you are about to read provide an exclusive preview of what's
										to come in August, preceding the official book release. Please note that the final version of
										these chapters may undergo further modifications if necessary.
									</div>

								</div>

								<div className="item_info_book2 jkl987">
									<div className="item_info_book2_content">
										<div className="slider_book_text4">DONATE & ACCESS THE FULL BOOK SOONER! <img src={'/images/icons/clock.png'} className="slider_book_img" /></div>

										<div className="slider_book_text5 py-2">
											<div className="row">
												<div className="col-sm-3">
													<div className="comm_text1">DID YOU KNOW?</div>
												</div>
												<div className="col-sm-9">
													<div className="comm_text2">Upon release, 100% of the book’s revenue  will go directly towards the Pranzel Elite School's construction.</div>
												</div>
											</div>

											<div className="row mt-4">
												<div className="col-sm-3">
													<div className="comm_text1">DONATE $1000+</div>
												</div>
												<div className="col-sm-9">
													<div className="comm_text2">Your tax-deductible donation will help Junior build the school for 500 students. We’ll mail you a FULL PRE-RELEASED BOOK within 24 HOURS as a thank you!</div>
												</div>
											</div>
										</div>

										<div className="py-3">
											<Link to="/Donate_payment1000" className="btn_buy_book4 px-4">DONATE NOW</Link>
										</div>

									</div>

								</div>
								<br /><br />
							</div>
						</div>
					</div>
				</div>
			</div>


			{/* ==============================Part 1_2==================== */}


			<div className="resume_book d-none d-sm-block space0098">
				<center>
					<div className="col-sm-8">
						<iframe className="pdf_res001" src={'/reviewer/chapter.pdf'} />
					</div>
				</center>
			</div>

			{/* =============================Mobile=================== */}
				{/* =============================Mobile=================== */}
				<div className="resume_book d-block d-sm-none">
				<div className="py-4">
					<center>
						<div className="scroll_page_jett">
							<table className="table_scroll">
								<tr>
									<td className="float-start"><i className="fa fa-arrow-circle-down"></i> Scroll Left</td>
									<td></td>
									<td className="float-end">Scroll Right <i className="fa fa-arrow-circle-down"></i></td>
								</tr>
							</table>


						</div>
						<div className="jett_scrollmenu">
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-01.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-02.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-03.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-04.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-05.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-06.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-07.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-08.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-09.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-10.png'} />
							
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-11.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-12.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-13.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-14.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-15.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-16.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-17.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-18.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-19.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-20.png'} />

							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-21.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-22.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-23.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-24.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-25.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-26.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-27.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-28.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-29.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-30.png'} />

							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/book/chapter-31.png'} />
						</div>
					</center>
				</div>
			</div>



			{/* =========================Part 3============================ */}
			<div className="py-5">
				<div className=" d-flex justify-content-center">
					<div className="col-sm-8  book_down2">JUNIOR’S COMMENCEMENT SPEECH</div>
				</div>
				<div className="black_bar_book_down"></div>
				<center>
					<div className="h6 mt-4 py-3">ALVERNIA UNIVERSITY - DECEMBER 2014</div>

					<Video_Junior />

				</center>
			</div>
			{/* =========================Part============================ */}






			<div className="mb-5">
				<div className=" d-flex justify-content-center">
					<div className="col-sm-4  book_down2">TAKE ACTION</div>
				</div>
				<div className="black_bar_book_down"></div>
				<center>
					<div className="book_down_text2 col-sm-4 mt-4 py-2">Join Junior in building the Pranzel Elite School for 500 students. </div>
					<div className="py-3">
						<a href="/Donate" className="btn_buy_book4 px-4">DONATE NOW</a>
					</div>
				</center>
			</div>

			{/* =========================Part ============================ */}

			<div className="cont_social_about">
				<div className="col-sm-6 social_about py-3">
					<div className="text1_social_about">WE ARE TRANSPARENT</div>
				</div>
			</div>
			<br />
			<Slider_double />

			{/* ========================Part3 */}

			<Video_student />

			{/* ================================= */}
			<br />
			<Board_members />

			{/* ================================= */}


			<div className="py-5">
				<center>
					<div className=" col-sm-7 bg-black  text-white h3 py-2">SUPPORT OUR WORK</div>
					<div className=" col-sm-7">
						<div className="fund_black_001_text1">Join us in changing the course of Haiti’s future through the power of knowledge.</div>
					</div>
					<br />
					<a href={'/Donate'} className="btn_fund_down" >DONATE NOW</a>
				</center>
			</div>


			<Footer />
		</div>
	)
}


export default Church_contribution