import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom';

function Buy_checkout() {


  // ==========Input============



  const navigate = useNavigate();

  const { state } = useLocation();

  const ID_ACHAT = Date.now();

  //  =========================
  // 1 $7
  // 2  $10.55
  // 3-4  $12.99
  // 5-11  $20.99
  // 12- free
  //  =========================

  let TOTAL;
  let shipping; // Declaring shipping outside the if-else block

  if ((state.type === 'PAPERBACK' || state.type === 'HARDCOVER') && (state.country === 'USA' || state.country === 'Canada')) {
    if (state.qty == 1) {
      shipping = 7;
    }
    if (state.qty == 2) {
      shipping = 10.55;
    }
    if (state.qty >= 3 && state.qty <= 4) {
      shipping = 12.99;
    }
    if (state.qty >= 5 && state.qty <= 9) {
      shipping = 20.99;
    }
    if (state.qty > 9) {
      shipping = 25;
    }
  
    TOTAL = state.amount + shipping;
  } else if (state.type === 'PAPERBACK' || state.type === 'HARDCOVER') {
    shipping = 45;
    TOTAL = state.amount + shipping;
  } else {
    TOTAL = state.amount;
  }
  



  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);

  // creates a paypal order
  const createOrder = (data, actions) => {

    return actions.order
      .create({
        purchase_units: [
          {
            description: "Sunflower",
            amount: {
              currency_code: "USD",
              value: Number(TOTAL).toFixed(2),
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);

      window.location.replace('https://juniorpersist.com/public/public/store_PreOrder_no_influencer?name=' + state.name
        // window.location.replace('http://127.0.0.1:8000/store_PreOrder_no_influencer?name=' + state.name
        + '&order_id=' + ID_ACHAT
        + '&phone=' + state.phone
        + '&email=' + state.email
        + '&type=' + state.type
        + '&qty=' + state.qty
        + '&amount=' + TOTAL
        + '&description=' + state.description

        + '&influencer_id=' + state.influencer_id
        + '&street=' + state.street
        + '&city=' + state.city
        + '&state_province=' + state.state_province
        + '&postal_ZIP_code=' + state.postal_ZIP_code
        + '&country=' + state.country

      );
    });
  };







  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
    navigate('../Donate')
  };


 

  return (
    <PayPalScriptProvider
      options={{
        //  LIVE
        "client-id": "AVRfkzj1lNDb1NLTdQBgf2qLz6jppRDP3AG8lDF31MKm22LTJie4kAT_KC_vQYIgfITBwMD228DqJt1a&disable-funding=paylater,venmo,card",

        //  SANBOX
        // "client-id": "AVWTBxdPJ6aLgCU2JRyxqLHjcUyRNKyuZIeuhuwa_pPGwwUtHT_Vbw76Kt90t0AAr6Mtn2FEbun2KZxV&disable-funding=paylater,venmo,card",
      }}
    >
      <div>
        <Header />

        <div className="container py-5">
          <div className="donate_bar3">
            <div className="h3 text-white cont763658 ">PAYMENT METHOD</div>
          </div>
          <div className="mt-4">
            <center>
              <div className="col-sm-8 shadow rounded px-2 py-4">




                <div className="row">

                  <div className="col-sm-6">
                    <div className=' justify-content-center  shadow-lg px-5 py-5 bg-white'>
                      {state.type == 'EBOOK' ? (
                        <div className=" "><img className="w-50" src={'images/book/1.png'} /></div>
                      ) : null}

                      {state.type == 'PAPERBACK' ? (
                        <div className=" "><img className="w-50" src={'images/paperback.png'} /></div>
                      ) : null}
                      {state.type == 'HARDCOVER' ? (
                        <div className=" "><img className="w-50" src={'images/hardcover.png'} /></div>
                      ) : null}
                      {state.type == 'AUDIOBOOK' ? (
                        <div className=" "><img className="w-50" src={'images/book/audio_home2.png'} /></div>
                      ) : null}
                      <div className="h3 py-2">{state.type}</div>

                      <div>
                        <table>
                          <tbody>
                            <tr>
                              <td className="px-3">Price:</td>
                              <td>$ {Number(state.amount / state.qty).toFixed(2)} x {state.qty}</td>
                            </tr>
                            <tr>
                              <td className="px-3">Subtotal:</td>
                              <td>$ {Number(state.amount).toFixed(2)}</td>
                            </tr>
                            {state.type === 'PAPERBACK' || state.type === 'HARDCOVER' ?

                              <tr>
                                <td className="px-3">Shipping:</td>
                                <td>
                                  {shipping == 0 ?
                                    <>
                                      Free
                                    </>
                                    :
                                    <>
                                      $ {Number(shipping).toFixed(2)}
                                    </>

                                  }

                                </td>
                              </tr>
                              :
                              <></>

                            }
                            <tr>
                              <td className="px-3">Taxes:</td>
                              <td>included</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>


                      <div className="h4 py-2">Total: ${Number(TOTAL).toFixed(2)} USD</div>
                      <div>{state.description}</div>
                      <div className="d-none d-sm-block"><br /><br /><br /></div>

                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className=' justify-content-center  shadow-lg px-5 py-5 bg-white'>
                      <div className="h2 opacity-75">You information</div>
                      <hr />
                      <h5 className="opacity-50">{state.name}</h5>
                      <span className="opacity-50">{state.phone}</span><br />
                      <span className="opacity-50">{state.email}</span>
                      <div className='form'>
                        <hr />
                        {/* ============Paypal============== */}
                        <div className="mt-4">
                          <PayPalButtons style={{ layout: "vertical" }} createOrder={createOrder} onApprove={onApprove} />
                        </div>

                        <hr />
                        {/* ============Stripe============== */}

                        <div className="mt-2">
                          {/* <form method="post" action="http://127.0.0.1:8000/order/stripe"> */}
                          <form method="post" action="https://juniorpersist.com/public/public/order/stripe">


                            <input type="hidden" name="name" value={state.name} />
                            <input type="hidden" name="phone" value={state.phone} />
                            <input type="hidden" name="email" value={state.email} />
                            <input type="hidden" name="type" value={state.type} />
                            <input type="hidden" name="qty" value={state.qty} />
                            <input type="hidden" name="description" value={state.description} />
                            <input type="hidden" name="influencer_id" value={state.influencer_id} />
                            <input type="hidden" name="street" value={state.street} />
                            <input type="hidden" name="city" value={state.city} />
                            <input type="hidden" name="state_province" value={state.state_province} />
                            <input type="hidden" name="postal_ZIP_code" value={state.postal_ZIP_code} />
                            <input type="hidden" name="amount" value={Number(TOTAL).toFixed(2)} />
                            <input type="hidden" name="country" value={state.country} />

                            <button className="btn_stripe" type="submit">
                              <div className="stripe_btn_jett mb-4">PAY WITH STRIPE</div>
                              <img src={"/images/icons/stripe_button.png"} className="w-100" />
                            </button>

                          </form>
                        </div>


                        <div className="h5 py-3">
                          <img src={"/images/icons/payment_new.png"} className="w-50" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>












            </center>
          </div>



        </div>








        <Footer />
      </div>
    </PayPalScriptProvider>
  )
}


export default Buy_checkout