import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function Endorsements() {


  return (
    <div>
      <Header />


      <div className="slider_endorse">
        <center>
          <div className="row container">
            <div className="col-sm-8">
              <div className="">
                <img src={"/images/img/persist_img1.png"} className="img_slider_endorse" />
              </div>
            </div>
            <div className="col-sm-4 align-self-center">
              <div className="text1_slider_endorse">
                Obtenez un certificat international en acquérant des compétences recherchées à l'échelle mondiale.
              </div>
              <br />
              <Link className="btn_login_jett" to="">READ MORE</Link>
            </div>
          </div>


        </center>
      </div>


      <div className="book">
        <center>
          <div className="row">

            <div className="col-sm-6">
              <a className="text_why_87" href="#WHY">Why endorse us?</a>
            </div>
            <div className="col-sm-6">
              <a className="text_why_87" href="#SATRTED">Apply to endorse us</a>
            </div>
          </div>
        </center>
      </div>


      <div className="mt-5 mb-3">
        <div className="text-center endorsement_text1">Meet Our Supporters</div>
      </div>




      <div className="container col-sm-9 pb-5">
        <div className="row">

          <div className="col-sm-3 ">
            <div className="jett_supp_met pb-3">
              <img className="image_endores" src="./images/users/user1.png" />
              <div className="name_endors ">ELIZABETH GILBERT</div>
              <center>
                <div className="py-2 ">
                  <Link className="btn_endors_1 px-3" to="">LISTEN NOW <i className="bi bi-play-fill"></i></Link>
                </div>
              </center>
            </div>
          </div>

          <div className="col-sm-3">
            <div className="jett_supp_met pb-3">
              <img className="image_endores" src="./images/users/user2.jpeg" />
              <div className="name_endors">ELIZABETH GILBERT</div>
              <center>
                <div className="py-2">
                  <Link className="btn_endors_1 px-3" to="">LISTEN NOW <i className="bi bi-play-fill"></i></Link>
                </div>
              </center>
            </div>
          </div>

          <div className="col-sm-3">
            <div className="jett_supp_met pb-3">
              <img className="image_endores" src="./images/users/user3.png" />
              <div className="name_endors">ELIZABETH GILBERT</div>
              <center>
                <div className="py-2">
                  <Link className="btn_endors_1 px-3" to="">LISTEN NOW <i className="bi bi-play-fill"></i></Link>
                </div>
              </center>
            </div>
          </div>

          <div className="col-sm-3">
            <div className="jett_supp_met pb-3">
              <img className="image_endores" src="./images/users/user4.jpeg" />
              <div className="name_endors">ELIZABETH GILBERT</div>
              <center>
                <div className="py-2">
                  <Link className="btn_endors_1 px-3" to="">LISTEN NOW <i className="bi bi-play-fill"></i></Link>
                </div>
              </center>
            </div>
          </div>

        </div>
      </div>

      {/* ============================Part 2 */}

      <div className="cont_social_about" id="WHY">
        <div className="col-sm-6 social_about py-3">
          <div className="text1_social_about">WHY ENDORSE US?</div>
          <div className="text2_social_about">
            Leam rom anywere. ¿ Pamered win top institudon to ensure qualit
            (3) Taught to become problem-solvers to get Haiti out of poverty. (3) Students
            will Work.rom anunere
          </div>
        </div>
      </div>


      {/* ==============================Part 3==================== */}


      <div className="d-flex justify-content-center py-4 cont_about_part3">
        <div className="col-sm-10 about_part3">
          <div className=' px-5 py-4'>

            <div className="part2_text_about_78599 mb-4 text-center">
              <div className="row">
                
                <div className="col-sm-4 border_img1_part1_2">
                  <div className="text_2_endors_101">ELEVATING THEIR BRANDS</div>
                  <div className="text_3_endors_101">
                    (1) Learn from anywhere. (2) Parnered with
                    top institution to ensure quality.(3Taught to become problem-solvers to get Haiti out of poverty. (3) Students will work from anywhere.
                  </div>
                </div>
                <div className="col-sm-4 border_img1_part1_2">
                  <div className="text_2_endors_101">ELEVATING THEIR BRANDS</div>
                  <div className="text_3_endors_101">
                    (1) Learn from anywhere. (2) Parnered with
                    top institution to ensure quality.(3Taught to become problem-solvers to get Haiti out of poverty. (3) Students will work from anywhere.
                  </div>
                </div>
                <div className="col-sm-4 ">
                  <div className="text_2_endors_101">ELEVATING THEIR BRANDS</div>
                  <div className="text_3_endors_101">
                    (1) Learn from anywhere. (2) Parnered with
                    top institution to ensure quality.(3Taught to become problem-solvers to get Haiti out of poverty. (3) Students will work from anywhere.
                  </div>
                </div>
               
              </div>
            </div>


          </div>
        </div>
      </div>


      <div className="container"><hr /></div>


      <div className="content_form_endor" id="SATRTED">

        <div className="pt-2">
          <div className="text-center endorsement_text1">GET SATRTED!</div>
        </div>
        <div className="form_endor col-sm-6 ">

          <div className="cont_input_form_endor">
            <input type="text" className="input_form_endor" placeholder="Firstname" />
          </div>

          <div className="cont_input_form_endor">
            <input type="text" className="input_form_endor" placeholder="Lastname" />
          </div>

          <div className="cont_input_form_endor">
            <input type="email" className="input_form_endor" placeholder="Email" />
          </div>

          <div className="cont_input_form_endor">
            <input type="phone" className="input_form_endor" placeholder="Phone Number" />
          </div>

          <div className="cont_input_form_endor">
            <textarea className="text_area_form_endor" placeholder="More Information" ></textarea>
          </div>

          <hr />
          <div className="cont_input_form_endor">
            <center>
              <div className="form_login">
                <input type="submit" className="fadeIn fourth" value="Submit" />
              </div>
            </center>
          </div>

        </div>
      </div>




      <br />  <br />  <br />
      <Footer />
    </div>
  )
}


export default Endorsements