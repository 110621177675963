import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import Video_student from "../Video_student";
import Slider_double from "../Slider_double";





function Fundraising() {


  return (
    <div>
      <Header />

      <div className="speaking_slider_all d-flex justify-content-center px-4">
        <div className="col-sm-9 py-4">
          <div className="row">
            <div className="col-sm-6 align-self-center">
              <div className="py-3">
                <div className="text1_speaking_slider">FUNDRAISING</div>
                <div className="text2_speaking_slider">BECOME HAITI’S SUPERPOWER</div>
                <div className="text3_speaking_slider mt-2">Help us build the Pranzel Elite School's <br />building to empower $500 students.</div>
                <br />
                <div className="">
                  <a className="btn_home_jett_7864_3 py-2 px-5" href={"/Donate"}>Enter an Amount...</a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 jett_block">
              <img src={'./images/img/fundraising_slider.png'} className="img_84774" />
            </div>
          </div>
        </div>
      </div>


      <div className="mt-5 mb-3">
        <div className="text-center col-sm-7 m-auto text-white ">
          <div className=" bg-black  text-white h3 py-2">WHY THIS FUNDRAISER AND ITS IMPACT</div>
          <div className="text1_fund px-5">
            Our current 80-student classroom can't meet the growing demand. Your contribution will help build a transformative school for 500 students, educating each to empower 10 more in their community. Our model will enable us to collectively make a lasting impact on thousands every year.          </div>
          <div className="text2_fund px-5 py-2 mb-3">A UNIQUE MODEL: EDUCATE ONE YOUTH, EMPOWER TEN.</div>
        </div>

        <img src={'images/img/23ed.png'} className="img_fund_5986" />
      </div>


      <div className=" d-flex justify-content-center ">
        <div className="fund_black_sect col-sm-7">


          <div className="fund_black_sect_text mt-3">

            <div className="cont1_fund_black_sect_text">





              <div className="h3 text-white">STUDENT BENEFITS</div>
              <span className="fund_black_001_text1">You're opening the door of opportunity for our students to access…</span>
              <ul>
                <li className="fund_black_001_text1">High-speed Starlink WIFI</li>
                <li className="fund_black_001_text1">24/7 computer availability & global mentorship</li>
                <li className="fund_black_001_text1">English learning & competitive skills building training until job-ready</li>
              </ul>

              <div className="h3 text-white">TRANSPARENCY</div>
              <ul>
                <li className="fund_black_001_text1">Complete transparency assurance (Our board oversees everything.) </li>
                <li className="fund_black_001_text1">A full impact/transparency report can be provided to you upon request</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <br /><br />

      {/* ==============================Part 2==================== */}


      <Slider_double />


      {/* ========================Part3 */}

      <Video_student />

      {/* ================================ */}


      <div className="py-5">
        <center>
          <div className=" col-sm-7 bg-black  text-white h3 py-2">TAKE ACTION NOW</div>
          <div className=" col-sm-7">
            <div className="fund_black_001_text1 py-3">Join us in changing the course of Haiti’s future through the power of knowledge.</div>
          </div>
          <div className="py-3">
          <a href={'/Donate'} className="btn_fund_down" >Donate Now</a>
          </div>
        </center>
      </div>


      <Footer />
    </div>
  )
}


export default Fundraising