import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function Advisor() {


  return (
    <div>
      <Header />
      
      <Footer />
    </div>
  )
}


export default Advisor