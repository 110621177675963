import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function Donate_Success() {


  return (
    <div>
      <Header />


 


      <div className="book">
        <center>


          <section className="mail-seccess section py-3">
            <div className="container ">

              <center>
                <div className="col-lg-6 ">

                  <div className="success-inner">
                    <h1><i className="fa fa-check"></i><span>Your donation has been completed successfully!</span></h1>
                    <p className="jett87345_1">
                      Thank you very much for your support. Your generosity means everything to us and to young people Haitian. God bless you!,
                    </p>
                  </div>

                </div>







                <div className="shadow col-sm-8 jett87345_1" >

                  <div className="py-3 px-3 jett87345_2" >
                    <div className="py-1">
                      <center>
                        <div className='col-sm-8 justify-content-center  shadow-lg px-5 py-5 bg-white'>
                          <span className="text_jett735428">You want to remain anonymous, <Link to={'/'}> click here</Link>,<br /> otherwise fill out the form.</span>
                          <div className='form'>
                            <input className='input_part2_jett' name='' placeholder='Name' />
                            <input className='input_part2_jett' name='' placeholder='Phone' />
                            <input className='input_part2_jett' name='' placeholder='Email Address' />
                            <textarea className='input_part2_jett' name='' placeholder='Note'></textarea>
                            <button type='submit' className='btn_part2_jett mt-2 py-1'>SUBMIT</button>
                          </div>
                        </div>


                      </center>
                    </div>
                  </div>
                </div>
              </center>

            </div>
            <br /><br /><br />
          </section>


        </center>
      </div>













      <div className="">
        <div className="buy_por984774_text1 mt-3">SHOP THE PERSIST COLLECTION</div>
        <div className="buy_por984774_text2">LET'S SPREAD A POSITIVE VIBE TOGETHER!</div>
        <div className="mt-4">
          <center>
            <a className="buy_box_btn px-5" target="_blank" href="https://ayisyenkapab.com/persist-collection/">SHOP NOW</a>
          </center>
          <img className="w-100 mt-3" src={'/images/new/pub_img1.png'} />
        </div>
      </div>
      <Footer />
    </div>
  )
}


export default Donate_Success