import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import Join from "../Join";
import Review from "../review/review";
import SubmitVideo from "../SubmitVideo";
function Video() {


  return (
    <div>
      <Header />
      <div className="bg-black text-center">
        <div className="py-3 slider_book_text1">LATEST REVIEWS</div>
      </div>



      <div className="col-sm-5 m-auto mt-3">
        <center>
          <Review />
        </center>
      </div>


      <div className="container col-sm-12 pb-5">
        <div className="jett_scrollmenu">

          <div className="col-sm-3 jett_scrollmenu_in">
            <div className="video_part_cont mb-4">
              <iframe className='iframe_part3' src="https://player.vimeo.com/video/957777985" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              <div className="part2_video_cont py-4">
                <div className="text1_part_cont">Readers' Reactions </div>
                <div className="text2_part_cont ">"It's an absolute masterpiece."</div>
              </div>
            </div>
          </div>


          <div className="col-sm-3 jett_scrollmenu_in">
            <div className="video_part_cont mb-4">
              <iframe className='iframe_part3' src="https://player.vimeo.com/video/956163210" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              <div className="part2_video_cont py-4">
                <div className="text1_part_cont">Review from University Professor</div>
                <div className="text2_part_cont ">Dr. Rumu's reaction to the book.</div>
              </div>
            </div>
          </div>



          <div className="col-sm-3 jett_scrollmenu_in">
            <div className="video_part_cont mb-4">
              <iframe className='iframe_part3' src="https://player.vimeo.com/video/957451789" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              <div className="part2_video_cont py-4">
                <div className="text1_part_cont">International Event</div>
                <div className="text2_part_cont ">Reflecting on the book "Persist, Persist, Persist."</div>
              </div>
            </div>
          </div>

          <div className="col-sm-3 jett_scrollmenu_in">
            <div className="video_part_cont mb-4">
              <iframe className='iframe_part3' src="https://player.vimeo.com/video/983285757" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              <div className="part2_video_cont py-4">
                <div className="text1_part_cont">Persist, Persist, Persist.</div>
                <div className="text2_part_cont ">You have what it takes</div>
              </div>
            </div>
          </div>


          <div className="col-sm-3 jett_scrollmenu_in">
            <div className="video_part_cont mb-4">
              <iframe className='iframe_part3' src="https://player.vimeo.com/video/950826541" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              <div className="part2_video_cont py-4">
                <div className="text1_part_cont">Life Lessons</div>
                <div className="text2_part_cont ">Life lessons from the Haiti earthquake.</div>
              </div>
            </div>
          </div>

          <div className="col-sm-3 jett_scrollmenu_in">
            <div className="video_part_cont mb-4">
              <iframe className='iframe_part3' src="https://player.vimeo.com/video/951139699" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              <div className="part2_video_cont py-4">
                <div className="text1_part_cont">Book Reaction from Kenya</div>
                <div className="text2_part_cont ">Feeling stuck in life? This book is for you!</div>
              </div>
            </div>
          </div>





          <div className="col-sm-3 jett_scrollmenu_in">
            <div className="video_part_cont mb-4">
              <iframe className='iframe_part3' src="https://player.vimeo.com/video/950825473" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              <div className="part2_video_cont py-4">
                <div className="text1_part_cont">A Young Professional's Reaction</div>
                <div className="text2_part_cont ">Serenity's impression of "Persist, Persist, Persist."</div>
              </div>
            </div>
          </div>




          <div className="col-sm-3 jett_scrollmenu_in">
            <div className="video_part_cont mb-4">
              <iframe className='iframe_part3' src="https://player.vimeo.com/video/952160011" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              <div className="part2_video_cont py-4">
                <div className="text1_part_cont">Book Reaction from Pakistan</div>
                <div className="text2_part_cont ">Reacting to the book with the author.</div>
              </div>
            </div>
          </div>

          <div className="col-sm-3 jett_scrollmenu_in">
            <div className="video_part_cont mb-4">
              <iframe className='iframe_part3' src="https://player.vimeo.com/video/952406607" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              <div className="part2_video_cont py-4">
                <div className="text1_part_cont">Review from Tanzania</div>
                <div className="text2_part_cont ">"If you like suspense, get this book!"</div>
              </div>
            </div>
          </div>

          <div className="col-sm-3 jett_scrollmenu_in">
            <div className="video_part_cont mb-4">
              <iframe className='iframe_part3' src="https://player.vimeo.com/video/951140872" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              <div className="part2_video_cont py-4">
                <div className="text1_part_cont">Life Lessons</div>
                <div className="text2_part_cont ">The power of decision for your dream.</div>
              </div>
            </div>
          </div>

          <div className="col-sm-3 jett_scrollmenu_in">
            <div className="video_part_cont mb-4">
              <iframe className='iframe_part3' src="https://player.vimeo.com/video/951146373" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              <div className="part2_video_cont py-4">
                <div className="text1_part_cont">An Immigrant's Viewpoint</div>
                <div className="text2_part_cont ">Is the US still the land of opportunity?</div>
              </div>
            </div>
          </div>


          <div className="col-sm-3 jett_scrollmenu_in">
            <div className="video_part_cont mb-4">
              <iframe className='iframe_part3' src="https://player.vimeo.com/video/950824943" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              <div className="part2_video_cont py-4">
                <div className="text1_part_cont">Insights on the Book</div>
                <div className="text2_part_cont ">Here's why I wrote the book.</div>
              </div>
            </div>
          </div>


        </div>
      </div>
      {/* ======================================== */}
      <SubmitVideo />
      {/* ======================================== */}



      {/* ====================== */}

      {/* ==================== */}






      <Footer />
    </div>
  )
}


export default Video