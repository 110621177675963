import React, { useState, useEffect } from 'react';
import ePub from 'epubjs';

const EpubReader = () => {
  const [book, setBook] = useState(null);
  const [rendition, setRendition] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const loadBook = async () => {
      const book = ePub('/epub/book.epub');
      setBook(book);

      const rendition = book.renderTo('viewer', { width: '100%', height: '100%' });
      setRendition(rendition);

      rendition.display().then(() => {
        updatePageCount();
      });

      rendition.on('relocated', (location) => {
        setCurrentPage(location.start.index);
      });

      // Cleanup on component unmount
      return () => {
        rendition.destroy();
      };
    };

    loadBook();
  }, []);

  const updatePageCount = async () => {
    if (rendition) {
      // Get the total number of pages
      const pagination = await rendition.getPagination();
      if (pagination && pagination.total) {
        setTotalPages(pagination.total);
      } else {
        // Fallback or manual calculation if total pages are not available
        setTotalPages(217); // Use the known number of pages if the library fails to provide it
      }
    }
  };

  const nextPage = () => {
    if (rendition) {
      rendition.next();
    }
  };

  const previousPage = () => {
    if (rendition) {
      rendition.prev();
    }
  };

  return (
    <div>
      <div id="viewer" style={{ width: '100%', height: '500px', overflow: 'auto' }}></div>
      <button onClick={previousPage} disabled={currentPage <= 0}>Previous</button>
      <button onClick={nextPage} disabled={currentPage >= totalPages - 1}>Next</button>
      <p>Page {currentPage + 1} of {totalPages}</p>
    </div>
  );
};

export default EpubReader;
