import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import React from 'react';
import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';


import { useNavigate } from 'react-router-dom';
import { useState } from "react";



function Podcast_impact_on_fire() {
  const navigate = useNavigate();
  const [firstname, setFistname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");

  const [expertise, setExpertise] = useState("");
  const [main_topics_are_you_excited, setMain_topics_are_you_excited] = useState("");
  const [anything_off_limits, setAnything_off_limits] = useState("");
  const [more_about_any_programs, setMore_about_any_programs] = useState("");
  const [include_bio_introduce, setInclude_bio_introduce] = useState("");
  const [list_any_social_handles, setList_any_social_handles] = useState("");
  const [any_previously_recorded_podcasts, setAny_previously_recorded_podcasts] = useState("");

  const [error, setError] = useState("")

  async function addData(e) {
    e.preventDefault();
    setError("")
    if (firstname && lastname && email && expertise && main_topics_are_you_excited && anything_off_limits && more_about_any_programs && include_bio_introduce && list_any_social_handles && any_previously_recorded_podcasts) {

      console.warn(firstname, lastname, email, expertise, main_topics_are_you_excited, anything_off_limits, more_about_any_programs, include_bio_introduce, list_any_social_handles, any_previously_recorded_podcasts)
      const formData = new FormData();
      formData.append('firstname', firstname);
      formData.append('lastname', lastname);
      formData.append('email', email);

      formData.append('expertise', expertise);
      formData.append('main_topics_are_you_excited', main_topics_are_you_excited);
      formData.append('anything_off_limits', anything_off_limits);
      formData.append('more_about_any_programs', more_about_any_programs);
      formData.append('include_bio_introduce', include_bio_introduce);
      formData.append('list_any_social_handles', list_any_social_handles);
      formData.append('any_previously_recorded_podcasts', any_previously_recorded_podcasts);
      formData.append('type_podcast', 'Podcast Impact On Fire');
      try {
        let result = await fetch("https://juniorpersist.com/public/public/api/addPodcast", {
        // let result = await fetch("http://127.0.0.1:8000/api/addPodcast", {
          method: 'POST',
          body: formData
        });
        navigate('/Success_message')
      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Error: all fields required");
    }
  }

  return (
    <div>
      <Header />

      <div className="podcast_all">
        <div className="podcast_on_fire ">

          <div className="row py-5">
            {/* <div className="col-sm-5 py-2">
              <div className="text_slider">
                <div className="podcast_box2_slider">

                  <div className="">
                    <MDBCarousel showControls fade touch={false}>
                      <MDBCarouselItem itemId={1}>
                        <img src='./images/podcast/slider/1.png' className='d-block w-100' alt='...' />
                      </MDBCarouselItem>
                      <MDBCarouselItem itemId={2}>
                        <img src='./images/podcast/slider/2.png' className='d-block w-100' alt='...' />
                      </MDBCarouselItem>
                    </MDBCarousel>
                  </div>

                  <span to="/buy" className="btn1_podcast_box px-3 py-1">UPCOMING EPISODE</span><br />
                </div>
              </div>
            </div>

            <div className="col-sm-2"></div> */} 

            {/* ============ */}
            <center>
              <div className="col-sm-5 py-2">
                <div className="text_slider">
                  <div className="podcast_box2">
                    <div className="new_about_part1">
                      <div className="new_about_text1">THE IMPACT ON FIRE PODCAST</div>
                      <div className="new_about_text2 ">Bringing You Stories of Doers Changing <br />Lives Around the World</div>
                    </div>
                    <div className="new_about_part2 mt-3">
                      <div className="bg-black mt-2 py-3">
                        <div className="new_about_part2_box1  "> BECOME A GUEST ON OUR PODCAST! </div>
                        <div className="mb-3"><a href="#INTERVIEW" className="btn1_podcast_box px-3 py-1">GET STARTED</a></div>
                        <img src={'/images/icons/ayisyenkapab.png'} className="img_7464" />
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </center>


          </div>

          {/* ================ */}
          <div className="new_about_bar_menu ">
            <center>
              <div className="col-sm-10 ">
                <div className="row">
                  <div className="col-sm-4 py-3">
                    <a href={'#RECENT'} className="new_about_bar_menu_btn"><img className="bg-white rounded-circle icon_0098" src={'/images/icons/micro.png'} /> LISTEN TO THE LATEST EPISODES</a>
                  </div>
                  <div className="col-sm-4 py-3">
                    <a href="https://www.youtube.com/@juniorpersist" target="_blank" className="new_about_bar_menu_btn"><img className="icon_0098" src={'/images/icons/youtube.png'} /> WATCH THE SHOW ON YOUTUBE</a>
                  </div>
                  <div className="col-sm-4 py-3">
                    <a href={'#INTERVIEW'} className="new_about_bar_menu_btn"><img className="bg-white rounded-circle icon_0098" src={'/images/icons/interview.png'} /> GUEST INTERVIEW QUESTIONS</a>
                  </div>
                </div>
              </div>
            </center>
          </div>



        </div>


        {/* ==============================Part 1_2==================== */}
        <div id="RECENT" className="py-4">
          <div className="part2_text_about_78588 mb-2 text-black text-center">RECENT EPISODES</div>


          <div className="container">
            <div className="jett_scrollmenu">


              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <img src={'/images/podcast/on_fire/1.png'} className="img_podcast_recent" />
                  <center>
                    <img src={'/images/icons/micro.png'} className="icon_podcast_recent" />
                    <div className="bar_podcast_recent"></div>
                  </center>
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">The Power of Bringing People Together </div>
                    <div className="text2_podcast_recent">Founder of Bring the Change</div>
                  </div>
                  <br />
                </div>
              </div>


              

              




              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <img src={'/images/podcast/on_fire/2.png'} className="img_podcast_recent" />
                  <center>
                    <img src={'/images/icons/micro.png'} className="icon_podcast_recent" />
                    <div className="bar_podcast_recent"></div>
                  </center>
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">Here's Why Your Dream Is Worth the Fight</div>
                    <div className="text2_podcast_recent">A Couple’s Business Journey</div>
                  </div>
                  <br />
                </div>
              </div>

              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <img src={'/images/podcast/on_fire/3.png'} className="img_podcast_recent" />
                  <center>
                    <img src={'/images/icons/micro.png'} className="icon_podcast_recent" />
                    <div className="bar_podcast_recent"></div>
                  </center>
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">Utilizing Profits to Positively Impact Lives</div>
                    <div className="text2_podcast_recent">The Brothers’ Journey</div>
                  </div>
                  <br />
                </div>
              </div>

              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <img src={'/images/podcast/on_fire/4.png'} className="img_podcast_recent" />
                  <center>
                    <img src={'/images/icons/micro.png'} className="icon_podcast_recent" />
                    <div className="bar_podcast_recent"></div>
                  </center>
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">From the Dominican Republic to the United States</div>
                    <div className="text2_podcast_recent">The Story of Carlos Canela</div>
                  </div>
                  <br />
                </div>
              </div>


            </div>
          </div>
        </div>

        {/* ==============================Part 1_2==================== */}
        <div className="bar_separation1_podcast"></div>
        {/* ==============================Part 1_2==================== */}

        <div className="podcast_part2 d-flex justify-content-center">

          <div className="row col-sm-10 ">
            <div className="col-sm-5 align-self-center">
              <div className="part2_text_about_78588 mb-2 text-black">WANT TO CHANGE THE WORLD? </div>
              <p className="part2_text2_about_78588 text-black">
                Don't be left behind—join the incredible community of the Impact on Fire podcast.
                <br /><br />
                We unveil captivating stories of nonprofit leaders, businesses, and entrepreneurs who go the extra mile to impact others’ lives, both locally and around the world.
                <br /><br />
                If you're on a quest for profound insights on turning your passion or business into a force for meaningful impact, this podcast is crafted exclusively for individuals like you. Get ready to be amazed!
              </p>
            </div>

            <div className="col-sm-7">
              <div className="px-5">
                <img src={'/images/podcast/2.png'} className="img_podcast1" />
              </div>
            </div>
          </div>
        </div>
        {/* ============================Part 2 */}

        <div className="podcast_part3">
          <center>
            <div className="podcast_part3_box1_other col-sm-5">LISTEN TO THE IMPACT ON FIRE PODCAST</div>
            <img src={'/images/icons/impactonfire.png'} className="img_podcast2 py-4" />
          </center>
        </div>

        <center>
          <div className="row bg-black col-sm-5">
            <div className="col-sm-4">
              <div className="podcast_part3_text1">
                <a className="bi_jett" href="https://open.spotify.com/show/7h4HLkd04yXXiMFeiYSJHH" target="_blank">
                  <table>
                    <tr>
                      <td><i className="bi bi-spotify"></i></td>
                      <td><div className="podcast_part3_text2 px-2">SPOTIFY</div></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="podcast_part3_text1">
                <a className="bi_jett" href="https://podcasts.apple.com/us/podcast/impact-on-fire/id1723329946" target="_blank">
                  <table>
                    <tr>
                      <td><i className="bi bi-broadcast-pin"></i></td>
                      <td><div className="podcast_part3_text2 px-2">APPLE</div></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="podcast_part3_text1">
                <a className="bi_jett" href="https://www.youtube.com/@juniorpersist" target="_blank">
                  <table>
                    <tr>
                      <td><i className="bi bi-youtube"></i></td>
                      <td><div className="podcast_part3_text2 px-2">YOUTUBE</div></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>
          </div>
        </center>

        {/* ============================Part 2 */}
        <center>
          <div className="col-sm-4 py-4 mt-2">
            <div className="new_about_double_box_1_text1 text-black">Junior's Social Media in English</div>
            <div className="new_about_part2_box1 mt-1 py-3">
              <div className="d-flex justify-content-center jett_scrollmenu">
                <div className="col-sm-2">
                  <a className="media_fa" href="https://www.facebook.com/juniorpersist" target="_blank">
                    <i className=" fa fa-facebook fa-lg"></i>
                  </a>
                </div>
                <div className="col-sm-2">
                  <a className="media_fa" href="https://www.instagram.com/juniorpersist" target="_blank">
                    <i className=" fa fa-instagram fa-lg"></i>
                  </a>
                </div>
                <div className="col-sm-2">
                  <a className="media_fa" href="https://twitter.com/Juniorpersist" target="_blank">
                    <i className=" fa fa-twitter fa-lg"></i>
                  </a>
                </div>
                <div className="col-sm-2">
                  <a className="media_fa" href="https://www.linkedin.com/in/junior-bernard-815825146/" target="_blank">
                    <i className=" fa fa-linkedin fa-lg"></i>
                  </a>
                </div>
                <div className="col-sm-2">
                  <a className="media_fa" href="https://www.youtube.com/@juniorpersist" target="_blank">
                    <i className=" fa fa-youtube fa-lg"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </center>
        {/* ============================Part 2 */}


        <div className="bg-black py-3 mt-5" id="INTERVIEW">
          <div className="part2_text_about_78599 text-center">GUEST FEATURE FORM</div>
          <p className="part2_text2_about_78588 text-white text-center opacity-75">Please complete the form below to apply to appear on the Impact on Fire Podcast.</p>
        </div>
        {/* ==============================Part 3==================== */}

        <div className="d-flex justify-content-center">
          <div className="podcast_form_content shadow-sm  col-sm-6">
            <div className="podcast_form shadow">
              <div className="podcast_form_text1">A few to make sure we have a great recording!</div>
              <div className="podcast_form_text2 py-3">* Required information</div>
              {error && <div className='error_text'>{error}</div>}


              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">First name *</div>
                <input className="input_podcast" type="text" placeholder="Fist name" onChange={(e) => setFistname(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Last name *</div>
                <input className="input_podcast" type="text" placeholder="Last name" onChange={(e) => setLastname(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">What's your email? *</div>
                <input className="input_podcast" type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
              </div>








              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">What's your expertise, or could you share a bit about yourself?</div>
                <input className="input_podcast py-4" type="text" placeholder="Not answered yet" onChange={(e) => setExpertise(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">What main topics are you excited to talk about?</div>
                <input className="input_podcast py-4" type="text" placeholder="Not answered yet" onChange={(e) => setMain_topics_are_you_excited(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Is there anything off limits/you want to avoid?</div>
                <input className="input_podcast py-4" type="text" placeholder="Not answered yet" onChange={(e) => setAnything_off_limits(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Tell me more about any programs, launches, or offers you want to talk about!</div>
                <input className="input_podcast py-4" type="text" placeholder="Not answered yet" onChange={(e) => setMore_about_any_programs(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Please include a bio so we can introduce you!</div>
                <input className="input_podcast py-4" type="text" placeholder="Not answered yet" onChange={(e) => setInclude_bio_introduce(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Please list any social handles you want highlighted:</div>
                <input className="input_podcast py-4" type="text" placeholder="Not answered yet" onChange={(e) => setList_any_social_handles(e.target.value)} />
              </div>

              <div className="podcast_form_box_input py-2">
                <div className="label_pod_text py-2">Do you have any previously recorded podcasts, interviews, blog posts, social posts that you think I should read before the interview to get to know you and your expertise better?</div>
                <input className="input_podcast py-4" type="text" placeholder="Not answered yet" onChange={(e) => setAny_previously_recorded_podcasts(e.target.value)} />
              </div>

              <center>
                <div className="col-sm-4">
                  <button type='submit' onClick={(e) => addData(e)} className='btn_part2_jett mt-2 py-1'> SUBMIT</button>
                </div>
              </center>



            </div>
          </div>
        </div>

        {/* ============================Part 2 */}

        <div className="bg-black py-3 mt-5">
          <div className="part2_text_about_78599 text-center">PODCAST GUEST QUESTION LIST</div>
          <p className="part2_text2_about_78588 text-white text-center opacity-75">Curious about what kind of questions you will be asked during your interview on the Impact on Fire Podcast? Click below for details!</p>
        </div>


        <center>
          <div className="py-5">
            <a href="/pdf/ENGLISH_INTERVIEW_QUESTIONS.pdf" download className="btn_home_jett_7864 px-4 py-2">DOWNLOAD INTERVIEW QUESTIONS</a>
          </div>
        </center>


        <br /><br /><br /><br />









      </div>
      <Footer />
    </div>
  )
}


export default Podcast_impact_on_fire