import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
function Ways_to_contribute() {

  // ==================DONATE TIME Form===================

  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  async function addData1(e) {
    e.preventDefault();
    setError1("");
    if (name && phone && email && description) {
      console.warn(name, phone, email, description)
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('description', description);
      formData.append('type', 'DONATE TIME');

      try {
        let result = await fetch("https://juniorpersist.com/public/public/api/addHelpStandWith", {
           // let result = await fetch("http://127.0.0.1:8000/api/addHelpStandWith", {
          method: 'POST',
          body: formData
        });
        navigate('/Success_message')
      } catch (error1) {
        console.log(error1);
      }
    } else {
      setError1("Error: all fields required");
    }
  }
  // ==================CONNECT US WITH 5 PEOPLE Form===================
  async function addData2(e) {
    e.preventDefault();
    setError2("");
    if (name && phone && email && description) {

      console.warn(name, phone, email, description)
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('description', description);
      formData.append('type', 'CONNECT US WITH 5 PEOPLE');

      try {
        let result = await fetch("https://juniorpersist.com/backend/public/api/addHelpStandWith", {
           // let result = await fetch("http://127.0.0.1:8000/api/addHelpStandWith", {
          method: 'POST',
          body: formData
        });
        navigate('/Success_message')
      } catch (error2) {
        console.log(error2);
      }
    } else {
      setError2("Error: all fields required");
    }
  }
  // ==================INFORM US ABOUT OPPORTUNITIES Form===================
  async function addData3(e) {
    e.preventDefault();
    setError3();

    if (name && phone && email && description) {
      console.warn(name, phone, email, description)
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('description', description);

      try {
        formData.append('type', 'INFORM US ABOUT OPPORTUNITIES');
        let result = await fetch("https://juniorpersist.com/backend/public/api/addHelpStandWith", {
           // let result = await fetch("http://127.0.0.1:8000/api/addHelpStandWith", {
          method: 'POST',
          body: formData
        });
        navigate('/Success_message')
      } catch (error3) {
        console.log(error3);
      }
    } else {
        setError3("Error: all fields required");
    }
  }
 
  // ==================HELP US PROMOTE OUR EFFORTS Form===================
  async function addData4(e) {
    e.preventDefault();
    setError4("");
    if(name && phone && email && description){
    console.warn(name, phone, email, description)
    const formData = new FormData();
    formData.append('name', name);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('description', description);
    formData.append('type', 'HELP US PROMOTE OUR EFFORTS');

    try{
    let result = await fetch("https://juniorpersist.com/backend/public/api/addHelpStandWith", {
    // let result = await fetch("http://127.0.0.1:8000/api/addHelpStandWith", {
      method: 'POST',
      body: formData
    });
    navigate('/Success_message')
  }catch (error4){
    console.log(error4);
  }
}else{
  setError4("Error: all field required");
}
}
  // =====================================

  return (
    <div>
      <Header />

      <div className="podcast_all">



        {/* ==============================Part 1_2==================== */}


        <div className="podcast_part2 px-4 d-flex justify-content-center">

          <div className="row col-sm-10">
            <div className="col-sm-5 align-self-center py-5">
              <div className="part2_text_about_78588 mb-2 text-black ">THERE ARE MANY WAYS TO CONTRIBUTE</div>
              <p className="part2_text2_about_78588 text-black">
                Funds are crucial, but our mission requires more than just financial support. Regardless of your background or location, we invite you to join us in our endeavor to create positive change in Haiti through education. Explore the options below to discover diverse ways you can contribute and make a meaningful impact.
              </p>
              <div className="py-3">
                <a href="#STARTED" className="btn_home_jett_7864 px-4 py-3">GET STARTED NOW</a>
              </div>
            </div>

            <div className="col-sm-7">
              <div className="px-5">
                <img src={'/images/way/1.png'} className="img_podcast1" />
              </div>
            </div>
          </div>
        </div>
        <div className="new_about_bar_menu ">
          <center>
            <div className="col-sm-10 ">
              <div className="row">
                <div className="col-sm-4 py-3">
                  <a href="#DONATE" className="new_about_bar_menu_btn">DONATE TIME</a>
                </div>
                <div className="col-sm-4 py-3">
                  <a href="#CONNECT" className="new_about_bar_menu_btn">CONNECT US WITH 5 PEOPLE</a>
                </div>
                <div className="col-sm-4 py-3">
                  <a href="#SHARE" className="new_about_bar_menu_btn">SHARE YOUR RESOURCES</a>
                </div>

                <div className="col-sm-4 py-3">
                  <a href="#HOLD" className="new_about_bar_menu_btn">HOLD A FUNDRAISING EVENT</a>
                </div>
                <div className="col-sm-4 py-3">
                  <a href="#INFORM" className="new_about_bar_menu_btn">INFORM US ABOUT OPPORTUNITIES</a>
                </div>
                <div className="col-sm-4 py-3">
                  <a href="#HELP" className="new_about_bar_menu_btn">HELP US PROMOTE OUR EFFORTS</a>
                </div>
              </div>
            </div>
          </center>
        </div>
        {/* ============================Box1  */}

        <div className="podcast_part3 pb-5" id="STARTED">
          <center>
            <div id="DONATE" className="way_to_contribute_box1 col-sm-5">DONATE TIME</div>
            <img src={'/images/way/donate_time.png'} className="img_way_to_contribute py-4" />

            <div className="way_to_contribute_box1_text1 col-sm-5">
              Whether it's grant writing, laptop collection, or fundraising event planning, there are countless ways for you to make a difference.
            </div>

            <div className='col-sm-4 mt-3 justify-content-center  shadow-lg px-5 py-4 bg-white'>
              {/* ================================= */}
              <div className='form'>
                {error1 && <div className='error_text'>{error1}</div>}
                <input className="input_part2_jett" placeholder='Name' type="text" id="name" required="" onChange={(e) => setName(e.target.value)} />
                <input className="input_part2_jett" placeholder='Phone' type="text" id="phone" required="" onChange={(e) => setPhone(e.target.value)} />
                <input className="input_part2_jett" placeholder='Email Address' type="text" id="email" required="" onChange={(e) => setEmail(e.target.value)} />
                <textarea className='textarea_part2_jett' id="description" onChange={(e) => setDescription(e.target.value)} placeholder="Kindly provide information on the following:&#10;1) your professional background,&#10;2) how much you would like to donate weekly,&#10;3) areas of interest in which you wish to contribute."></textarea>
                <button onClick={(e) => addData1(e)} className='btn_part2_jett mt-2 py-1'>TAKE ACTION</button>
              </div>
              {/* ================================= */}
            </div>

          </center>
        </div>


        {/* ============================Box2*/}

        <div id="CONNECT" className="podcast_part3 pb-5 mt-4">
          <center>
            <div className="way_to_contribute_box2 col-sm-5">CONNECT US WITH 5 PEOPLE</div>
            <img src={'/images/way/tell_five_people_you_know.png'} className="img_way_to_contribute py-4" />

            <div className="way_to_contribute_box1_text1 col-sm-5">
              Do you know someone, a family member, friend, or an organization that may be interested in helping? Help us get in touch with them.
            </div>

            <div className='col-sm-4 mt-3 justify-content-center  shadow-lg px-5 py-4 bg-white'>

              {/* ================================= */}
              <div className='form'>
                {error2 && <div className='error_text'>{error2}</div>}
                <input className="input_part2_jett" placeholder='Name' type="text" id="name" required="" onChange={(e) => setName(e.target.value)} />
                <input className="input_part2_jett" placeholder='Phone' type="text" id="phone" required="" onChange={(e) => setPhone(e.target.value)} />
                <input className="input_part2_jett" placeholder='Email Address' type="text" id="email" required="" onChange={(e) => setEmail(e.target.value)} />
                <textarea className='textarea_part2_jett' id="description" onChange={(e) => setDescription(e.target.value)} placeholder='Please provide as much information as you can, such as name, contact details, and anything else you deem necessary for us to know. Also, when reaching out to your reference(s), do you prefer to be mentioned or remain anonymous?'></textarea>
                <button onClick={(e) => addData2(e)} className='btn_part2_jett mt-2 py-1'>TAKE ACTION</button>
              </div>
              {/* ================================= */}
            </div>

          </center>
        </div>

        {/* ============================Box3 */}

        <div id="SHARE" className="podcast_part3 pb-5 mt-4">
          <center>
            <div className="way_to_contribute_box3 col-sm-5">SHARE YOUR RESOURCES</div>
            <img src={'/images/way/donate_resources.png'} className="img_way_to_contribute py-4" />

            <div className="way_to_contribute_box1_text1 col-sm-5">
              Whether an organization or individual, your donation of laptops, solar panels, or construction tools is greatly needed and highly appreciated.
            </div>

            <div className="py-3">
              <a href="https://pranzeleliteschool.com/donate_items" target="_blank" className="btn_home_jett_8000 px-4 py-2">LEARN MORE</a>

            </div>

          </center>
        </div>

        {/* ============================Box4 */}

        <div id="HOLD" className="podcast_part3 pb-5 mt-4">
          <center>
            <div className="way_to_contribute_box4 col-sm-5">HOLD A FUNDRAISING EVENT</div>
            <img src={'/images/way/hold_fundraising_event.png'} className="img_way_to_contribute py-4" />
            <div className="way_to_contribute_box1_text1 col-sm-5">
              Junior is available to speak at your company, church, or school to advocate and raise funds for the construction of Pranzel Elite School. Reach out to find out how we can partner together.
            </div>
            <div className="py-3">
              <Link to="/Speaking" className="btn_home_jett_8000 px-4 py-2">LEARN MORE</Link>
            </div>

          </center>
        </div>

        {/* ============================Box5 */}

        <div id="INFORM" className="podcast_part3 pb-5 mt-4">
          <center>
            <div className="way_to_contribute_box5 col-sm-5">INFORM US ABOUT OPPORTUNITIES</div>
            <img src={'/images/way/inform_about_opportunities.png'} className="img_way_to_contribute py-4" />

            <div className="way_to_contribute_box1_text1 col-sm-5">
              Are there opportunities in your area or organization that you believe can benefit Pranzel or our student body? Please share with us; we're always on the lookout for new opportunities, big or small.
            </div>

            <div className='col-sm-4 mt-3 justify-content-center  shadow-lg px-5 py-4 bg-white'>
              {/* ================================= */}
              <div className='form'>
              {error3 && <div className='error_text'>{error3}</div>}
                <input className="input_part2_jett" placeholder='Name' type="text" id="name" required="" onChange={(e) => setName(e.target.value)} />
                <input className="input_part2_jett" placeholder='Phone' type="text" id="phone" required="" onChange={(e) => setPhone(e.target.value)} />
                <input className="input_part2_jett" placeholder='Email Address' type="text" id="email" required="" onChange={(e) => setEmail(e.target.value)} />
                <textarea className='input_part2_jett' onChange={(e) => setDescription(e.target.value)} placeholder='Tell us how you would like to help...'></textarea>
                <button onClick={(e) => addData3(e)} className='btn_part2_jett mt-2 py-1'>TAKE ACTION</button>
              </div>
              {/* ================================= */}
            </div>

          </center>
        </div>
        {/* ============================Box6 */}

        <div id="HELP" className="podcast_part3 pb-5 mt-4">
          <center>
            <div className="way_to_contribute_box2 col-sm-5">HELP US PROMOTE OUR EFFORTS</div>
            <img src={'/images/way/promote_our_efforts.png'} className="img_way_to_contribute py-4" />

            <div className="way_to_contribute_box1_text1 col-sm-5">
              Feature Junior’s story on your show, podcast, or blog newsletter, and join us in spreading the word to collectively impact more lives in Haiti.
            </div>

            <div className='col-sm-4 mt-3 justify-content-center  shadow-lg px-5 py-4 bg-white'>
              {/* ================================= */}
              <div className='form'>
              {error4 && <div className='error_text'>{error4}</div>}
                <input className="input_part2_jett" placeholder='Name' type="text" id="name" required="" onChange={(e) => setName(e.target.value)} />
                <input className="input_part2_jett" placeholder='Phone' type="text" id="phone" required="" onChange={(e) => setPhone(e.target.value)} />
                <input className="input_part2_jett" placeholder='Email Address' type="text" id="email" required="" onChange={(e) => setEmail(e.target.value)} />
                <textarea className='input_part2_jett' onChange={(e) => setDescription(e.target.value)} placeholder='Tell us how you would like to help...'></textarea>
                <button onClick={(e) =>addData4(e)} className='btn_part2_jett mt-2 py-1'>TAKE ACTION</button>
              </div>
              {/* ================================= */}
            </div>

          </center>
        </div>

        <br /><br /><br /><br />

      </div>
      <Footer />
    </div>
  )
}


export default Ways_to_contribute