import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Buy_lulu = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientId = '17d4cfb8-d73d-4548-a3da-d114c8bab2af';
        const clientSecret = '1pXDv2cbn3grhiIer3PgOdSpIM6U3wxE';
        const basicAuth = 'Basic MTdkNGNmYjgtZDczZC00NTQ4LWEzZGEtZDExNGM4YmFiMmFmOjFwWER2MmNibjNncmhpSWVyM1BnT2RTcElNNlUzd3hF';
        const apiUrl = 'https://api.lulu.com/your/endpoint'; // Replace 'your/endpoint' with the actual endpoint

        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization': basicAuth,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });

        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1>Lulu API Data</h1>
      {data && (
        <pre>{JSON.stringify(data, null, 2)}</pre>
      )}
    </div>
  );
};

export default Buy_lulu;









