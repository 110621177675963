import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import Supporters from "./Supporters";
import React, { useEffect, useRef, useState } from 'react';
function SubmitVideo() {

  // Function to change the image source
  const [imageSrc, setImageSrc] = useState('/images/book/3.png');
  const FrontImageSrc = () => {
    setImageSrc('/images/book/6.png');
  }
  const BackImageSrc = () => {
    setImageSrc('/images/book/3.png');
  }
  
  // ===========CLOUDINARY======================
  const [uploadedUrl, setUploadedUrl] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget({
      cloudName: 'dzpvitjve',
      uploadPreset: 'eeohdbag',
      multiple: false // Enable multiple file uploads
    }, function (error, result) {
      if (!error && result && result.event === "success") {
        console.log('Uploaded image URL: ', result.info.url);
        setUploadedUrl(result.info.url);
        setSuccessMessage('Your file has been successfully added.');
      }
    });
  }, []);
  // =================================

  const [error1, setError1] = useState("");

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");


  async function addData1(e) {
    e.preventDefault();
    setError1("");
    if (name && phone && email && description) {
      console.warn(name, phone, email, description)
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('description', description);
      formData.append('link', uploadedUrl);

      try {
        let result = await fetch("https://juniorpersist.com/public/public/api/addReaction", {
          // let result = await fetch("http://127.0.0.1:8000/api/addReaction", {
          method: 'POST',
          body: formData
        });
        window.location.href = '/Success_message_reaction';
      } catch (error1) {
        console.log(error1);
      }
    } else {
      setError1("Error: all fields required");
    }
  }
  return (
    <div>



      <div className="reaction_header_1 mt-2 py-3">
        SUBMIT A REVIEW AND JOIN THE FUN!
        <div className="reaction_header_down">NEED IDEAS FOR YOUR REVIEW? <a href="#INFO" className="reaction_header_down_in"> CLICK HERE</a></div>

      </div>
      <div className="reaction_bg_img"> </div>
      <center>
        <div className="reaction_box1 col-sm-5">
          <div className="reaction_box1_text1">
            Share your experience reading the book.<br /> Post a video or text review.
          </div>


          {/* ======================= */}
          <div className="podcast_part3 pb-5 mt-3" id="STARTED">
            <div className='justify-content-center  shadow-lg px-5 py-4 bg-white'>
              <div className='form'>
                {error1 && <div className='error_text'>{error1}</div>}
                {uploadedUrl ?
                  <></>
                  :
                  <>
                    <button className='btn_upload_video' onClick={() => widgetRef.current.open()}><i class="bi bi-cloud-arrow-up-fill"></i> Upload Video</button>
                  </>
                }
                {successMessage && (
                  <div>
                    <p className="text_succes_up_video">{successMessage}</p>
                  </div>
                )}

                <input className="input_part2_jett" placeholder='Name' type="text" id="name" required="" onChange={(e) => setName(e.target.value)} />
                <input className="input_part2_jett" placeholder='Phone' type="text" id="phone" required="" onChange={(e) => setPhone(e.target.value)} />
                <input className="input_part2_jett" placeholder='Email Address' type="text" id="email" required="" onChange={(e) => setEmail(e.target.value)} />
                <textarea className='textarea_part2_jett' id="description" onChange={(e) => setDescription(e.target.value)} placeholder="Post your review here."></textarea>
                <button onClick={(e) => addData1(e)} className='btn_part2_jett mt-2 py-1'>SUBMIT</button>
              </div>
            </div>
          </div>
          {/* ======================= */}






        </div>

        <div id="INFO" className="reaction_box3 mt-3">
          <div className="container py-4">
            <div className="reaction_box3_text1 ">IDEAS FOR YOUR VIDEO OR TEXT REVIEW</div>
            <div className="reaction_box3_1 mt-2">
              <ul>
                <li><span className="reaction_box3_1_text2">What did you like most about the memoir?</span></li>
                <li><span className="reaction_box3_1_text2">Which parts of the memoir did you find most emotionally compelling or impactful?</span></li>
                <li><span className="reaction_box3_1_text2">How did reading this memoir influence your perspective on poverty and achieving dreams?</span></li>
                <li><span className="reaction_box3_1_text2">How does this memoir compare to other similar works you've read?</span></li>
                <li><span className="reaction_box3_1_text2">Who would you recommend this memoir to, and why?</span></li>
                <li><span className="reaction_box3_1_text2">What do you think readers will take away from this memoir?</span></li>
                <li><span className="reaction_box3_1_text2">Are there any quotes or passages from the memoir that you found particularly motivational?</span></li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>














      </center>







    </div>
  )
}


export default SubmitVideo;








