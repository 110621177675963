import { Link } from 'react-router-dom'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const images = [
  "./images/building/1.png",
  "./images/building/2.png",
  "./images/building/3.png",
  "./images/building/4.png",
  "./images/building/5.png",
  "./images/building/6.png",
  "./images/building/7.png",
  "./images/building/8.png",

];
const images2 = [
  "./images/school_today/school1.png",
  "./images/school_today/school2.png",
  "./images/school_today/school3.png",
  "./images/school_today/school4.png",
  "./images/school_today/school5.png",
  "./images/school_today/school6.png",
  "./images/school_today/school7.png",
  "./images/school_today/school8.png",
  "./images/school_today/school9.png",
  "./images/school_today/school10.png",
  "./images/school_today/school11.png",
];

function Slider_double() {



  return (
    // ========Start Join
    <div>

<div className="cont_fund_part2">
        <center>
          <div className="container row">

            <div className="col-sm-6 py-2 jett_block_double_slider">
              <div className="fund_div_cont bg-black">
                <div className="fund_header h3">WHERE WE ARE TODAY</div>
                <div className="text2_fund_001 col-sm-10 py-2 text-white opacity-75">
                  Pranzel is currently operating at full capacity with 80 students at the Jeremie public library. Unfortunately, we are currently unable to accept new enrollments due to resource constraints.
                </div>
                  <div className="box">
                    <Carousel useKeyboardArrows={true}>
                      {images2.map((URL, index) => (
                        <div className="slide">
                          <img alt="sample_file" src={URL} key={index} />
                        </div>
                      ))}
                    </Carousel>
                  </div>
              </div>
            </div>
            <div className="col-sm-6 py-2 jett_block_double_slider">
              <div className="fund_div_cont bg_jett_new98">
                <div className="fund_header h3">WHERE WE WANT TO BE</div>
                <div className="text2_fund_001 col-sm-10 py-2 text-white opacity-75">
                  Our innovative building design and approach will enable us to educate 500 students yearly, each dedicated to imparting their knowledge to 10 more youths within the community.                </div>
                  <div className="box">
                    <Carousel useKeyboardArrows={true}>
                      {images.map((URL, index) => (
                        <div className="slide">
                          <img alt="sample_file" src={URL} key={index} />
                        </div>
                      ))}
                    </Carousel>
                  </div>
              </div>
            </div>

          </div>
        </center>
      </div>


    </div>
    // ========End header
  )
}

export default Slider_double