import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Header from "../Header";
import { Footer } from "flowbite-react";
import Menu from "./Menu";
import { NumericFormat } from 'react-number-format';
import { Result } from "postcss";


function Product() {

  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);







  // ====User Login only
  // const navigate = useNavigate();
  // const user = JSON.parse(localStorage.getItem('user-info'));

  // useEffect(() => {
  //   if (!localStorage.getItem('user-info')) {
  //     navigate('/')
  //   }
  // })
  // =============+DELETE==========================
  async function deleteProduct(id) {
    let result = await fetch("https://juniorpersist.com/backend/public/api/deleteProduct/" + id, {
    // let result = await fetch("http://127.0.0.1:8000/api/deleteProduct/" + id, {
      method: "DELETE"
    });
    result = await result.json();
    console.warn(result)
    getData()
  }

  async function getData() {

    let result = await fetch("https://juniorpersist.com/backend/public/api/listProduct");
    // let result = await fetch("http://127.0.0.1:8000/api/listProduct");
    result = await result.json();
    setData(result)

    // const fetchInfo = () => {
    //   return fetch(url)
    //     .then((res) => res.json())
    //     .then((d) => setData(d))
    // }
  }

  return (
    <div>
      <Header />


      <div className="dash_body py-4">
        <div className="page-content container note-has-grid ">
          <Menu />
          <div className="tab-content bg-transparent">
            <div id="note-full-container" className="note-has-grid row">


              <div className="add_button container">
                <Link className="btn_search px-4" to="../admin/searchproduct"><i className="bi bi-search"></i> Search</Link>
                <Link className="btn_add px-4" to="../admin/addproduct"><i className="bi bi-plus-circle"></i> Add New Product</Link>
              </div>


              <div className="container mt-3 mb-5">
                <div className="d-flex justify-content-center row">
                  <div className="col-md-10">

                    {
                      data.map((dataObj) =>
                        <div className="row p-2 bg-white border rounded mb-3">
                          <div className="col-md-2 mt-1"><img className="img-fluid img-responsive rounded product-image" src={"http://127.0.0.1:8000/" + dataObj.file_path} /></div>
                          <div className="col-md-6 mt-1">
                            <h5>{dataObj.name}</h5>

                            <div className="mt-1 mb-1 spec-1">
                              <span>Unique design</span>
                              <span className="dot"></span>
                              <span>For men</span>
                              <span className="dot"></span>
                              <span>Casual<br /></span>
                            </div>
                            <p className="text-justify text-truncate para mb-0">
                              {dataObj.description}
                            </p>
                          </div>
                          <div className="align-items-center align-content-center col-md-3 border-left mt-1">
                            <div className="d-flex flex-row align-items-center">
                              <h4 className="mr-1">$ {dataObj.price}</h4><span className="strike-text">$00.00</span>
                            </div>
                            <div className="d-flex flex-column mt-4">
                              <Link to={"../admin/detailsproduct/" + dataObj.id} className="btn btn-primary btn-sm" >Details</Link>

                              <button onClick={() => { deleteProduct(dataObj.id) }} className="btn btn-outline-primary btn-sm mt-2" >
                                <i className="bi bi-trash"></i>  Delete
                              </button>
                            </div>
                          </div>
                        </div>

                      )}
                  </div>
                </div>
              </div>















            </div>
          </div>


        </div>

      </div>



      <Footer />
    </div>
  )
}


export default Product;


