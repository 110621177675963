import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom';

function Donate() {

  // ==========Input============
  const [val, setVal] = useState("5")
  const change = event => {
    setVal(event.target.value)
  }
  //  =========================


  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Sunflower",
            amount: {
              currency_code: "USD",
              value: val,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
      navigate('../Donate_Success')
    });
  };
  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
    navigate('../Donate')
  };




  return (
    <PayPalScriptProvider
      options={{
        "client-id": "AVRfkzj1lNDb1NLTdQBgf2qLz6jppRDP3AG8lDF31MKm22LTJie4kAT_KC_vQYIgfITBwMD228DqJt1a&disable-funding=paylater,venmo,card",
      }}
    >
      <div id="DONATE">
        <Header />


        <div className="slider_donate" >
          <div className="container">
            <center>
              <div className="col-sm-5 py-4">
                <div className="text1_slider_donate_new">DONATE NOW</div>
                <div className="text2_slider_donate_new mt-3">Your tax-deductible donation of any amount will go directly to fund the Pranzel Elite School’s construction to pave the way for 500+ students.</div>
                <br />
 

                <table>
                  <tr>
                    {/* ===================== */}
                    <td>

                      <input type="number" required step="any" min={500} className="input_somme_jett" placeholder="0.00 USD" onChange={change} value={val} />
                    </td>
                    <td><button type="submit" className="btn1_jett_89 px-4" onClick={() => setShow(true)}>Donate</button></td>
                    {/* ===================== */}
                  </tr>
                </table>

              </div>

              {show ? (<div className="bar_payment mt-5 mb-3"></div>) : null}
              {show ? (
                <div className="h4 py-3 text-white">Payment Method</div>
              ) : null}
              <div className="d-flex justify-content-center">
                {show ? (
                  <PayPalButtons
                    style={{ layout: "vertical" }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                  />
                ) : null}



              </div>
            </center>
          </div>
        </div>

        {/* =========================================== */}






        <div className="container py-5">
          <img className="image_donate_bar2 d-none d-sm-block" src={"/images/book/5.png"} />
          <div className="donate_bar2">
            <div className="cont763658 ">AMPLIFY YOUR IMPACT</div>
          </div>

          <div className="donate_part2">
            <center>
              <div className="col-sm-5 py-3 donate_part2_text1">
                Join the movement. Choose a donation level below.
                As a thank you, we’ll mail you an exclusive pre-final copy of the book IN 24 HOURS, providing you with an inside look at what's coming in June!
              </div>


              {/* ====================== */}


              <div className="part2_text_about_78599 col-sm-8 py-5text-center">
                <div className="row">

                  <div className="col-sm-4 border_bar_donate py-4">
                    <div className="text_2_endors_101">DONATE $250+</div>
                    <div className="text_3_endors_101">Receive 1 pre-released  copy</div>
                    <Link to={'/Donate_payment250'} className="bonate_btn1 px-4" >DONATE</Link>
                  </div>
                  <div className="col-sm-4 border_bar_donate py-4">
                    <div className="text_2_endors_101">DONATE $500+</div>
                    <div className="text_3_endors_101">Receive 3 pre-released copies</div>
                    <Link to={'/Donate_payment500'} className="bonate_btn1 px-4" >DONATE</Link>
                  </div>
                  <div className="col-sm-4 py-4">
                    <div className="text_2_endors_101">DONATE $1000+</div>
                    <div className="text_3_endors_101">Receive 10 pre-released copies</div>
                    <Link to={'/Donate_payment1000'} className="bonate_btn1 px-4" >DONATE</Link>
                  </div>

                </div>
              </div>
            </center>
          </div>
        </div>

        {/* ============================Part 2 */}

        <div className="cont_social_about" id="WHY">
          <div className="col-sm-6 social_about py-3">
            <div className="text1_social_about">WHY DONATE?</div>
          </div>
        </div>


        {/* ==============================Part 3==================== */}


        <div className="py-4 cont_about_part3">
          <div className="about_part3">
            <div className=' px-5 py-4'>
              <center>
                <div className="part2_text_about_78599 py-4  text-center col-sm-9">
                  <div className="row">


                    <div className="col-sm-6 border_img1_part1_2 py-4">
                      <div className="text_2_endors_101 text-white">BOARD OVERSIGHT</div>
                      <div className="text_3_endors_101 text-white">
                        Our board oversees everything, and ALL expenditures must be approved by an independent board.
                      </div>
                    </div>
                    <div className="col-sm-6 py-4">
                      <div className="text_2_endors_101 text-white">EDUCATE ONE YOUTH - EMPOWER TEN</div>
                      <div className="text_3_endors_101 text-white">
                        At Pranzel, paying it forward is a requirement. Every student must share their knowledge with 10 others in the community.
                      </div>
                    </div>

                  </div>
                </div>
 
              </center>
            </div>
            <img src={'images/img/23ed.png'} className="img_fund_5986" />
          </div>
        </div>

        <div className="text-center">
          <div className="donate_bottom_text ">MAKE YOUR CONTRIBUTION NOW</div>
          <div className="mt-4">
            <a href="#DONATE" className="bonate_btn1 px-4 " >DONATE</a>
          </div>


        </div>







        <br />  <br />  <br />
        <Footer />
      </div>
    </PayPalScriptProvider>
  )
}


export default Donate