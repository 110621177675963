import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import React from 'react';
import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';


import { useNavigate } from 'react-router-dom';
import { useState } from "react";



function EliteAccess() {


  return (
    <div>
      <Header />

      <div className="podcast_all">
        <div className="podcast_on_fire ">

          <div className="row py-5">


            {/* ============ */}
            <center>
              <div className="col-sm-6 py-2">
                <div className="text_slider">
                  <div className="podcast_box2">
                    <div className="new_about_part1">
                      <div className="new_about_text1">WATCH THE LATEST</div>
                    </div>
                    <div className="new_about_part2 mt-1">
                      <div className="mt-2 py-3">
                        <img src={'/images/podcast/on_fire/3.png'} className="w-75" />
                        <div className="text1_podcast_recent h4  mt-4 text-white ">MY BROTHER’S REACTION TO THE BOOK!</div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </center>


          </div>

          {/* ================ */}
          <div className="new_about_bar_menu ">
            <center>
              <div className="col-sm-4 ">
                <div className="row">
                  <div className="col-sm-6 py-3">
                    <a href={'/BuyDetails'} className="btn_Video_serie1">PRE-ORDER NOW</a>
                  </div>
                  <div className="col-sm-6 py-3">
                    <a href="https://ayisyenkapab.com/persist-collection/" target="_blank" className="btn_Video_serie2">SHOP OUR STORE</a>
                  </div>
                </div>
              </div>
            </center>
          </div>



        </div>


        {/* ==============================Part 1_2==================== */}
        <div id="RECENT" className="py-4 bg-black">
          <div className="part2_text_about_78588 mb-2 text-white text-center">RECENT RELEASES</div>


          <div className="container">
            <div className="jett_scrollmenu">


              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <img src={'/images/podcast/on_fire/1.png'} className="img_podcast_recent" />
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">The Power of Bringing People Together </div>
                    <div className="text2_podcast_recent">Founder of Bring the Change</div>
                  </div>
                  <br />
                </div>
              </div>


              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <img src={'/images/podcast/on_fire/2.png'} className="img_podcast_recent" />
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">Here's Why Your Dream Is Worth the Fight</div>
                    <div className="text2_podcast_recent">A Couple’s Business Journey</div>
                  </div>
                  <br />
                </div>
              </div>

              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <img src={'/images/podcast/on_fire/3.png'} className="img_podcast_recent" />
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">Utilizing Profits to Positively Impact Lives</div>
                    <div className="text2_podcast_recent">The Brothers’ Journey</div>
                  </div>
                  <br />
                </div>
              </div>

              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <img src={'/images/podcast/on_fire/4.png'} className="img_podcast_recent" />
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">From the Dominican Republic to the United States</div>
                    <div className="text2_podcast_recent">The Story of Carlos Canela</div>
                  </div>
                  <br />
                </div>
              </div>


            </div>
          </div>
        </div>

        {/* ==============================Part 1_2==================== */}
        <div className="bar_separation1_podcast"></div>
        {/* ==============================Part 1_2==================== */}

        <a href='https://ayisyenkapab.com/persist-collection/' target='_blank'>
        <img className='w-100' src='/images/new/h_shop.png' />
      </a>

        {/* ============================Part 2 */}



        <br /><br /><br /><br />


      </div>
      <Footer />
    </div>
  )
}


export default EliteAccess