import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import React from 'react';
import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';


import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import Subscribe_layout from "../Subscribe_layout";



function Subscribe() {


  return (
    <div>
      <Header />
      <Subscribe_layout />
      <Footer />
    </div>
  )
}


export default Subscribe