import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function Withdraw_success() {


  return (
    <div>
      <Header />





      <div className="book">
        <center>

          <section className="mail-seccess section py-3">
            <div className="container ">

              <center>
                <div className="col-lg-6 ">

                  <div className="success-inner">
                    <h1><i className="fa fa-check"></i><span>Your withdrawal request has been submitted successfully! </span></h1>
                    <p className="jett87345_1">
                    We will notify you once your funds are transferred to your account. <br />
                    For any questions, please contact us at ambassador@juniorpersist.com.
                    </p>

                   
                  </div>

                </div>
              </center>

            </div>
            <br /><br /><br />
          </section>


        </center>
      </div>













      <br />  <br />  <br />
      <Footer />
    </div>
  )
}


export default Withdraw_success