import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import React from 'react';


import { Dropdown } from 'flowbite-react';
import { HiCog, HiCurrencyDollar, HiLogout, HiViewGrid } from 'react-icons/hi';
import Countdown from './Countdown';

function Header() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user-info'));
  console.warn(user);
  function logout() {
    localStorage.clear();
    navigate('/');
  }


  var levelButton;
  if (user && user.level == 'Admin') {
    levelButton = <li><Link className="dropdown-item" to="../admin/dash"><i className="bi bi-person-fill-gear"></i> Admin</Link></li>;
  }


  // =======TIME TO END===
  const offerEndDate = "2024-10-01T00:00:00";




  return (
    // ========Start header
    <div>
      {/* header part 1 */}
      <div className='d-none d-md-block'>
        <div className='row py-2 px-5'>
          <div className='col-sm-4'>
            <a href='https://www.facebook.com/juniorpersist' target='_blank' className='social_icon'><i className='fa fa-facebook'></i></a>
            <a href='https://twitter.com/Juniorpersist' target='_blank' className='social_icon'><i className='fa fa-twitter'></i></a>
            <a href='https://www.instagram.com/jrpersist' target='_blank' className='social_icon'><i className='fa fa-instagram'></i></a>
            <a href='https://www.linkedin.com/in/junior-bernard-40081a304' target='_blank' className='social_icon'><i className='fa fa-linkedin'></i></a>
            <a href='https://www.youtube.com/@juniorpersist' target='_blank' className='social_icon'><i className='fa fa-youtube'></i></a>
            <a href='https://www.tiktok.com/@juniorpersist' target='_blank' className='social_icon'><i className="bi bi-tiktok"></i></a>
          </div>
          <div className='col-sm-3'></div>
          <div className='col-sm-5 '>
            <div className='header1_jett  float-end'>
              {/* <Link to='/influencer'  >
                <span className='text_header1'>
                   BECOME AN INFLUENCER
                </span>
              </Link> */}
              <a href='https://lp.constantcontactpages.com/sl/mt3B9Qg/persistbook' target='_blank'>
                <span className='text_header1'>
                  <i className='fa fa-sign-in'></i> NEWSLETTER SIGNUP
                </span>
              </a>
              <a href='' target='_blank'>
                <span className='text_header1'>
                  <Link className='no_affect_link' to={'/Contact'}>
                    CONTACT JUNIOR
                  </Link>
                </span>
              </a>

              {
                localStorage.getItem('user-info') ?
                  <>

                    <span className="dropdown ">
                      <span className="text_header11 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img className='avatar_jett' src={"/images/icons/avatar.png"} />    {user.firstname}
                      </span>
                      <ul className="dropdown-menu">
                        <li>
                          <div className='px-2 mt-2'>
                            <h6 className='text_user1'>{user && user.firstname + ' ' + user.lastname}</h6>
                            <h6 className='text_user2'>{user && user.email}</h6>

                          </div>
                        </li>
                        <hr />
                        <li><Link className="dropdown-item" to={'/profil'}><i className="bi bi-person"></i> Profil</Link></li>
                        <li><a className="dropdown-item" href="#"><i className="bi bi-bag"></i> Purchases</a></li>
                        {user && levelButton}
                        <li><a className="dropdown-item" role="button" onClick={logout} href="#"><i className="bi bi-box-arrow-right"></i> Logout</a></li>
                      </ul>
                    </span>

                  </>
                  :
                  <>
                    <span className='text_header1'>
                      {/* <a className='no_affect_link' href='http://127.0.0.1:8000/home'> */}
                      <a className='no_affect_link' href='https://juniorpersist.com/public/public/home'>
                        LOGIN
                      </a>
                    </span>

                    {/* <span className='text_header1'>
                      <a className='no_affect_link' href='http://127.0.0.1:8000/register'>
                        REGISTER
                      </a>
                    </span> */}
                  </>
              }



            </div>
          </div>
        </div>

      </div>



      {/* header part 2 */}
      <nav className="navbar navbar-expand-lg bg-body-tertiary ">

        <div className="container-fluid px-3">
          <table>
            <tbody>
              <tr>
                <td>
                  <div className='logo_main'>
                    <Link className="navbar-brand" to="/">
                      <img className='logo_jett' src={"/images/icons/junior_persist.png"} />
                    </Link>
                  </div>
                </td>
                <td>
                  <button className="navbar-toggler float-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>


          <div className="collapse navbar-collapse " id="navbarSupportedContent">

            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link menu_text" to="/">HOME</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link menu_text" to="/Speaking">SPEAKING</Link>
              </li>
              <li className="dropdown dropdown_jett">
                <a className="nav-link    dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  BUY IN BULK
                </a>
                <ul className="dropdown-menu">
                  <Link className="dropdown-item menu_text" to="/InspireSchools">Schools</Link>
                  <Link className="dropdown-item menu_text" to="/InspireCompanies">Companies</Link>
                  <Link className="dropdown-item menu_text" to="/InspireChurches">Churches</Link>
                  <Link className="dropdown-item menu_text" to="/InspireBook_clubs">Book Clubs</Link>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link menu_text" to="/Video">REVIEWS</Link>
              </li>
              <li className="nav-item">
                <a className="nav-link menu_text" href="https://ayisyenkapab.com/persist-collection/" target='_blank'>SHOP</a>
              </li>



              <li className="d-block d-sm-none  nav-item">
                <a className="nav-link menu_text" href='https://juniorpersist.com/public/public/login' >
                  LOGIN
                </a>
              </li>

              <li className="dropdown dropdown_jett">
                <a className="nav-link    dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  MORE
                </a>
                <ul className="dropdown-menu">
                  <Link className="dropdown-item menu_text" to="/Reaction">Submit a Review</Link>
                  <li><a className="dropdown-item menu_text" href="https://persistblog.com">Blog</a></li>
                  <li><Link className="dropdown-item menu_text" to="/Video">Fan Reactions</Link></li>
                  <li><a className="dropdown-item menu_text" href="https://juniorpersist.com/public/public/home">Promotion</a></li>
                  <li><Link className="dropdown-item menu_text" to="/Press">Press Kit</Link></li>
                  <Link className="dropdown-item menu_text" to="/Contact">Contact</Link>
                  <Link className="dropdown-item menu_text" to="/About">About</Link>
                  <li><Link className="dropdown-item menu_text" to="/GeneralTerms">Terms of Use</Link></li>
                  <li><Link className="dropdown-item menu_text" to={'/SpreadTheWord'}>Engage<i class="bi bi-arrow-up-right-circle-fill engage_icon px-1"></i></Link></li>
                  <li><Link className="dropdown-item menu_text" to={'/ClaimSpecial'}>Special Offer</Link></li>
                  {/* <li><Link className="dropdown-item menu_text" to="/SpreadTheWord">Spread the Word</Link></li> */}
                </ul>
              </li>


            </ul>


            <div className="d-none d-sm-block d-flex menu-section">
              <a className="vip_access_btn  menu_text px-4" href="https://juniorpersist.com/public/public/GoBook">VIP BOOK ACCESS</a>
              <Link to={'/Millioncopyjourney'} className="video_serie px-3">
                VIDEO SERIES
              </Link>
              <a className="btn_donate_jett px-4" href="/BuyDetails">PRE-ORDER NOW</a>
            </div>


            {/* ==========Mobile */}
            <div className="d-block d-sm-none bnj98">
              <a href="https://juniorpersist.com/public/public/GoBook" className="btn_login_jett px-3">
                VIP BOOK ACCESS
              </a> <br /><br />


              <Link to={'/Millioncopyjourney'} className="btn_login_jett px-5">
                VIDEO SERIES
              </Link> <br /><br />
              <a className="btn_donate_jett px-5" href="/BuyDetails" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PRE-ORDER NOW&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
            </div>
            <br />

          </div>
        </div>
      </nav>

      {/* ===========Header notification======== */}
      {/* <div className='header_time d-block d-sm-none'>

        <div className='col-sm-7 m-auto'>
          <div className='row g-0'>
            <div className='col-sm-2 align-self-center d-none d-sm-block'><img className='img_time' src={'/images/icons/clock2.png'} /></div>
            <div className='col-sm-2 align-self-center d-block d-sm-none'><img className='img_time' src={'/images/icons/clock2.png'} /></div>

            <div className='col-sm-6 align-self-center'>
              <div className=' '>
                <div className='time_text1'> BUY JUNIOR’S BOOK IN BULK</div>
                <div className='time_text2'>Learn About Bulk Order Rates</div>
                <div className='time_text3'> <Countdown targetDate={offerEndDate} /></div>
              </div>
            </div>

            <div className='col-sm-4 align-self-center mb-3 mt-4 '>
              <div className='border8759 '>
                <div className='time_text2'>Available for</div>
                <a className='a_header09 time_text2' href={'/InspireSchools'}>Schools</a>, <a className='a_header09 time_text2' href={'/InspireCompanies'}>Companies</a>, <br />
                <a className='a_header09 time_text2' href={'/InspireChurches'}> Churches</a>, <a className='a_header09 time_text2' href={'/InspireBook_clubs'}>Book Clubs, and More.</a>
              </div>
            </div>





          </div>
        </div>
      </div> */}
      {/* ========== */}

    </div>

    // ========End header
  )
}

export default Header