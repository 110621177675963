import React from 'react';
import { useLocation } from 'react-router-dom';
export default function PostDetailsScreen() {
    const { state } = useLocation();
    return (
        <>
            <div>{state.post_id}</div>
            <div>{state.post_name}</div>
            <div>{state.post_description}</div>
        </>
    )
}