import React, { useState, useEffect, Component } from "react";

import { Link, useParams } from "react-router-dom";
import Header from "../Header";
import { Footer } from "flowbite-react";
import Menu from "./Menu";
import { withRouter } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import axios from "axios";


function DetailsProduct() {
  const navigate = useNavigate();
  // Get ID from URL
  const params = useParams();
  const [product, setProduct] = useState([])

  const [name, setName] = useState("");
const [file, setFile] = useState("");
const [price, setPrice] = useState("");
const [description, setDescription] = useState("");


  useEffect(() => {
    axios.get('https://juniorpersist.com/backend/public/api/product/${params.id}')
    // axios.get(`http://127.0.0.1:8000/api/product/${params.id}`)
      .then(res => {
        console.log(res)
        setProduct(res.data)
         setName(product.name)
  setPrice(product.price)
  setFile(product.file)
  setDescription(product.description)
      })
      .catch(err => {
     
        console.log(err)
      })
  }, [params.id])

  

  
  async function editProduct(id){
  const formData = new FormData();

  

  formData.append('name', name);
  formData.append('price', price);
  formData.append('file', file);
  formData.append('description', description);
  let result = await fetch("http://127.0.0.1:8000/api/updateProduct/"+id+"?_method=PUT",{
    method: 'POST',
    body: formData
  });
  alert("Product has been updated ")
}

  return (
    <div>
      <Header />


      <div className="dash_body py-4">
        <div className="page-content container note-has-grid ">
          <Menu />
          <div className="tab-content bg-transparent">
            <div id="note-full-container" className="note-has-grid row">


              <h1 className="text-center">UPDATE {product.name}</h1>



              <div className="container padding-bottom-3x mb-2">
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-10">

                    <div className="card mt-4">
                      <div className="card-body">

                        <div className="row py-2">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label for="name">Name</label>
                                <input className="form-control my-2" type="text" id="name" required="" 
                                onChange={(e)=>setName(e.target.value)}
                                defaultValue={product.name}
                                />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label for="price">Price</label>
                              <input className="form-control  my-2" type="text" id="price" required="" 
                              onChange={(e)=>setPrice(e.target.value)}
                              defaultValue={product.price}
                               />
                            </div>
                          </div>
                        </div>

                        <div className="row py-2">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label for="image">Image</label>
                              <div className="row">
                                <div className="col-sm-6">
                                  <input className="form-control  my-2" type="file" id="image" required="" 
                                  onChange={(e)=>setFile(e.target.files[0])}
                                  defaultValue={product.file_path}
                                   />
                                </div>
                                <div className="col-sm-6">
                                  <img className="img-fluid img-responsive rounded product-image" src={"http://127.0.0.1:8000/" + product.file_path} />
                                </div>
                              </div>
                              <center>
                              </center>
                            </div>
                          </div>
                        </div>

                        <div className="row py-2">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label for="description">Description</label>
                              <textarea className="form-control  my-2" id="description" required=""
                               defaultValue={product.description}
                               onChange={(e)=>setDescription(e.target.value)}></textarea>
                            </div>
                          </div>
                        </div>

                        <div className="card-footer">
                        <button className="btn_add px-3" onClick={()=>editProduct(product.id)}>Edit Product</button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>










            </div>
          </div>


        </div>

      </div>



      <Footer />
    </div>
  )
}

export default DetailsProduct




