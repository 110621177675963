import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import '../css/read.css';

import { useState } from "react";

function Ebook() {

  return (
    <div>
      <Header />









      {/* ==================================================== */}
      <div className="lee_book">
        <center>
          <object data="/pdf/Ebook.pdf#view=Fit&toolbar=0" type="application/pdf" className="pdf_ebook py-2">
            <p>It appears you don't have Adobe Reader or PDF support in this web browser. <a rel="external" href="pdf/sample.pdf">Click here to download the PDF</a></p>
          </object>
        </center>
      </div>




      {/* ==================================================== */}








      <Footer />
    </div>
  )
}


export default Ebook