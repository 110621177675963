import Header from "../Header";
import Footer from "../Footer";
import Board_members from "../Board_members";
import Join from "../Join";
import { Link } from "react-router-dom";

import React from 'react';
import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';


function ProjetDetails() {


  return (
    <div>
      <Header />



      <div className="speaking_slider_all d-flex justify-content-center py-3 px-4">
        <div className="col-sm-11 py-5">
          <div className="row">
            <div className="col-sm-6 align-self-center py-4 jett_block_double_slider">
                <div className="text1_speaking_slider">TRANSPARENCY</div>
                <div className="text2_1_speaking_slider ">EXPLORE OUR CONSTRUCTION PLANS & DETAILS</div>
                <div className="text3_speaking_slider col-sm-9 mt-2">The work we're doing isn't just a dream. It's happening!<br /> Please proceed below to see the details.</div>
                <br />
                <div className="">
                  <Link className="btn_home_jett_7864_3 px-5" to="#GETSTARTED">GET STARTED</Link>
                </div>
            </div>
            <div className="col-sm-6 jett_block_double_slider">

              <div className="">
                <MDBCarousel showControls fade touch={false}>
                  <MDBCarouselItem itemId={1}>
                    <img src='./images/slider_projet_details/1.png' className='d-block w-100' alt='...' />
                  </MDBCarouselItem>
                  <MDBCarouselItem itemId={2}>
                    <img src='./images/slider_projet_details/2.png' className='d-block w-100' alt='...' />
                  </MDBCarouselItem>
                  <MDBCarouselItem itemId={3}>
                    <img src='./images/slider_projet_details/3.png' className='d-block w-100' alt='...' />
                  </MDBCarouselItem>
                  <MDBCarouselItem itemId={4}>
                    <img src='./images/slider_projet_details/4.png' className='d-block w-100' alt='...' />
                  </MDBCarouselItem>
                </MDBCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ============================== */}
      <center>


        <div className="fund_cont2 col-sm-9 py-5 px-3 jett_block_double_slider">

          <div className="row">

            <div className="col-sm-6 fund_cont2_part1 py-3">
              <div className="fund_cont2_text1">OUR CURRENT NEED</div>
              <div className="fund_cont2_text2 mb-2">
                The listed materials are crucial for our building construction. We will continually update the list as more needs arise. Subscribe below for ongoing updates.
              </div>
              <a href="https://lp.constantcontactpages.com/sl/RNCHQUJ/pranzelblog" target="_blank" className="btn_home_jett_7864_2 py-1 px-4">SIGN UP NOW</a>
            </div>

            <div className="col-sm-6 py-3">
              <div className="">
                <div className="fund_cont2_text1">BOARD OVERSIGHT</div>
                <div className="fund_cont2_text2 mb-2">
                  Our aim is to be the most credible institution in Haiti, which is why transparency is our top priority. All expenditures must be approved by an independent board.
                </div>
                <a href="#BOARD" className="btn_home_jett_7864 py-1 px-4">MEET OUR VALUED BOARD MEMBERS</a>
              </div>
            </div>

          </div>
        </div>

      </center>





      <div className="bg-black text-center text-white h4 py-3">CONSTRUCTION SUPPLIES NEEDED NOW </div>



      <div className='container'>
        <div className='row'>



          <div className='col-sm-3 py-3'>
            <div className='part3_video_box shadow'>
              <img src={'/images/construction/mat1.png'} className="img_trans_001" />
              <div className='px-4 py-2'>
                <span>Cement/Concrete</span><br />
              </div>
            </div>
          </div>

          <div className='col-sm-3 py-3'>
            <div className='part3_video_box shadow'>
              <img src={'/images/construction/mat2.png'} className="img_trans_001" />
              <div className='px-4 py-2'>
                <span>Classroom Chairs</span><br />
              </div>
            </div>
          </div>

          <div className='col-sm-3 py-3'>
            <div className='part3_video_box shadow'>
              <img src={'/images/construction/mat3.png'} className="img_trans_001" />
              <div className='px-4 py-2'>
                <span>Concrete Blocks</span><br />
              </div>
            </div>
          </div>

          <div className='col-sm-3 py-3'>
            <div className='part3_video_box shadow'>
              <img src={'/images/construction/mat4.png'} className="img_trans_001" />
              <div className='px-4 py-2'>
                <span>Lumber</span><br />
              </div>
            </div>
          </div>

          <div className='col-sm-3 py-3'>
            <div className='part3_video_box shadow'>
              <img src={'/images/construction/mat5.png'} className="img_trans_001" />
              <div className='px-4 py-2'>
                <span>Construction Equipment</span><br />
              </div>
            </div>
          </div>

          <div className='col-sm-3 py-3'>
            <div className='part3_video_box shadow'>
              <img src={'/images/construction/mat6.png'} className="img_trans_001" />
              <div className='px-4 py-2'>
                <span>Land</span><br />
              </div>
            </div>
          </div>



          <div className='col-sm-3 py-3'>
            <div className='part3_video_box shadow'>
              <img src={'/images/construction/mat7.png'} className="img_trans_001" />
              <div className='px-4 py-2'>
                <span>Laptop/Desktop</span><br />
              </div>
            </div>
          </div>

          <div className='col-sm-3 py-3'>
            <div className='part3_video_box shadow'>
              <img src={'/images/construction/mat8.png'} className="img_trans_001" />
              <div className='px-4 py-2'>
                <span>Rebar</span><br />
              </div>
            </div>
          </div>
          <div className='col-sm-3 py-3'>
            <div className='part3_video_box shadow'>
              <img src={'/images/construction/mat9.png'} className="img_trans_001" />
              <div className='px-4 py-2'>
                <span>Sheet Metal</span><br />
              </div>
            </div>
          </div>

          <div className='col-sm-3 py-3'>
            <div className='part3_video_box shadow'>
              <img src={'/images/construction/mat10.png'} className="img_trans_001" />
              <div className='px-4 py-2'>
                <span>Solar Panels</span><br />
              </div>
            </div>
          </div>

          <div className='col-sm-3 py-3'>
            <div className='part3_video_box shadow'>
              <img src={'/images/construction/mat11.png'} className="img_trans_001" />
              <div className='px-4 py-2'>
                <span>Shipping Container</span><br />
              </div>
            </div>
          </div>

          <div className='col-sm-3 py-3'>
            <div className='part3_video_box shadow'>
              <img src={'/images/construction/mat12.png'} className="img_trans_001" />
              <div className='px-4 py-2'>
                <span>Vehicles</span><br />
              </div>
            </div>
          </div>

        </div>
      </div>



      {/* ======================================== */}


      <div className="d-flex justify-content-center ">
        <div className=" col-sm-5">
          <div className="py-3">
            <h3 className="text-center text-black">GET STARTED</h3>
            <p className="text-center fund_text1_986554">
              <a href={'/Donate'} className="text-black">Click here to donate funds now </a>
              or fill out the form below to donate supplies now
            </p>
          </div>

          <div className="py-3 px-3  cont_fund_3" >
            <h5 className="text-left fw-bold text-black">Field out the form below.</h5>
            <hr />

            <div className="row">
              <div className="col-sm-6 py-2">
                <input type="text" name="lastname" required className="input_donner_iterm_jett" placeholder="Your last name" />
              </div>
              <div className="col-sm-6 py-2">
                <input type="text" name="firstname" required className="input_donner_iterm_jett" placeholder="Your first name" />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 py-2">
                <input type="email" name="email" required className="input_donner_iterm_jett" placeholder="Your email" />
              </div>
              <div className="col-sm-6 py-2">
                <input type="text" name="phone" required className="input_donner_iterm_jett" placeholder="Your phone number" />
              </div>
            </div>
            <hr />
            <div className="row ">
              <div className="col-sm-6 py-2">
                <label for="">Quantity</label>
                <input type="number" min="1" value="1" name="quantity" required className="input_donner_iterm_jett" />
              </div>
              <div className="col-sm-6 py-2">
                <label for="">Item shipping date</label>
                <input type="date" name="expedition_date" required className="input_donner_iterm_jett" />
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="col-sm-12 py-2">
                <input type="text" name="object_name" required className="input_donner_iterm_jett" placeholder="Object name" />
              </div>
              <div className="col-sm-12 py-2">
                <div className=" py-1" >Item image  <span >(optional)</span></div>
                <input type="file" className="form-control" name="image" accept="image/*" onchange="loadFile(event)" />
              </div>
            </div>

            <center>
              <button type="submit" className="btn_donner_iterm_jett px-4">Send</button>
            </center>

          </div>

        </div>
      </div>





      {/* ================================= */}
      <br />
      <div id="BOARD">
        <Board_members />
      </div>

      {/* ================================= */}
      <Join />



      {/* ====================== */}
      <div className="pkl_908 mb-5 ">
        <div className=" d-flex justify-content-center">
          <div className="col-sm-5  book_down2">BECOME PART OF JUNIOR’S JOURNEY</div>
        </div>
        <div className="black_bar_book_down"></div>
        <center>
          <div className="book_down_text2  marg_top_jett_8347">Join Junior in building the Pranzel Elite School facility for 500 students.</div>
          <div className="py-3">
            <a href="/Donate" className="btn_home_jett_7864_2 px-3 py-1">DONATE TODAY</a>
          </div>
        </center>
      </div>
      <br />
      {/* ====================== */}





      <Footer />
    </div>
  )
}


export default ProjetDetails