import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function Success_message_reaction() {
  return (
    <div>
      <Header />

      <div className="book">
        <center>


          <section className="mail-seccess section py-3">
            <div className="container ">

              <center>
                <div className="col-lg-6 ">

                  <div className="success-inner">
                    <h1><i className="fa fa-check"></i><span>Your reaction has been sent successfully!</span></h1>
                    <p className="jett87345_1">
                      Thank you for submitting this form. A member of our team will be in touch with you shortly! Until then, stay awesome!
                    </p>
                  </div>

                </div>
              </center>

            </div>
            <br /><br /><br />
          </section>


        </center>
      </div>


      <div className="">
        <div className="buy_por984774_text1 mt-3">SHOP THE PERSIST COLLECTION</div>
        <div className="buy_por984774_text2">LET'S SPREAD A POSITIVE VIBE TOGETHER!</div>
        <div className="mt-4">
          <center>
            <a className="buy_box_btn px-5" target="_blank" href="https://ayisyenkapab.com/persist-collection/">SHOP NOW</a>
          </center>
          <img className="w-100 mt-3" src={'/images/new/pub_img1.png'} />
        </div>
      </div>
      <Footer />
    </div>
  )
}


export default Success_message_reaction