import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Header from "../Header";
import { Footer } from "flowbite-react";
import Menu from "./Menu";

function Dash() {

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user-info'));



  useEffect(() => {
    if (!localStorage.getItem('user-info')) {
      navigate('/')
    }
  })


  return (
    <div>
      <Header />

     
      <div className="dash_body py-4">
        <div className="page-content container note-has-grid ">
          <Menu />
          <div className="tab-content bg-transparent">
            <div id="note-full-container" className="note-has-grid row">


              Body
            </div>
          </div>

        
        </div>

      </div>



      <Footer />
    </div>
  )
}


export default Dash