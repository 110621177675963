import { Link } from 'react-router-dom'

function Board_members() {



  return (
    // ========Start Join
    <div>

<div className="d-flex justify-content-center mt-4">
        <div className="col-sm-7 text-center">
          <div className=" bg-black  text-white h2 py-2  ">MEET OUR BOARD</div>
          <div className="board_menber_text3 py-4 px-2">
            Our board consists of qualified, volunteered U.S. business leaders with a passion for enhancing lives in Haiti
            through quality, competitive education, and opportunities. They oversee our operations, provide guidance, and no
            funds or donations can be released or allocated to projects unless approved and monitored by our board.
          </div>
        </div>
      </div>
      <div className="board_menber">
        <center>
          <div className="container">
            <div className="row py-5">

              <div className="col-sm-4 mb-4">
                <div className="shadow rounded px-3 py-4 bg-white">
                  <img src={'/images/board_members/art.png'} className="img_board_menber"></img>
                  <div className="board_menber_text1">Art Vallely</div>
                  <div className="board_menber_text2">President of Penske Truck Leasing Harvard Business School Executive Education</div>
                  <div className="board_menber_text3">Art Vallely is the president at Penske Truck Leasing, a worldwide company with more than 40,000 employees and a fleet of more than 400,000 vehicles. Mr. Vallely obtained his B.A. degree from College of St. Joseph and his organizational leadership education from Harvard Business School Executive Education.<br /><br /></div>
                </div>
              </div>

              <div className="col-sm-4 mb-4">
                <div className="shadow rounded px-3 py-4 bg-white">
                  <img src={'/images/board_members/tiffani.png'} className="img_board_menber"></img>
                  <div className="board_menber_text1">Tiffani Sims</div>
                  <div className="board_menber_text2">Chief Visionary Officer at Diversity Talent Scouts Baker College University</div>
                  <div className="board_menber_text3">Tiffani Sims is the owner of Diversity Talent Scouts, a boutique Search Firm that specializes in placing Diverse Professionals into Leadership roles across numerous industries. She has a bachelor’s Degree in Human Resource Management, is Greenbelt certified, and a Certified Diversity Recruiter. She obtained her education from Baker College in Muskegon, Michigan.</div>
                </div>
              </div>
              <div className="col-sm-4 mb-4">
                <div className="shadow rounded px-3 py-4 bg-white">
                  <img src={'/images/board_members/tony.png'} className="img_board_menber"></img>
                  <div className="board_menber_text1">Tony Arnett</div>
                  <div className="board_menber_text2">International Missions Pastor at Central Church of God</div>
                  <div className="board_menber_text3">Pastor Tony is the missions pastor at Central Church in Charlotte, N.C. Central Church takes the great commission literally in “going into the entire world preaching the gospel,” partnering with missionaries and organizations by providing financial assistance and prayer support. Pastor Tony is at the heart of these missions. His counsel and impact at Pranzel is invaluable.<br /><br /></div>
                </div>
              </div>

              
              <div className="col-sm-4 mb-4">
                <div className="shadow rounded px-3 py-4 bg-white">
                  <img src={'/images/board_members/billy.png'} className="img_board_menber"></img>
                  <div className="board_menber_text1">Billy Barr</div>
                  <div className="board_menber_text2">Founding Partner at Acuitas Analytics Brown University & NYU</div>
                  <div className="board_menber_text3">Billy Barr is a founding partner at Acuitas Analytics, a tech company that builds reporting systems to assist pharmaceutical and biotech manufacturers in quantifying and capitalizing on the influence of reimbursement on product demand. Mr. Barr received his B.A. degree from Brown University and his master of business administration from NYU.<br /></div>
                </div>
              </div>
              <div className="col-sm-4 mb-4">
                <div className="shadow rounded px-3 py-4 bg-white">
                  <img src={'/images/board_members/alex.png'} className="img_board_menber"></img>
                  <div className="board_menber_text1">Aleksandr Migdalovich</div>
                  <div className="board_menber_text2">Founder of Migdalovich Consulting Oakton College</div>
                  <div className="board_menber_text3">Aleksandr Migdalovich is the founder of Migdalovich consulting. He has over eight years of experience in the marketing industry, and his business has helped and guided countless clients in launching their eCommerce Channels, including Amazon, eBay, and Walmart. Migdalovich obtained his education at College of Lake County.<br /><br /></div>
                </div>
              </div>
              <div className="col-sm-4 mb-4">
                <div className="shadow rounded px-3 py-4 bg-white">
                  <img src={'/images/board_members/junior.png'} className="img_board_menber"></img>
                  <div className="board_menber_text1">Junior Bernard</div>
                  <div className="board_menber_text2">Co-founder at Pranzel Education Alvernia University</div>
                  <div className="board_menber_text3">Junior Bernard (born and raised in Haiti) is a co-founder and Lead executive at Pranzel Education. When Junior noticed thousands of kids across Haiti dropping out of school due to violence and natural disasters, he quit his job to focus on building the Pranzel Education platform. Junior obtained his education at Alvernia University in Pennsylvania.<br /><br /></div>
                </div>
              </div>



            </div>
          </div>
        </center>
      </div>

    </div>
    // ========End header
  )
}

export default Board_members