import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import React from 'react';
import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';


import { useNavigate } from 'react-router-dom';
import { useState } from "react";



function Millioncopyjourney() {


  return (
    <div>
      <Header />

      <div className="bg-black">
        <div className="container py-4">
          <center>
            <div className="journey_text1">WELCOME TO</div>
            <div className="text-white h3">THE 1-MILLION COPY JOURNEY</div>
            <div className="journey_text1">WEEKLY VIDEO SERIES</div>
          </center>
        </div>
      </div>

      <div className="journey_box2">
        <div className="container pt-3 pb-1">
          <p className="text-center opacity-75">Insights, Inspiration, and a Behind-the-Scenes Look at Our Journey to Impact a Million Lives </p>
        </div>
      </div>

      <div className="podcast_all">
        <div className="podcast_on_fire ">

          <div className="row py-5">


            {/* ============ */}
            <center>
              <div className="col-sm-6 py-2">
                <div className="text_slider">
                  <div className="podcast_box22">
                    <div className="new_about_part3">
                      <div className="new_about_text1">WATCH THE LATEST</div>
                    </div>
                    <div className="new_about_part2 mt-1">
                      <div className="space87540">
                        <iframe className='iframe_part4' src="https://player.vimeo.com/video/956163210" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        <div className="text1_podcast_recent h4   text-white space875478">Book Review from University Professor and Author</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </center>


          </div>

          {/* ================ */}
          <div className="new_about_bar_menu ">
            <center>
              <div className="col-sm-4 ">
                <div className="row">
                  <div className="col-sm-6 py-3">
                    <a href={'/BuyDetails'} className="btn_Video_serie1">PRE-ORDER NOW</a>
                  </div>
                  <div className="col-sm-6 py-3">
                    <a href="https://ayisyenkapab.com/persist-collection/" target="_blank" className="btn_Video_serie2">SHOP OUR STORE</a>
                  </div>
                </div>
              </div>
            </center>
          </div>



        </div>


        {/* ==============================Part 1_2==================== */}
        <div id="RECENT" className="py-4 bg-black">
          <div className="part2_text_about_78588 mb-2 text-white text-center mb-3">RECENT RELEASES</div>


          <div className="container">
            <div className="jett_scrollmenu">


              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <iframe className='iframe_part3 img_podcast_recent' src="https://player.vimeo.com/video/983334108" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">"You Are the Reason the Book Is Finally Done."</div>
                    <div className="text2_podcast_recent">A special chat with my grandmother</div>
                  </div>
                  <br />
                </div>
              </div>


              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <iframe className='iframe_part3 img_podcast_recent' src="https://player.vimeo.com/video/982922665" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">"I Didn't Want to Put the Book Down. It`s amazing!"</div>
                    <div className="text2_podcast_recent">My brother's reaction</div>
                  </div>
                  <br />
                </div>
              </div>

              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <iframe className='iframe_part3 img_podcast_recent' src="https://player.vimeo.com/video/982590316" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">How the Power of "Minutes" Changed My Life</div>
                    <div className="text2_podcast_recent">My experience in America</div>
                  </div>
                  <br />
                </div>
              </div>

              <div className="col-sm-3 jett_scrollmenu_in">
                <div className="impact_box_part_cont mb-4">
                  <iframe className='iframe_part3 img_podcast_recent' src="https://player.vimeo.com/video/957327933" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  <div className="part2_video_cont py-2">
                    <div className="text1_podcast_recent">Answering Your Questions About the Book</div>
                    <div className="text2_podcast_recent">Discussing the book</div>
                  </div>
                  <br />
                </div>
              </div>


            </div>
          </div>
        </div>

        {/* ==============================Part 1_2==================== */}
        <div className="bar_separation1_podcast"></div>
        {/* ==============================Part 1_2==================== */}

        <a href='https://ayisyenkapab.com/persist-collection/' target='_blank'>
          <img className='w-100' src='/images/new/h_shop.png' />
        </a>

        {/* ============================Part 2 */}



        <br /><br /><br /><br />


      </div>
      <Footer />
    </div>
  )
}


export default Millioncopyjourney