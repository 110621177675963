import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import React from 'react';
import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';


import { useNavigate } from 'react-router-dom';
import { useState } from "react";



function VideoSeries() {


  return (
    <div>
      <Header />

      <div className="bg-black mb-1">
        <div className="container py-5">
          <div className="videoSerie_text1">SUBSCRIBE TO THE</div>
          <div className="videoSerie_text2">THE JOURNEY TO LAUNCH</div>
          <div className="videoSerie_text1">SERIES</div>

          <p className="videoSerie_text3 py-4">
            All about the book, Junior’s journey, <br />
            and more—delivered weekly to your inbox!
          </p>

          <div className=" col-sm-7 m-auto">

            <div className="videoSerie_box1">
              <div className="row">
                <div className="col-sm-6 text-center videoSerie_text4 bar_sep1001">
                  Insightful - Entertaining - Unpredictible
                </div>
                <div className="col-sm-6 text-center videoSerie_text4">
                  <img src={'/images/icons/star.png'} className="icon_star" /> Exclusive to subscribers only
                </div>
              </div>
            </div>
            {/* ======= */}
            <div className="jett_scrollmenu bg_scroll_jett mt-2">
              <div className="col-sm-4 jett_scrollmenu_in">
                <div className="impact_box_part_cont">
                  <img src={'/images/podcast/on_fire/1.png'} className="img_podcast_recent" />
                </div>
              </div>

              <div className="col-sm-4 jett_scrollmenu_in">
                <div className="impact_box_part_cont">
                  <img src={'/images/podcast/on_fire/2.png'} className="img_podcast_recent" />
                </div>
              </div>

              <div className="col-sm-4 jett_scrollmenu_in">
                <div className="impact_box_part_cont">
                  <img src={'/images/podcast/on_fire/3.png'} className="img_podcast_recent" />
                </div>
              </div>

            </div>

            <center>
              <div className=" mt-5">
                <a href="" className="btn_Video_serie1">SUBSCRIBE</a>
              </div>
            </center>





          </div>


        </div>
      </div>

      <Footer />
    </div>
  )
}


export default VideoSeries