import React, { useRef, useState, useEffect, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';

const AudioPlayer = () => {
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1); // Volume range is 0.0 to 1.0
  const [isPlaying, setIsPlaying] = useState(false); // Track play/pause state
  const [loading, setLoading] = useState(true); // Track loading state
  const audioPath = '/reviewer/audioBook.mp3';

  useEffect(() => {
    if (waveformRef.current) {
      // Initialize WaveSurfer instance
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#ddd',
        progressColor: '#ff4e09',
        height: 100,
        responsive: true,
        barWidth: 2,
        cursorWidth: 1,
      });

      // Load audio file
      wavesurferRef.current.load(audioPath);

      // Handle errors
      wavesurferRef.current.on('error', (error) => {
        console.error('WaveSurfer error:', error);
      });

      // Update current time
      wavesurferRef.current.on('audioprocess', () => {
        setCurrentTime(wavesurferRef.current.getCurrentTime());
      });

      // Handle play/pause state change
      wavesurferRef.current.on('play', () => {
        setIsPlaying(true);
      });
      wavesurferRef.current.on('pause', () => {
        setIsPlaying(false);
      });

      // Handle ready event to hide loader
      wavesurferRef.current.on('ready', () => {
        setLoading(false);
      });

      // Cleanup on component unmount
      return () => {
        if (wavesurferRef.current) {
          wavesurferRef.current.destroy();
        }
      };
    }
  }, [audioPath]);

  const handlePlayPause = useCallback(() => {
    if (wavesurferRef.current) {
      if (isPlaying) {
        wavesurferRef.current.pause();
      } else {
        wavesurferRef.current.play();
      }
    }
  }, [isPlaying]);

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (wavesurferRef.current) {
      wavesurferRef.current.setVolume(newVolume);
    }
  };

  const skipTime = (seconds) => {
    if (wavesurferRef.current) {
      const newTime = Math.max(0, Math.min(wavesurferRef.current.getDuration(), wavesurferRef.current.getCurrentTime() + seconds));
      wavesurferRef.current.seekTo(newTime / wavesurferRef.current.getDuration());
      setCurrentTime(newTime);
    }
  };

  // Format time in minutes:seconds
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <div className="audio-player">
      <div className="waveform-container">
        <div ref={waveformRef} id="waveform" />
        {loading && <div className="loader">Loading...</div>}
      </div>
      <div className="controls">
        <button onClick={() => skipTime(-10)} className="skip-backward-button">
          <img src="/images/icons/player/back10.png" alt="Rewind 10s" className="img_player" />
        </button>
        <button onClick={handlePlayPause} className="play-pause-button mx-3">
          <img
            src={isPlaying ? '/images/icons/player/pause10.png' : '/images/icons/player/play10.png'}
            alt={isPlaying ? 'Pause' : 'Play'}
            className="img_player"
          />
        </button>
        <button onClick={() => skipTime(10)} className="skip-forward-button">
          <img src="/images/icons/player/next10.png" alt="Forward 10s" className="img_player" />
        </button>
        <div className="volume-control">
          <label htmlFor="volume"><img src={'/images/icons/player/high-volume.png'} className='img_player' /> </label>&nbsp;
          <input
            id="volume"
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
