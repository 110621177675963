import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useNavigate, Link } from 'react-router-dom';
function Buy25() {
  const navigate = useNavigate();

  const hardcover_normal = 32.99;
  const hardcover = 28.04;
  const hardcover_inf = 28.04;

  const paperback_normal = 21.99;
  const paperback = 18.69;
  const paperback_inf = 18.69;

  // ==========
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const [isChecked, setIsChecked] = useState(false);



  const handleCheckboxChange2 = (event) => {
    setIsChecked2(event.target.checked);
  };
  const [isChecked2, setIsChecked2] = useState(false);




  const [input, setInput] = useState({
    qty_hardcover: "25",
    name1: "",
    phone1: "",
    email1: "",
    description_hardcover: "",

    street1: "",
    city1: "",
    state_province1: "",
    postal_ZIP_code1: "",
    country1: "",



    // ======

    qty_paperback: "25",
    name2: "",
    phone2: "",
    email2: "",
    description_paperback: "",

    influencer_id: "",
    street2: "",
    city2: "",
    state_province2: "",
    postal_ZIP_code2: "",
    country2: "",
  });

  const handleChange = (e) => {
    let newValue = {
      ...input,
      [e.target.name]: e.target.value,
    };
    setInput(newValue);
  };
  // ============hardcover================


  const [error, setError] = useState("");
  const [error2, setError2] = useState("");

  const CheckHardcover = (e) => {
    e.preventDefault();
    setError("");
    if (input.name1 && input.phone1 && input.email1 && input.street1 && input.city1 && input.state_province1 && input.postal_ZIP_code1 && input.country1) {
      try {



        navigate('/Buy_checkout_no_influencer',
          {
            state: {
              type: "HARDCOVER",
              qty: input.qty_hardcover,
              amount: input.qty_hardcover * hardcover,
              name: input.name1,
              phone: input.phone1,
              email: input.email1,
              description: input.description_hardcover,

              street: input.street1,
              city: input.city1,
              state_province: input.state_province1,
              postal_ZIP_code: input.postal_ZIP_code1,
              country: input.country1
            }
          });


      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Error: Missing information (Add shipping details below)");
    }
  }

  // =============PAPERBACK===============

  const CheckPaperBack = (e) => {
    e.preventDefault();
    setError2("");
    if (input.name2 && input.phone2 && input.email2 && input.street2 && input.city2 && input.state_province2 && input.postal_ZIP_code2 && input.country2) {
      try {

        navigate('/Buy_checkout_no_influencer',
          {
            state: {
              type: "PAPERBACK",
              qty: input.qty_paperback,
              amount: input.qty_paperback * paperback,
              name: input.name2,
              phone: input.phone2,
              email: input.email2,
              description: input.description_paperback,


              street: input.street2,
              city: input.city2,
              state_province: input.state_province2,
              postal_ZIP_code: input.postal_ZIP_code2,
              country: input.country2
            }
          });
      } catch (error2) {
        console.log(error2);
      }
    } else {
      setError2("Error: Missing information (Add shipping details below)");
    }
  }
  return (
    <div>
      <Header />
      <div className="book">
        <center>
          <div className=" slider_book_text1 h2 text-white">START YOUR PRE-ORDER NOW</div>

        </center>
      </div>


      {/* ======================================================= */}
      {/* <div className="buy_jett_space2 d-none d-sm-block">
        <div className="px-4 py-3">
          <div className="row pt-2">
            <div className="col-sm-6 separation2345 align-self-center">
              <div className="h5"><img src={"./images/payment_type2.png"} className="payment_image_accept2" /></div>
            </div>
            <div className="col-sm-6 align-self-center">
              <div className="eapce95875">
                <img className="image_jett83645" src={"/images/book/5.png"} />
                <span className="px-3 h5 jett84646">WANT TO ORDER IN BULK? <a href="#PREORDER" className="text90487645">CLICK HERE.</a></span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* ======================================================= */}

      {/* <div className="col-sm-8 m-auto mt-5">
        <div className="text-center text-white h5 new_buy001 col-sm-8 m-auto  py-3">BULK ORDER SPECIAL</div>
        <br />
        <div className="d-none d-sm-block ">
          <div className="row ">
            <div className="col-sm-6 new_buy001_box1 py-3 px-4">
              <div className="">
                <div className="float-end">
                  Buy <strong>3+ hardcover copies</strong> <br />
                  and <strong>SAVE BIG</strong> on shipping!
                </div>
              </div>
            </div>
            <div className="col-sm-6 py-3">
              Buy<strong> 10+ hardcover copies</strong> for friends<br /> and family—get <strong>FREE</strong> shipping!
            </div>
          </div>
        </div>

         =====Mobile 
        <div className="text-center d-block d-sm-none">
          <div className=" new_buy001_box1 py-2">
            Buy <strong>3+ Hardcover copies</strong> <br />
            and <strong>SAVE BIG</strong> on shipping!
          </div>
          <div className="py-2">
            Buy<strong> 10+ Hardcover copies</strong> for friends<br /> and family—get <strong>FREE</strong> shipping!
          </div>
        </div>
      </div> */}

      <div className="buy_7585 ">
        <div className="container">
          <div className="row">






            {/*========================PEPERBACK=====================  */}
            <div className="col-sm-6">
              <div className="int_cont1_buy ">
                <center>
                  <img className="buy_image001" src={'images/paperback.png'} />
                  <h3 className="py-4 text-white">GET THE PAPERBACK</h3>
                </center>

                <div className="bar_cont1_buy">
                  <center>

                    <div className="text_bar_cont1_buy">
                      <table className="buy_table">
                        <tr>
                          {/* <td><span className="buy_text56">Price:</span> $ {Number(hardcover).toFixed(2)}</td> */}
                          <td><span className="buy_text56">Price: </span>
                            {/* <span className="text-decoration-line-through"> ${Number(hardcover).toFixed(2)}</span> */}

                            <span className="text-decoration-line-through"> ${Number(paperback_normal).toFixed(2)}</span>
                            &nbsp; &nbsp;${Number(paperback).toFixed(2)}
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td><span className="buy_text56">Quantity: </span></td>
                          <td><input className="buy_input_98" name="qty_paperback" value={input.qty_paperback} type="number" onChange={handleChange} /></td>




                        </tr>
                      </table>
                      <div className="text_disc">Great news! You're about to save 10% OFF this order.</div>
                    </div>
                  </center>
                </div>

                <div className="container px-4 pb-3">
                  <div className="cont_cent_buy_7468">
                    <div className="cont2_cent_buy_7468">
                      <table className="table_buy_345">
                        <tr>
                          <td>Subtotal:</td>
                          <td>$ {Number(input.qty_paperback * paperback).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td className="total_buy">Total:</td>
                          <td className="total_buy">$ {Number(input.qty_paperback * paperback).toFixed(2)} USD</td>
                        </tr>
                      </table>
                      <p className="text_tax_buy">Taxes and shipping calculated at checkout</p>
                      {error2 && <div className='error_text'>{error2}</div>}
                      <input className="input_part2_jett2" placeholder='Name' name="name2" type="text" id="name2" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Phone' name="phone2" type="text" id="phone2" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Email Address' name="email2" type="text" id="email2" required="" onChange={handleChange} />
               
                      <textarea className="input_part2_jett2" placeholder="Order note" name="description_paperback" onChange={handleChange} ></textarea>
                      {/*  ============SHIPPING====================*/}
                      <div>
                        <input id="shipping99" className="form-check-input" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />

                        <label class="form-check-label" for="shipping99">
                          &nbsp; Shipping Address
                        </label>
                      </div>

                      {isChecked && (
                        <div>
                          <input className="input_part2_jett2" placeholder='Street Address' name="street2" type="text" id="street2" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='City' name="city2" type="text" id="city2" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='State/Province' name="state_province2" type="text" id="state_province2" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='Postal/ZIP Code' name="postal_ZIP_code2" type="text" id="postal_ZIP_code2" required="" onChange={handleChange} />
                          <select className="input_part2_jett2" name="country2" id="country2" required onChange={handleChange}>
                            <option value="" disabled selected>Select your country</option>
                            <option value="USA">USA</option>
                            <option value="Canada">Canada</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="France">France</option>
                            <option value="Germany">Germany</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Kenya">Kenya</option>
                          </select>

                        </div>
                      )}

                      {/* ================ */}


                      <div>
                        <center>
                          <div className="mt-2 mb-4">
                            <button onClick={(e) => { CheckPaperBack(e) }} className="btn_buy_now " ><i className="bi bi-lock px-2"></i>PROCEED TO SAFE CHECKOUT</button>
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>




            {/*========================HARDCOVER=====================  */}
            <div className="col-sm-6">
              <div className="int_cont1_buy ">
                <center>
                  <img className="buy_image001" src={'images/hardcover.png'} />
                  <h3 className="py-4 text-white">GET THE HARDCOVER</h3>
                </center>

                <div className="bar_cont2_buy py-5">
                  <center>
                    <table className="buy_table">
                      <tr>
                        {/* <td><span className="buy_text56">Price:</span> $ {Number(paperback).toFixed(2)}</td> */}
                        <td><span className="buy_text56">Price:</span>
                          {/* <span className="text-decoration-line-through"> ${Number(paperback).toFixed(2)}</span> */}




                          <span className="text-decoration-line-through"> ${Number(hardcover_normal).toFixed(2)}</span>
                          &nbsp; &nbsp;${Number(hardcover).toFixed(2)}
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td><span className="buy_text56">Quantity: </span></td>
                        <td><input className="buy_input_98" name="qty_hardcover" value={input.qty_hardcover} type="number" onChange={handleChange} /></td>
                      </tr>
                    </table>
                    <div className="text_disc">Great news! You're about to save 10% OFF this order.</div>
                  </center>
                </div>

                <div className="container px-4 pb-3">
                  <div className="cont_cent_buy_7468">
                    <div className="cont2_cent_buy_7468">
                      <table className="table_buy_345">
                        <tr>
                          <td>Subtotal:</td>
                          <td>$ {Number(input.qty_hardcover * hardcover).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td className="total_buy">Total:</td>
                          <td className="total_buy">$ {Number(input.qty_hardcover * hardcover).toFixed(2)} USD</td>
                        </tr>
                      </table>


                      <p className="text_tax_buy">Taxes and shipping calculated at checkout</p>
                      {error && <div className='error_text'>{error}</div>}
                      <input className="input_part2_jett2" placeholder='Name' name="name1" type="text" id="name1" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Phone' name="phone1" type="text" id="phone1" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Email Address' name="email1" type="text" id="email1" required="" onChange={handleChange} />

                      <textarea className="input_part2_jett2" name="description_hardcover" onChange={handleChange} placeholder="Order note"></textarea>
                      {/*  ============SHIPPING====================*/}
                      <div>
                        <input id="shipping99" className="form-check-input" type="checkbox" checked={isChecked2} onChange={handleCheckboxChange2} />

                        <label class="form-check-label" for="shipping99">
                          &nbsp; Shipping Address
                        </label>
                      </div>

                      {isChecked2 && (
                        <div>
                          <input className="input_part2_jett2" placeholder='Street Address' name="street1" type="text" id="street1" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='City' name="city1" type="text" id="city1" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='State/Province' name="state_province1" type="text" id="state_province1" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='Postal/ZIP Code' name="postal_ZIP_code1" type="text" id="postal_ZIP_code1" required="" onChange={handleChange} />
                          <select className="input_part2_jett2" name="country1" id="country1" required onChange={handleChange}>
                            <option value="" disabled selected>Select your country</option>
                            <option value="USA">USA</option>
                            <option value="Canada">Canada</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="France">France</option>
                            <option value="Germany">Germany</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Kenya">Kenya</option>
                          </select>

                        </div>
                      )}

                      {/*  ================================*/}



                      <div>
                        <center>
                          <div className="mt-2">
                            <button onClick={(e) => { CheckHardcover(e) }} className="btn_buy_now2 " ><i className="bi bi-lock px-2"></i>PROCEED TO SAFE CHECKOUT</button>
                          </div>
                        </center>
                      </div>
                    </div>
                    <div className="d-none d-sm-block"><br /></div>
                  </div>
                </div>

              </div>
            </div>



          </div>
        </div>

        <div className="d-none d-sm-block"><br /></div>

        {/* ======================================================= */}




        {/* ===================================== */}


      </div>

      <Footer />
    </div>
  )
}


export default Buy25