import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Header from "../Header";
import { Footer } from "flowbite-react";
import Menu from "./Menu";
import { NumericFormat } from 'react-number-format';
import { Result } from "postcss";


function SearchProduct() {

  const[data,setData] = useState([])

async function search(key)
{
  console.warn(key)
  let result = await fetch("https://juniorpersist.com/backend/public/api/searchProduct/"+key);
  // let result = await fetch("http://127.0.0.1:8000/api/searchProduct/"+key);
  result = await result.json();
  setData(result)
  console.warn(result)
}






  

  return (
    <div>
      <Header />


      <div className="dash_body py-4">
        <div className="page-content container note-has-grid ">
          <Menu />
          <div className="tab-content bg-transparent">
            <div id="note-full-container" className="note-has-grid row">







             <center>
                <div className="col-sm-12 ">
                  <input placeholder="Search a product" className="input_jett_search" type="text" required="" 
                  onChange={(e)=>search(e.target.value)} autoFocus
                  />
                  </div>

             </center>







             <div className="container mt-3 mb-5">
                <div className="d-flex justify-content-center row">
                  <div className="col-md-10">

                    {
                      data.map((dataObj) =>
                        <div className="row p-2 bg-white border rounded mb-3">
                          <div className="col-md-2 mt-1"><img className="img-fluid img-responsive rounded product-image" src={"http://127.0.0.1:8000/" + dataObj.file_path} /></div>
                          <div className="col-md-6 mt-1">
                            <h5>{dataObj.name}</h5>

                            <div className="mt-1 mb-1 spec-1">
                              <span>Unique design</span>
                              <span className="dot"></span>
                              <span>For men</span>
                              <span className="dot"></span>
                              <span>Casual<br /></span>
                            </div>
                            <p className="text-justify text-truncate para mb-0">
                              {dataObj.description}
                            </p>
                          </div>
                          <div className="align-items-center align-content-center col-md-3 border-left mt-1">
                            <div className="d-flex flex-row align-items-center">
                              <h4 className="mr-1">$ {dataObj.price}</h4><span className="strike-text">$00.00</span>
                            </div>
                            <div className="d-flex flex-column mt-4">
                              <Link to={"../admin/detailsproduct/" + dataObj.id} className="btn btn-primary btn-sm" >Details</Link>

                              {/* <button onClick={() => { deleteProduct(dataObj.id) }} className="btn btn-outline-primary btn-sm mt-2" >
                                <i className="bi bi-trash"></i>  Delete
                              </button> */}
                            </div>
                          </div>
                        </div>

                      )}
                  </div>
                </div>
              </div>






















            </div>
          </div>


        </div>

      </div>



      <Footer />
    </div>
  )
}


export default SearchProduct;


