import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React, { useState, useEffect } from "react";
import { formatDistanceToNow, differenceInHours } from 'date-fns';
function Footer() {


  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  // =====
  // async function getData() {
  //   let result = await fetch("https://juniorpersist.com/public/public/api/listpreorder");
  //   // let result = await fetch("http://127.0.0.1:8000/api/listpreorder");
  //   result = await result.json();

  //   // Transform the created_at field
  //   const transformedData = result.map(dataObj => ({
  //     ...dataObj,
  //     created_at: formatDistanceToNow(new Date(dataObj.created_at), { addSuffix: true })
  //   }));

  //   setData(transformedData);
  // }




  async function getData() {
    let result = await fetch("https://juniorpersist.com/public/public/api/listpreorder");
    // let result = await fetch("http://127.0.0.1:8000/api/listpreorder");
    result = await result.json();

    // Transform the created_at field
    const transformedData = result.map(dataObj => {
      const createdAtDate = new Date(dataObj.created_at);
      const isRecent = differenceInHours(new Date(), createdAtDate) >= 48;
      return {
        ...dataObj,
        created_at: isRecent ? 'Recently' : formatDistanceToNow(new Date(dataObj.created_at), { addSuffix: true })
      };
    });

    setData(transformedData);
  }


  const [isVisible, setIsVisible] = useState(true);
  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  // =====


  // ===========================

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 250,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: false // Disable the left and right arrows
  };
  return (
    // ========Start Footer
    <div>
      <footer className=''>
        {/* <div className="footer_jett pb-4">
          <div className="container-fluid mt-5">
            <div className='text1_footer'>Follow us</div>
            <div className='text2_footer'>CONNECT ONLINE</div>
            <div className="social mt-2 mb-3 text-center">
              <i className="jett_fa_footer fa fa-facebook fa-lg"></i>
              <i className="jett_fa_footer fa fa-instagram fa-lg"></i>
              <i className="jett_fa_footer fa fa-twitter fa-lg"></i>
              <i className="jett_fa_footer fa fa-linkedin fa-lg"></i>
              <i className="jett_fa_footer fa fa-youtube fa-lg"></i>

            </div>
          </div>
        </div> */}

        <div className='footer_jett2 py-4'>



          {/* ============Message buy=============== */}


          <div className="right-bottom-div mt-5">

            <Slider {...settings}>
              {data.map((dataObj, index) => (
                <div key={index}>
                  {dataObj.type === "EBOOK" ? (
                    // EBOOK
                    <div className='py-5'>
                      <button onClick={handleClose} className="close-button"><img className='ico_jett_close' src={'/images/icons/close.png'} /></button>
                      <div className='px-3 jett_bg984 mt-5'>
                        <img src='/images/book/1.png' className='img-right-bottom-div px-3' alt='Ebook' />
                        <div className='m-34-text-bg'>
                          <div className='m-34-text-bg2'>
                            <div className='text-mess-1'>
                              {dataObj.name.substring(0, 7)} in <span className='text-uppercase'>
                                {dataObj.country == null ? "USA" : dataObj.country.substring(0, 8)}
                              </span> 
                            </div>
                            <div className='text-mess-1'>
                              bought {dataObj.qty} ebook {dataObj.qty > 1 ? "copies" : "copy"}!
                            </div>
                            <div className='text-mess-2'>
                              <table>
                                <tbody>
                                  <tr>
                                    <td><img src='/images/icons/time-left.png' className='img-time-left' alt='Time left' /></td>
                                    <td><span className='px-1'>{dataObj.created_at}</span></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // PAPERBACK
                    <div className='py-5'>
                      <button onClick={handleClose} className="close-button"><img className='ico_jett_close' src={'/images/icons/close.png'} /></button>
                      <div className='px-3 jett_bg984 mt-5'>
                        <img src='/images/book/4.png' className='img-right-bottom-div px-3' alt='Paperback' />
                        <div className='m-34-text-bg'>
                          <div className='m-34-text-bg2'>
                            <div className='text-mess-1'>
                              {dataObj.name.substring(0, 7)} in <span className='text-uppercase'>
                                {dataObj.country == null ? "USA" : dataObj.country.substring(0, 8)}
                              </span> 
                            </div>
                            <div className='text-mess-1'>
                              bought {dataObj.qty} {dataObj.qty > 1 ? "copies" : "copy"}!
                            </div>
                            <div className='text-mess-2'>
                              <table>
                                <tbody>
                                  <tr>
                                    <td><img src='/images/icons/time-left.png' className='img-time-left' alt='Time left' /></td>
                                    <td><span className='px-1 text-mess-3'>{dataObj.created_at}</span></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </Slider>
          </div>
          {/* /* =========================== */}










          <center>
            <img className='footer_logo_jett d-none d-sm-block' src={"/images/icons/favicon.ico"} />


            <div className='jett_footer985 py-2 col-sm-8 d-none d-md-block'>
              <ul className="mb-2 mb-lg-0 jett_footer_menu">
                <li className="nav-item">
                  <Link className="menu_text text-white" to="/">HOME</Link>
                </li>
           
                <li className="nav-item">
                  <Link className="menu_text text-white" to="/Speaking">SPEAKING</Link>
                </li>
                <li className="nav-item">
                  <Link className="menu_text text-white" to="/Video">REVIEWS</Link>
                </li>
                <li className="nav-item">
                  <Link className="menu_text text-white" to="/About">ABOUT</Link>
                </li>
                <li className="nav-item">
                  <Link className="menu_text text-white" to="/Contact">CONTACT</Link>
                </li>
                <li className="nav-item">
                  <a target='_blank' href='https://persistblog.com/' className="menu_text text-white" >BLOG</a>
                </li>
                <li className="nav-item">
                  <Link className="menu_text text-white" to="/SpreadTheWord">ENGAGE</Link>
                </li>
              </ul>
            </div>
          </center>

	

          <div className='container d-flex align-items-center justify-content-center footer_jett_3098'>
            <div className='pt-5 col-sm-12'>
              <div className='row'>
                <div className='col-sm-5'>
                  <div className='text5_footer'>
                    <div className='text3_footer'>Persist, Persist, Persist.</div>
                    <div className='text4_footer'>The Haitian Boy, The American, and The Hidden Lesson</div>

                  </div>
                </div>


                <div className='col-sm-7'>

                  <center>
                    <div className='footer_jett_flot54  mt-4'>
                      <a className="btn_login_jett px-2 " href="https://ayisyenkapab.com/persist-collection/" target='_blank'>SHOP</a>
                      <a className="btn_donate_jett px-2" href="/BuyDetails">PRE-ORDER NOW</a>
                    </div>
                  </center>
                </div>
              </div>
              <div className='bar_footer mt-3'></div>
              <div className='footer_jett78489 py-2 text-center opacity-50'>
                <i className="fa fa-copyright"></i> 2024, PERSIST PERSIST PERSIST. All right reserved.
              </div>

            </div>
          </div>




        </div>


      </footer>
    </div>
    // ========End Footer
  )
}

export default Footer