import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useState } from "react";


function Join() {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  async function addHelpData(e) {
    e.preventDefault();
    setError("");

    if (name && phone && email && description) {
      console.warn(name, phone, email, description)
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('description', description);
      formData.append('type', 'STAND WITH US');

      try {
        let result = await fetch("https://juniorpersist.com/backend/public/api/addHelpStandWith", {
          // let result = await fetch("http://127.0.0.1:8000/api/addHelpStandWith", {
          method: 'POST',
          body: formData
        });
        navigate('/Success_message')
      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Error: all fields required");
    }
  }

  return (
    // ========Start Join
    <div className=''>
      <div className='d-none  d-md-block'> <br /> <br /></div>
      <div className='part6'>

        <div className='col-sm-5 px-5  jett_block_double_slider'>
          <div className='d-none d-sm-block'> <br /> <br /> <br /><br /></div>
          <br /><br /><br /> <br />
          {/* <div className='text6_part6 py-3'>WE CALL ON YOU TO STAND WITH US</div> */}
          <div className='text6_part6 py-1'>FIRST CHAPTER ACCESS COMING SOON!</div>


          <div className='text5_part6'>
            {/* Wherever you are, we urge you to join Junior in his mission to transform Haiti through education. Here's how you can contribute:
            <br /> */}

            <div className='text5_part6_1 mt-2 mb-3'>
              Curious about what's in the book? Wonder no more!
              Junior is giving away the first chapter of the book, 'Persist, Persist, Persist', to 1,000 super fans prior to the book release. Sign up below so you don’t miss out!
            </div>

          </div>

          <a href="https://lp.constantcontactpages.com/sl/mt3B9Qg/persistbook" target='_blank' className='btn_part2_jett mt-2 py-1 px-4'>STAY UPDATED</a>

          {/* <div className=' justify-content-center  shadow-lg px-5 py-5 bg-white'>
            <div className='form'>
            {error && <div className='error_text'>{error}</div>}
              <input className="input_part2_jett" placeholder='Name' type="text" id="name" required="" onChange={(e) => setName(e.target.value)} />
              <input className="input_part2_jett" placeholder='Phone' type="text" id="phone" required="" onChange={(e) => setPhone(e.target.value)} />
              <input className="input_part2_jett" placeholder='Email Address' type="text" id="email" required="" onChange={(e) => setEmail(e.target.value)} />
              <textarea className="input_part2_jett" placeholder='Tell us how you would like to help...' id="description" required="" onChange={(e) => setDescription(e.target.value)}></textarea>

              <button onClick={(e)=>addHelpData(e)} className='btn_part2_jett mt-2 py-1'>TAKE ACTION</button>
            </div>
          </div> */}
        </div>
      </div>

    </div>
    // ========End header
  )
}

export default Join