import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useNavigate, Link } from 'react-router-dom';
function Buy() {
  const navigate = useNavigate();

  const ebook_normal = 9.99;
  const ebook = 8.99;
  const ebook_inf = 8.49;

  const paperback_normal = 32.99;
  const paperback = 29.69;
  const paperback_inf = 28.04;

  // ==========
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const [isChecked, setIsChecked] = useState(false);

  const [input, setInput] = useState({
    qty_ebook: "1",
    name: "",
    phone: "",
    email: "",
    description_ebook: "",


    // ======

    qty_paperback: "1",
    name2: "",
    phone2: "",
    email2: "",
    description_paperback: "",

    influencer_id: "",
    street: "",
    city: "",
    state_province: "",
    postal_ZIP_code: "",
    country: "",
  });

  const handleChange = (e) => {
    let newValue = {
      ...input,
      [e.target.name]: e.target.value,
    };
    setInput(newValue);
  };
  // ============EBOOK================


  const [error, setError] = useState("");
  const [error2, setError2] = useState("");

  const CheckEbook = (e) => {
    e.preventDefault();
    setError("");
    if (input.name && input.phone && input.email) {
      try {



        navigate('/Buy_checkout_no_influencer',
          {
            state: {
              type: "EBOOK",
              qty: input.qty_ebook,
              amount: input.qty_ebook * ebook,
              name: input.name,
              phone: input.phone,
              email: input.email,
              description: input.description_ebook,
              country: input.country
            }
          });


      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Error: Missing information");
    }
  }

  // =============PAPERBACK===============

  const CheckPaperBack = (e) => {
    e.preventDefault();
    setError2("");
    if (input.name2 && input.phone2 && input.email2 && input.street && input.city && input.state_province && input.postal_ZIP_code && input.country) {
      try {

        navigate('/Buy_checkout_no_influencer',
          {
            state: {
              type: "PAPERBACK",
              qty: input.qty_paperback,
              amount: input.qty_paperback * paperback,
              name: input.name2,
              phone: input.phone2,
              email: input.email2,
              description: input.description_paperback,


              street: input.street,
              city: input.city,
              state_province: input.state_province,
              postal_ZIP_code: input.postal_ZIP_code,
              country: input.country
            }
          });
      } catch (error2) {
        console.log(error2);
      }
    } else {
      setError2("Error: Missing information (Add shipping details below)");
    }
  }
  return (
    <div>
      <Header />
      <div className="book">
        <center>
          <div className=" slider_book_text1 h2 text-white">START YOUR PRE-ORDER NOW</div>

        </center>
      </div>


      {/* ======================================================= */}
      {/* <div className="buy_jett_space2 d-none d-sm-block">
        <div className="px-4 py-3">
          <div className="row pt-2">
            <div className="col-sm-6 separation2345 align-self-center">
              <div className="h5"><img src={"./images/payment_type2.png"} className="payment_image_accept2" /></div>
            </div>
            <div className="col-sm-6 align-self-center">
              <div className="eapce95875">
                <img className="image_jett83645" src={"/images/book/5.png"} />
                <span className="px-3 h5 jett84646">WANT TO ORDER IN BULK? <a href="#PREORDER" className="text90487645">CLICK HERE.</a></span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* ======================================================= */}

      <div className="col-sm-8 m-auto mt-5">
        <div className="text-center text-white h5 new_buy001 col-sm-8 m-auto  py-3">BULK ORDER SPECIAL</div>
        <br />
        <div className="d-none d-sm-block ">
          <div className="row ">
            <div className="col-sm-6 new_buy001_box1 py-3 px-4">
              <div className="">
                <div className="float-end">
                  Buy <strong>3+ hardcover copies</strong> <br />
                  and <strong>SAVE BIG</strong> on shipping!
                </div>
              </div>
            </div>
            <div className="col-sm-6 py-3">
              Buy<strong> 10+ hardcover copies</strong> for friends<br /> and family—get <strong>FREE</strong> shipping!
            </div>
          </div>
        </div>

        {/* =====Mobile */}
        <div className="text-center d-block d-sm-none">
          <div className=" new_buy001_box1 py-2">
            Buy <strong>3+ Hardcover copies</strong> <br />
            and <strong>SAVE BIG</strong> on shipping!
          </div>
          <div className="py-2">
            Buy<strong> 10+ Hardcover copies</strong> for friends<br /> and family—get <strong>FREE</strong> shipping!
          </div>
        </div>
      </div>

      <div className="buy_7585 ">
        <div className="container">
          <div className="row">




            {/*=============================================  */}
            <div className="col-sm-6">
              <div className="int_cont1_buy ">
                <center>
                  <img className="buy_image001" src={'images/book/4.png'} />
                  <h3 className="py-4 text-white">GET THE HARDCOVER</h3>
                </center>

                <div className="bar_cont2_buy py-5">
                  <center>
                    <table className="buy_table">
                      <tr>
                        {/* <td><span className="buy_text56">Price:</span> $ {Number(paperback).toFixed(2)}</td> */}
                        <td><span className="buy_text56">Price:</span>
                          {/* <span className="text-decoration-line-through"> ${Number(paperback).toFixed(2)}</span> */}
                          
                          


                          <span className="text-decoration-line-through"> ${Number(paperback_normal).toFixed(2)}</span>
                          &nbsp; &nbsp;${Number(paperback).toFixed(2)}
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td><span className="buy_text56">Quantity: </span></td>
                        <td><input className="buy_input_98" name="qty_paperback" value={input.qty_paperback} type="number" onChange={handleChange} /></td>
                      </tr>
                    </table>
                    <div className="text_disc">Great news! You're about to save 10% OFF this order.</div>
                  </center>
                </div>

                <div className="container px-4 pb-3">
                  <div className="cont_cent_buy_7468">
                    <div className="cont2_cent_buy_7468">
                      <table className="table_buy_345">
                        <tr>
                          <td>Subtotal:</td>
                          <td>$ {Number(input.qty_paperback * paperback).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td className="total_buy">Total:</td>
                          <td className="total_buy">$ {Number(input.qty_paperback * paperback).toFixed(2)} USD</td>
                        </tr>
                      </table>

                     
                      <p className="text_tax_buy">Taxes and shipping calculated at checkout</p>
                      {error2 && <div className='error_text'>{error2}</div>}
                      <input className="input_part2_jett2" placeholder='Name' name="name2" type="text" id="nam2e" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Phone' name="phone2" type="text" id="phone2" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Email Address' name="email2" type="text" id="email2" required="" onChange={handleChange} />

                      <textarea className="input_part2_jett2" name="description_paperback" onChange={handleChange} placeholder="Order note"></textarea>
                      {/*  ============SHIPPING====================*/}
                      <div>
                        <input id="shipping99" className="form-check-input" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />

                        <label class="form-check-label" for="shipping99">
                          &nbsp; Shipping Address
                        </label>
                      </div>

                      {isChecked && (
                        <div>
                          <input className="input_part2_jett2" placeholder='Street Address' name="street" type="text" id="street" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='City' name="city" type="text" id="city" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='State/Province' name="state_province" type="text" id="state_province" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='Postal/ZIP Code' name="postal_ZIP_code" type="text" id="postal_ZIP_code" required="" onChange={handleChange} />
                          {/* <input className="input_part2_jett2" placeholder='Country' name="country" type="text" id="country" required="" onChange={handleChange} /> */}
                          <select className="input_part2_jett2" name="country" id="country" required onChange={handleChange}>
                            <option value="" disabled selected>Select your country</option>
                            <option value="USA">USA</option>
                            <option value="Canada">Canada</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="France">France</option>
                            <option value="Germany">Germany</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Kenya">Kenya</option>
                          </select>

                        </div>
                      )}

                      {/*  ================================*/}



                      <div>
                        <center>
                          <div className="mt-2">
                            <button onClick={(e) => { CheckPaperBack(e) }} className="btn_buy_now2 " ><i className="bi bi-lock px-2"></i>PROCEED TO SAFE CHECKOUT</button>
                          </div>
                        </center>
                      </div>
                    </div>
                    <div className="d-none d-sm-block"><br /><br /></div>
                  </div>
                </div>

              </div>
            </div>


            {/*========================EBOOK=====================  */}
            <div className="col-sm-6">
              <div className="int_cont1_buy ">
                <center>
                  <img className="buy_image001" src={'images/book/1.png'} />
                  <h3 className="py-4 text-white">GET THE EBOOK</h3>
                </center>

                <div className="bar_cont1_buy">
                  <center>

                    <div className="text_bar_cont1_buy">
                      <table className="buy_table">
                        <tr>
                          {/* <td><span className="buy_text56">Price:</span> $ {Number(ebook).toFixed(2)}</td> */}
                          <td><span className="buy_text56">Price: </span>
                            {/* <span className="text-decoration-line-through"> ${Number(ebook).toFixed(2)}</span> */}

                            <span className="text-decoration-line-through"> ${Number(ebook_normal).toFixed(2)}</span>
                            &nbsp; &nbsp;${Number(ebook).toFixed(2)}
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td><span className="buy_text56">Quantity: </span></td>
                          <td><input className="buy_input_98" name="qty_ebook" value={input.qty_ebook} type="number" onChange={handleChange} /></td>




                        </tr>
                      </table>
                      <div className="text_disc">Great news! You're about to save 10% OFF this order.</div>
                    </div>
                  </center>
                </div>

                <div className="container px-4 pb-3">
                  <div className="cont_cent_buy_7468">
                    <div className="cont2_cent_buy_7468">
                      <table className="table_buy_345">
                        <tr>
                          <td>Subtotal:</td>
                          <td>$ {Number(input.qty_ebook * ebook).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td className="total_buy">Total:</td>
                          <td className="total_buy">$ {Number(input.qty_ebook * ebook).toFixed(2)} USD</td>
                        </tr>
                      </table>
                      <p className="text_tax_buy">Taxes and shipping calculated at checkout</p>
                      {error && <div className='error_text'>{error}</div>}
                      <input className="input_part2_jett2" placeholder='Name' name="name" type="text" id="name" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Phone' name="phone" type="text" id="phone" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Email Address' name="email" type="text" id="email" required="" onChange={handleChange} />
                      <select className="input_part2_jett2" name="country" id="country" required onChange={handleChange}>
                        <option value="" disabled selected>Select your country</option>
                        <option value="USA">USA</option>
                        <option value="Canada">Canada</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="France">France</option>
                        <option value="Germany">Germany</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Kenya">Kenya</option>
                      </select>
                      <textarea className="input_part2_jett2" placeholder="Order note" name="description_ebook" onChange={handleChange} ></textarea>
                      <div>
                        <center>
                          <div className="mt-2 mb-4">
                            <button onClick={(e) => { CheckEbook(e) }} className="btn_buy_now " ><i className="bi bi-lock px-2"></i>PROCEED TO SAFE CHECKOUT</button>
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>







          </div>
        </div>

        <div className="d-none d-sm-block"><br /></div>

        {/* ======================================================= */}

        <div id="PREORDER" className="">
          <div className="bg_84763543">
            <div className="container py-3 d-none d-sm-block">
              <div className="row ">
                <div className="col-sm-6"><img src={'/images/book/5.png'} className="image763437" /></div>
                <div className="col-sm-6"><img src={'/images/heading_picture984.png'} className="image763437984" /></div>
              </div>
            </div>
          </div>
          <div className="buy_por984774_text1 mt-3">PRE-ORDER IN BULK NOW AND SPREAD THE IMPACT</div>
          <div className="buy_por984774_text2 py-2"> Buy a bundle and share your spare copies with< br /> friends, family, or your organization.</div>

          <center>
            <div className="col-sm-6 buy_box1 py-5 mt-5 ">
              <div className="buy_box_text1 px-2">PRE-ORDER 10+ HARDCOVER COPIES</div>
              <div className="buy_box_text1 color_main_jaune px-2"> (FREE SHIPPING INCLUDED!)</div>
              <br />
              <div className="buy_box_btn_cont">
                <a href='/Buy10' className="buy_box_btn px-3 pb-2">ORDER NOW</a>
              </div>
            </div>

            {/* ========= */}

            {/* <div className="col-sm-6 buy_box1 py-5 mt-5 ">
              <div className="buy_box_text1 px-2">PRE-ORDER 100+ HARDCOVER COPIES</div>
              <div className="buy_box_text1 color_main_jaune px-2"> (GET 5 EXTRA COPIES FOR FREE PLUS FREE SHIPPING!)</div>
              <br />
              <div className="buy_box_btn_cont">
                <Link to={'/Buy100'} className="buy_box_btn px-3 pb-2">ORDER NOW</Link>
              </div>
            </div> */}
            <br /><br />
            <div>
              <center>
                <div className="h5 ">EXPLORE MORE WAYS TO BUY IN BULK<br /> AND INSPIRE YOUR COMMUNITY</div>
                <br />
                <div className="col-sm-7 mt-2">
                  <div className="row">
                    <div className="col-sm-3 mb-4">
                      <a href={'/InspireSchools'} className="btn_inspire_buy">SCHOOLS</a>
                    </div>

                    <div className="col-sm-3 mb-4">
                      <a href={'/InspireCompanies'} className="btn_inspire_buy">COMPANIES</a>
                    </div>

                    <div className="col-sm-3 mb-4">
                      <a href={'/InspireChurches'} className="btn_inspire_buy">CHURCHES</a>
                    </div>

                    <div className="col-sm-3 mb-4">
                      <a href={'/InspireBook_clubs'} className="btn_inspire_buy">BOOK CLUBS</a>
                    </div>
                  </div>
                </div>
              </center>

            </div>





            <div className="bg-black mt-5">
              <div className="col-sm-4 py-5">
                <div className="">
                  <img src={"/images/payment_type.png"} className="payment_image_accept" />
                </div>
              </div>
            </div>

          </center>

        </div>


        {/* ===================================== */}





      </div>
      {/* ===================================== */}

      <div className="last_payment_page984 py-3 mb-2">
        <center>
          <div className="col-sm-10">
            <img src={'/images/buy_footer123.png'} className="img_buy_footer123" />
          </div>
        </center>
      </div>












      <Footer />
    </div>
  )
}


export default Buy