import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import '../css/read.css';

import { useState } from "react";

function Special_access001() {

  return (
    <div>
      <Header />




      <div className="main-wrapper read_jett_font_family">
        <div className="book px-3 px-lg-5">
          <div className=" container d-flex align-items-center justify-content-center">
            <div className="row col-sm-10">
              <div className="col-sm-4">
                <img className='slider_book_imager float-end' src={"/images/book/3.png"} />
              </div>
              <div className="col-sm-8">
                <div className="mt-4 slider_book_text1">Your business is about to build a team that is <span className="slider_book_text2">unstoppable</span></div>
                <hr />
                <div className="item_info_book py-3">
                  <div className="pt-4">
                    <center>
                      <Link to="" className="btn_buy_book px-4">Read Summary</Link>
                      <Link to="" className="btn_buy_book3 px-4">Post a Reaction Video!</Link>
                    </center>

                    <p className="pt-4 px-3 text-white">
                      100% of the proceeds from the book is going towards the construction
                      the Pranzel Elite School in Haiti.
                      <a className="p_jett" href="https://pranzeleliteschool.com/" target="_blank">LEARN MORE</a>
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





      {/* =========================Part 3============================ */}

      <div className="lee_book py-5">

        <div className="d-flex align-items-center justify-content-center">
          <div className="col-sm-9 ">

            <div className="container">
              <div className="text_book_part3 py-4 text-center">PERSIST, PERSIST, PERSIST</div>

              <div className="book_part3_text">
                At the tender age of twenty-three, I stumbled into entrepreneurship without grasping the significance of a
                strong team. My misguided attempts at finding and retaining great employees left me frustrated and
                overwhelmed. But through self-reflection, I realized that to build a thriving company, I needed to
                become a great leader, not just a business strategist.
                <br></br>
                <br></br>
                I immersed myself in understanding the potential of each team member and crafted a formula that nurtured
                their talents. Along the way, I learned four key strategies that transformed my leadership approach and
                turned my team into top performers.

                <br></br>
                <br></br>
                In this book, I share the lessons I’ve learned and the insights I’ve gained from studying successful
                companies and leaders. I hope to inspire you to invest in your employees, for they hold the potential
                to be exceptional contributors. You don’t need to be a perfect leader; all that matters is your commitment
                to continuous improvement. Embrace this journey, and together, we’ll unlock the full potential of your team.
              </div>

            </div>

          </div>
        </div>
      </div>

      {/* ============================Part 2 */}

      <div className="cont_social_about">
        <div className="col-sm-6 social_about py-3">
          <div className="text2_social_about opacity-75 py-2">HELP US SPREAD THE WORD. </div>
          <div className="h1 text-center text-white">JUNIOR ON SOCIAL MEDIA</div>
        </div>
      </div>

      <div className="">
        <div className="col-sm-5 social_about py-3">
          <div className="text2_social_about opacity-75 py-2 text-black">
            Help build the school of the future of Haiti by leaving a reaction video.
            This will help us spread the word to raise awareness about Haiti and the work
            Pranzel is doing to impact lives.
          </div>
       

          <center>
            <div className="mt-3">
              <Link to="" className="btn_buy_book3 px-4 ">POST A VIDEO NOW</Link>
            </div>
          </center>

        </div>
      </div>

      {/* ==============================Part 1_2==================== */}


      <div className="d-flex justify-content-center mt-2 py-2 cont_book_part1_2 ">
        <div className="col-sm-10 book_part1_2">
          <h3 className="text-center pt-5 text1_part1_2">LET'S DO THIS TOGETHER!</h3>
          <p className="text2_part1_2">Help us change the world by passing on the book to those around you.</p>

          <center>
            <div className='row col-sm-10 py-4'>

              <div className='col-sm-4 py-3 border_img1_part1_2'>
                <img src={"./images/icons/avatar.png"} className="img1_part1_2" />
                <div className="text-white mb-2">Pass It On</div>
                <a href="" className="btn_buy_book2 px-2">Get 3 Books</a>
              </div>
              <div className='col-sm-4 py-3 border_img1_part1_2'>
                <img src={"./images/icons/avatar.png"} className="img1_part1_2" />
                <div className="text-white mb-2">Pass It On</div>
                <a href="" className="btn_buy_book2 px-2">Get 3 Books</a>
              </div>
              <div className='col-sm-4 py-3'>
                <img src={"./images/icons/avatar.png"} className="img1_part1_2" />
                <div className="text-white mb-2">Pass It On</div>
                <a href="" className="btn_buy_book2 px-2">Get 3 Books</a>
              </div>
            </div>

          </center>
        </div>

      </div>




      <Footer />
    </div>
  )
}


export default Special_access001