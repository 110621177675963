import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import Video_Junior from "../Video_Junior";
function About() {


  return (
    <div>
      <Header />

      <div className="about_slider_page_jett22 ">

        <div className="row">
          <div className="col-sm-6">
            <center>
              <img src={'images/img/about_page.png'} className="img_about9857" />
            </center>
          </div>
          <div className="col-sm-6 align-self-center">
            <div className="text_slider ">

              <div className="new_about">
                <div className="new_about_part1">
                  <div className="new_about_text1">I AM JUNIOR BERNARD</div>
                  <div className="new_about_text2 ">"No matter the pain, there is no mountain you cannot overcome if you have the guts to persist, persist, and persist again."</div>
                </div>

                <div className="new_about_part2 mt-3">
                  <div className="row">
                    <div className="col-sm-8 py-2">
                      <span className="">

                        {/* <Link to={'/Speaking'} >
                          <div className="new_about_part2_box1 mt-2">BOOK JUNIOR FOR YOUR NEXT EVENT</div>
                        </Link>
                        <Link to={'/Contact'} >
                          <div className="new_about_part2_box1 mt-2">INTERVIEWS & MEDIA APPEARANCES</div>
                        </Link> */}

                        <div className="h4 text-white">NEVER MISS A THING!</div>
                        <a href=" https://lp.constantcontactpages.com/sl/mt3B9Qg/persistbook" target="_blank">
                          <div className="contact_box1 mt-2">SIGN UP FOR OUR NEWSLETTER</div>
                        </a>
                        <Link to={'/Speaking'}>
                          <div className="new_about_part2_box1 mt-2">BOOK JUNIOR FOR YOUR NEXT EVENT</div>
                        </Link>




                        <div className="new_about_part2_box1 mt-2">
                          <div className=" jett_scrollmenu">

                            <div className="col-sm-2 jett_scrollmenu_in">
                              <a className="media_fa" href="https://www.facebook.com/juniorpersist" target="_blank">
                                <i className=" fa fa-facebook fa-lg"></i>
                              </a>
                            </div>
                            <div className="col-sm-2 jett_scrollmenu_in">
                              <a className="media_fa" href="https://www.instagram.com/jrpersist/" target="_blank">
                                <i className=" fa fa-instagram fa-lg "></i>
                              </a>
                            </div>
                            <div className="col-sm-2 jett_scrollmenu_in ">
                              <a className="media_fa" href="https://twitter.com/Juniorpersist" target="_blank">
                                <i className=" fa fa-twitter fa-lg"></i>
                              </a>
                            </div>
                            <div className="col-sm-2 jett_scrollmenu_in">
                              <a className="media_fa" href="https://www.linkedin.com/in/junior-bernard-40081a304" target="_blank">
                                <i className=" fa fa-linkedin fa-lg"></i>
                              </a>
                            </div>
                            <div className="col-sm-2 jett_scrollmenu_in">
                              <a className="media_fa" href="https://www.youtube.com/@juniorpersist" target="_blank">
                                <i className=" fa fa-youtube fa-lg"></i>
                              </a>
                            </div>
                            

                            <div className="col-sm-2 jett_scrollmenu_in">
                              <a className="media_fa" href="https://www.tiktok.com/@juniorpersist" target="_blank">
                                <i className="bi bi-tiktok bi-lg"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div className="col-sm-4 mt-2">
                      {/* <div className="new_about_part2_box1 mt-4">
                        <table>
                          <tr>
                            <td> GET FEATURED ON<br /> JUNIOR’S PODCASTS &nbsp;</td>
                            <td><img src={'/images/icons/ayisyenkapab.png'} className="img_7464" /></td>
                          </tr>
                        </table>

                      </div>
                      <Link to={"/Contact#IMPACT"}>
                        <div className="new_about_part2_box2 mt-2">IMPACT ON FIRE (SHOW IN ENGLISH)</div>
                      </Link>
                      <Link to={"/Contact#IMPACT"}>
                        <div className="new_about_part2_box2 mt-2">AYISYEN KAPAB (SHOW IN CREOLE)</div>
                      </Link> */}


                    </div>

                  </div>
                </div>

              </div>


            </div>
          </div>
        </div>

        {/* ================ */}
        <div className="new_about_bar_menu " >
          <center>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-6 py-3">
                  <a href="#JUNIORSPEECH" className="new_about_bar_menu_btn">WATCH JUNIOR'S GRADUATION SPEECH</a>
                </div>
                <div className="col-sm-6 py-3">
                  <a href="https://www.facebook.com/juniorpersist" target="_blank"  className="new_about_bar_menu_btn">EXPLORE JUNIOR'S SOCIAL MEDIA</a>
                </div>
                {/* <div className="col-sm-4 py-3">
                  <Link to={'/'} className="new_about_bar_menu_btn">RESOURCES FOR MEDIA APPEARANCES</Link>
                </div> */}
              </div>
            </div>
          </center>
        </div>



      </div>


      {/* ==============================Part 1_2==================== */}


      <div className="d-flex justify-content-center py-4 cont_about_part2">
        <div className="col-sm-10 about_part2">
          <div className='row px-5 py-4'>

            <div className="col-sm-6 mt-5">
              <div className="part2_text_about_78588 mb-2">PROFESSIONAL BIO:</div>
              <p className="part2_text2_about_78588 opacity-75">
                Junior was born and raised in Haiti and moved to the United States in 2010 following the devastating earthquake that claimed the lives of over 100,000 people. He earned his degree from Alvernia University in December 2014 on a full scholarship.
                <br /><br />
                  His commitment to making a difference has earned him the prestigious Alvernia University’s Four Under Forty Achievement Award, a testament to his philanthropic leadership and the impact he is igniting to boost economic growth in Haiti and inspire individuals from diverse backgrounds.
              </p>
            </div>

            <div className="col-sm-6">
              <div className="content_img_about">
                <img className='pict_part2_about' src={'images/about_pic1.png'} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============================Part 2 */}

   

      {/* ================== */}
      <div id="JUNIORSPEECH" className="new_about_bar2 h3 text-center text-white">WATCH JUNIOR’S COMMENCEMENT SPEECH </div>

      <center>
        <div className='new_about_double_part2 py-3'>
          <div className='py-2'>
            <br />
            <div className='home_new_jett_text1'>PERSIST, PERSIST, PERSIST.</div>
            <div className='home_new_jett_text1'>ALVERNIA UNIVERSITY - DECEMBER 2014</div>
          </div>
          <Video_Junior />
          <br /> <br /> <br /> 
        </div>
      </center>
      {/* ====================== */}
      <div className="pkl_908 mb-5">
        <div className=" d-flex justify-content-center">
          <div className="col-sm-5  book_down2">GAIN EARLY ACCESS TO THE BOOK</div>
        </div>
        <div className="black_bar_book_down"></div>
        <center>
          <div className="book_down_text2  mt-4">Receive exclusive updates and be the first to know when the book is ready to launch!</div>
          <div className="py-3">
            <a href="https://lp.constantcontactpages.com/sl/mt3B9Qg/persistbook" target='_blank' className="btn_home_jett_7864_2 px-3 py-1">SIGN UP NOW</a>
          </div>
        </center>
      </div>
      {/* ==================== */}


   






      <Footer />
    </div>
  )
}


export default About