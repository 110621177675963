import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useNavigate, Link } from 'react-router-dom';
import AudioPlayer from "./AudioPlayer";
function BuyPhysicalBook() {
  const navigate = useNavigate();

  const hardcover_normal = 32.99;
  const hardcover = 28.04;
  const hardcover_inf = 28.04;

  const paperback_normal = 21.99;
  const paperback = 18.69;
  const paperback_inf = 18.69;

  // ==========
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const [isChecked, setIsChecked] = useState(false);



  const handleCheckboxChange2 = (event) => {
    setIsChecked2(event.target.checked);
  };
  const [isChecked2, setIsChecked2] = useState(false);




  const [input, setInput] = useState({
    qty_hardcover: "1",
    name1: "",
    phone1: "",
    email1: "",
    description_hardcover: "",

    street1: "",
    city1: "",
    state_province1: "",
    postal_ZIP_code1: "",
    country1: "",



    // ======

    qty_paperback: "1",
    name2: "",
    phone2: "",
    email2: "",
    description_paperback: "",

    influencer_id: "",
    street2: "",
    city2: "",
    state_province2: "",
    postal_ZIP_code2: "",
    country2: "",
  });

  const handleChange = (e) => {
    let newValue = {
      ...input,
      [e.target.name]: e.target.value,
    };
    setInput(newValue);
  };
  // ============hardcover================


  const [error, setError] = useState("");
  const [error2, setError2] = useState("");

  const CheckHardcover = (e) => {
    e.preventDefault();
    setError("");
    if (input.name1 && input.phone1 && input.email1 && input.street1 && input.city1 && input.state_province1 && input.postal_ZIP_code1 && input.country1) {
      try {



        navigate('/Buy_checkout_no_influencer',
          {
            state: {
              type: "HARDCOVER",
              qty: input.qty_hardcover,
              amount: input.qty_hardcover * hardcover,
              name: input.name1,
              phone: input.phone1,
              email: input.email1,
              description: input.description_hardcover,

              street: input.street1,
              city: input.city1,
              state_province: input.state_province1,
              postal_ZIP_code: input.postal_ZIP_code1,
              country: input.country1
            }
          });


      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Error: Missing information (Add shipping details below)");
    }
  }

  // =============PAPERBACK===============

  const CheckPaperBack = (e) => {
    e.preventDefault();
    setError2("");
    if (input.name2 && input.phone2 && input.email2 && input.street2 && input.city2 && input.state_province2 && input.postal_ZIP_code2 && input.country2) {
      try {

        navigate('/Buy_checkout_no_influencer',
          {
            state: {
              type: "PAPERBACK",
              qty: input.qty_paperback,
              amount: input.qty_paperback * paperback,
              name: input.name2,
              phone: input.phone2,
              email: input.email2,
              description: input.description_paperback,


              street: input.street2,
              city: input.city2,
              state_province: input.state_province2,
              postal_ZIP_code: input.postal_ZIP_code2,
              country: input.country2
            }
          });
      } catch (error2) {
        console.log(error2);
      }
    } else {
      setError2("Error: Missing information (Add shipping details below)");
    }
  }
  return (
    <div>
      {/* <Header /> */}
      <div className="book">
        <center>
          <div className=" slider_book_text1 h2 text-white">START YOUR PRE-ORDER NOW</div>

        </center>
      </div>


      {/* ======================================================= */}
      <div class="mt-3">
        <center>
          <img className="img_time" src={'/images/icons/clock/2.gif'} /><br />
          <div>
            <div className='time_text1'> LIMITED AVAILABILITY!</div>
            <div className='text_tax_buy'>Act now before time runs out!</div>
            <div className="mt-3">
              <a href="#Trailer" className="btn79403">&nbsp;&nbsp;BOOK TRAILER&nbsp;&nbsp;</a>&nbsp;&nbsp;&nbsp;
              <a href="#BookBlurb" className="btn79403">ABOUT THE BOOK</a>

            </div>
          </div>
        </center>
      </div>
      <div className="d-sm-none d-block mb-4"></div>
      {/* ======================================================= */}

      <div className="buy_7585 ">
        <div className="container">
          <div className="row">

            {/*========================PEPERBACK=====================  */}
            <div className="col-sm-6 mb-2">
              <div className="int_cont1_buy ">
                <center>
                  <img className="buy_image001" src={'images/paperback.png'} />
                  <h3 className="py-4 text-white">GET THE PAPERBACK</h3>
                </center>

                <div className="bar_cont1_buy">
                  <center>

                    <div className="text_bar_cont1_buy">
                      <table className="buy_table">
                        <tr>
                          {/* <td><span className="buy_text56">Price:</span> $ {Number(hardcover).toFixed(2)}</td> */}
                          <td><span className="buy_text56">Price: </span>
                          <div className="d-block d-sm-none"></div>
                            {/* <span className="text-decoration-line-through"> ${Number(hardcover).toFixed(2)}</span> */}

                            <span className="text-decoration-line-through"> ${Number(paperback_normal).toFixed(2)}</span>
                            &nbsp; &nbsp;${Number(paperback).toFixed(2)}
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td><span className="buy_text56">Quantity: </span>
                          <div className="d-block d-sm-none"></div>
                          <input className="buy_input_98" name="qty_paperback" value={input.qty_paperback} type="number" onChange={handleChange} /></td>
                        </tr>
                      </table>
                      <div className="text_disc">Great news! You're about to save 10% OFF this order.</div>
                    </div>
                  </center>
                </div>

                <div className="container px-4 pb-3">
                  <div className="cont_cent_buy_7468">
                    <div className="cont2_cent_buy_7468">
                      <table className="table_buy_345">
                        <tr>
                          <td>Subtotal:</td>
                          <td>$ {Number(input.qty_paperback * paperback).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td className="total_buy">Total:</td>
                          <td className="total_buy">$ {Number(input.qty_paperback * paperback).toFixed(2)} USD</td>
                        </tr>
                      </table>
                      <p className="text_tax_buy">Taxes and shipping calculated at checkout</p>
                      {error2 && <div className='error_text'>{error2}</div>}
                      <input className="input_part2_jett2" placeholder='Name' name="name2" type="text" id="name2" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Phone' name="phone2" type="text" id="phone2" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Email Address' name="email2" type="text" id="email2" required="" onChange={handleChange} />

                      <textarea className="input_part2_jett2" placeholder="Order note" name="description_paperback" onChange={handleChange} ></textarea>
                      {/*  ============SHIPPING====================*/}
                      <div>
                        <input id="shipping99" className="form-check-input" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />

                        <label class="form-check-label" for="shipping99">
                          &nbsp; Shipping Address
                        </label>
                      </div>

                      {isChecked && (
                        <div>
                          <input className="input_part2_jett2" placeholder='Street Address' name="street2" type="text" id="street2" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='City' name="city2" type="text" id="city2" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='State/Province' name="state_province2" type="text" id="state_province2" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='Postal/ZIP Code' name="postal_ZIP_code2" type="text" id="postal_ZIP_code2" required="" onChange={handleChange} />
                          <select className="input_part2_jett2" name="country2" id="country2" required onChange={handleChange}>
                            <option value="" disabled selected>Select your country</option>
                            <option value="USA">USA</option>
                            <option value="Canada">Canada</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="France">France</option>
                            <option value="Germany">Germany</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Kenya">Kenya</option>
                          </select>

                        </div>
                      )}

                      {/* ================ */}


                      <div>
                        <center>
                          <div className="mt-2 mb-4">
                            <button onClick={(e) => { CheckPaperBack(e) }} className="btn_buy_now " ><i className="bi bi-lock px-2"></i>PROCEED TO SAFE CHECKOUT</button>
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>




            {/*========================HARDCOVER=====================  */}
            <div className="col-sm-6 mb-2">
              <div className="int_cont1_buy ">
                <center>
                  <img className="buy_image001" src={'images/hardcover.png'} />
                  <h3 className="py-4 text-white">GET THE HARDCOVER</h3>
                </center>

                <div className="bar_cont2_buy py-5">
                  <center>
                    <table className="buy_table">
                      <tr>
                        <td><span className="buy_text56">Price:</span>
                        <div className="d-block d-sm-none"></div>




                          <span className="text-decoration-line-through"> ${Number(hardcover_normal).toFixed(2)}</span>
                          &nbsp; &nbsp;${Number(hardcover).toFixed(2)}
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td><span className="buy_text56">Quantity: </span>
                        <div className="d-block d-sm-none"></div>
                        <input className="buy_input_98" name="qty_hardcover" value={input.qty_hardcover} type="number" onChange={handleChange} /></td>
                      </tr>
                    </table>
                    <div className="text_disc">Great news! You're about to save 10% OFF this order.</div>
                  </center>
                </div>

                <div className="container px-4 pb-3">
                  <div className="cont_cent_buy_7468">
                    <div className="cont2_cent_buy_7468">
                      <table className="table_buy_345">
                        <tr>
                          <td>Subtotal:</td>
                          <td>$ {Number(input.qty_hardcover * hardcover).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td className="total_buy">Total:</td>
                          <td className="total_buy">$ {Number(input.qty_hardcover * hardcover).toFixed(2)} USD</td>
                        </tr>
                      </table>


                      <p className="text_tax_buy">Taxes and shipping calculated at checkout</p>
                      {error && <div className='error_text'>{error}</div>}
                      <input className="input_part2_jett2" placeholder='Name' name="name1" type="text" id="name1" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Phone' name="phone1" type="text" id="phone1" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Email Address' name="email1" type="text" id="email1" required="" onChange={handleChange} />

                      <textarea className="input_part2_jett2" name="description_hardcover" onChange={handleChange} placeholder="Order note"></textarea>
                      {/*  ============SHIPPING====================*/}
                      <div>
                        <input id="shipping99" className="form-check-input" type="checkbox" checked={isChecked2} onChange={handleCheckboxChange2} />

                        <label class="form-check-label" for="shipping99">
                          &nbsp; Shipping Address
                        </label>
                      </div>

                      {isChecked2 && (
                        <div>
                          <input className="input_part2_jett2" placeholder='Street Address' name="street1" type="text" id="street1" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='City' name="city1" type="text" id="city1" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='State/Province' name="state_province1" type="text" id="state_province1" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='Postal/ZIP Code' name="postal_ZIP_code1" type="text" id="postal_ZIP_code1" required="" onChange={handleChange} />
                          <select className="input_part2_jett2" name="country1" id="country1" required onChange={handleChange}>
                            <option value="" disabled selected>Select your country</option>
                            <option value="USA">USA</option>
                            <option value="Canada">Canada</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="France">France</option>
                            <option value="Germany">Germany</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Kenya">Kenya</option>
                          </select>

                        </div>
                      )}

                      {/*  ================================*/}



                      <div>
                        <center>
                          <div className="mt-2">
                            <button onClick={(e) => { CheckHardcover(e) }} className="btn_buy_now2 " ><i className="bi bi-lock px-2"></i>PROCEED TO SAFE CHECKOUT</button>
                          </div>
                        </center>
                      </div>
                    </div>
                    <div className="d-none d-sm-block"><br /></div>
                  </div>
                </div>

              </div>
            </div>



          </div>
        </div>

        <div className="d-none d-sm-block"><br /></div>

        {/* ======================================================= */}
        <div id="Trailer" className="bg-black ">
          <br></br>
          <div className="container col-sm-8 py-4 ">
            <center>
              <div className="jett_scrollmenu">
                <div className="col-sm-6 jett_scrollmenu_in">
                  <div className="text_spread_box006">BOOK TRAILER</div>
                  <div className="video_part_cont video_part_cont_new py-4">
                    <iframe className='iframe_part3' src="https://player.vimeo.com/video/1001246468" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
                <div className="col-sm-6 jett_scrollmenu_in">
                  <div className="text_spread_box006">PRAISES</div>
                  <div className="video_part_cont video_part_cont_new py-4">
                    <iframe className='iframe_part3' src="https://player.vimeo.com/video/957777985" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 m-auto">
                <div className="new_audio0398 px-2 py-2">AUDIO EXCERPT</div>
                <div className="py-2">
                  <AudioPlayer />
                </div>
              </div>

            </center>
          </div>

        </div>



        {/* ===================================== */}
        <div className="px-2 py-4" id="BookBlurb" >
          <div className="col-sm-6 m-auto px-3 py-3 border857" >
            <h3 className="">Book Blurb</h3>
            <hr />
            <div className="sum_book_7645 " id="PERSIST">
              <div className="d-flex align-items-center justify-content-center">
                <div className="col-sm-10 ">
                  <div className="buy_new_1 font_size0948 pt-2">PERSIST, PERSIST, PERSIST.</div>
                  <div className="">
                    <div className="buy_new_2 ">The Haitian Boy, The American, </div>
                    <div className="buy_new_2 ">and The Hidden Lesson</div>
                    <div className="book_part3_text mt-3 ">
                      In a world that often dismisses the pursuit of dreams as a mere luxury, for Junior, it was an all-out war between life and death.
                      <br /><br />
                      Growing up in the heart of poverty in Haiti, a world vividly described in this memoir, Junior embarked on an unprecedented journey—from surviving a heart-wrenching car crash to desperate street begging in the Dominican Republic. Then came the Haiti earthquake, a relentless force that snuffed out over 200k lives in less than 60 seconds.
                      <br /><br />
                      And if you think you've heard it all, think again. What lies ahead in these pages takes “persistence” to a whole new dimension—a story so gripping, so raw, that you need to read it to believe it.
                      <br /><br />
                      "Persist, Persist, Persist" is not just a memoir; it's an electrifying, can't-put-it-down chronicle that immerses you in Junior's thrilling journey and unveils Haiti's narrative in a revolutionary light. A timeless tale of obsession, survival, and self-invention, intricately woven with the unforgiving twists of fate.
                      <br /><br />
                      Brace yourself for a narrative that will make you lose track of time and demand to be shared with everyone you know.
                    </div>
                    <div className="mt-4">
                      <img src={'/images/buy_footer1234.png'} className="persist_img_page8474" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===================================== */}


      </div>

      {/* <Footer /> */}
    </div>
  )
}


export default BuyPhysicalBook