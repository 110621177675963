import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom';
import BookList from "../components/BookList";

function Donate() {




  return (

    <div>
      <Header />


      <div className="slider_donate" >
        <div className="container">
         <BookList />
        </div>
      </div>

      {/* =========================================== */} 

      <br />  <br />  <br />
      <Footer />
    </div>
  )
}


export default Donate