import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
function Buy100() {
  const navigate = useNavigate();


  const ebook = 19.99;
  const paperback = 34.99;

  // ==========
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [isChecked, setIsChecked] = useState(false);




  const [input, setInput] = useState({
    qty_ebook: "100",
    name: "",
    phone: "",
    email: "",
    description_ebook: "",


    // ======

    qty_paperback: "100",
    name2: "",
    phone2: "",
    email2: "",
    description_paperback: "",


    influencer_id: "",
    street: "",
    city: "",
    state_province: "",
    postal_ZIP_code: "",
    country: "",
  });

  const handleChange = (e) => {
    let newValue = {
      ...input,
      [e.target.name]: e.target.value,
    };
    setInput(newValue);
  };
  // ============EBOOK================


  const [error, setError] = useState("");
  const [error2, setError2] = useState("");

  const CheckEbook = (e) => {
    e.preventDefault();
    setError("");
    if (input.name && input.phone && input.email) {
      try {



        navigate('/Buy_checkout_no_influencer',
          {
            state: {
              type: "EBOOK",
              qty: input.qty_ebook,
              amount: input.qty_ebook * ebook,
              name: input.name,
              phone: input.phone,
              email: input.email,
              description: input.description_ebook
            }
          });


      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Error: Missing information");
    }
  }

  // =============PAPERBACK===============

  const CheckPaperBack = (e) => {
    e.preventDefault();
    setError2("");
    if (input.name2 && input.phone2 && input.email2 && input.street && input.city && input.state_province && input.postal_ZIP_code && input.country) {

      try {

        navigate('/Buy_checkout_no_influencer',
          {
            state: {
              type: "PAPERBACK",
              qty: input.qty_paperback,
              amount: input.qty_paperback * paperback,
              name: input.name2,
              phone: input.phone2,
              email: input.email2,
              description: input.description_paperback,

              street: input.street,
              city: input.city,
              state_province: input.state_province,
              postal_ZIP_code: input.postal_ZIP_code,
              country: input.country
            }
          });
      } catch (error2) {
        console.log(error2);
      }
    } else {
      setError2("Error: Missing information (Add shipping details below)");
    }
  }
  return (
    <div>
      <Header />


      <div className="book">
        <center>
          <div className=" slider_book_text1 h3 text-white">START YOUR PRE-ORDER NOW</div>

        </center>
      </div>

  
      {/* ======================================================= */}

      <div className="col-sm-8 m-auto mt-5">
      <div className="text-center text-white h2 new_buy001 col-sm-8 m-auto  py-3">BULK ORDER DISCOUNTS</div>
      <br/>
        <div className="d-none d-sm-block ">
          <div className="row ">
            <div className="col-sm-6 new_buy001_box1 py-3 px-4">
              <div className="">
                <div className="float-end">
                  Buy <strong>3+ hardcover copies</strong> <br />
                  and <strong>SAVE BIG</strong> on shipping!
                </div>
              </div>
            </div>
            <div className="col-sm-6 py-3">
              Buy<strong> 12+ hardcover copies</strong> for friends<br /> and family—get <strong>FREE</strong> shipping!
            </div>
          </div>
        </div>

        <div className="text-center d-block d-sm-none">
          <div className=" new_buy001_box1 py-2">
            Buy <strong>3+ Hardcover copies</strong> <br />
            and <strong>SAVE BIG</strong> on shipping!
          </div>
          <div className="py-2">
            Buy<strong> 12+ Hardcover copies</strong> for friends<br /> and family—get <strong>FREE</strong> shipping!
          </div>
        </div>
      </div>

      {/* ======================================================= */}



      <div className=" ">
        <div className="container">
          <div className="row">

            

            {/*=============================================  */}
            <div className="col-sm-6 py-4">
              <div className="int_cont1_buy ">
                <center>
                  <img className="w-25" src={'images/book/4.png'} />
                  <h3 className="py-4 text-white">GET THE HARDCOVER</h3>
                </center>

                <div className="bar_cont2_buy py-5">
                  <center>
                    <table className="buy_table">
                      <tr>
                        <td><span className="buy_text56">Price:</span> $ {paperback}</td>
                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td><span className="buy_text56">Quantity: </span></td>
                        <td><input className="buy_input_98" name="qty_paperback" value={input.qty_paperback} type="number" onChange={handleChange} /></td>
                      </tr>
                    </table>

                  </center>
                </div>

                <div className="container px-4 pb-3">
                  <div className="cont_cent_buy_7468">
                    <div className="cont2_cent_buy_7468">
                      <table className="table_buy_345">
                        <tr>
                          <td>Subtotal:</td>
                          <td>$ {Number(paperback).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td className="total_buy">Total:</td>
                          <td className="total_buy">$ {Number(input.qty_paperback * paperback).toFixed(2)} USD</td>
                        </tr>
                      </table>
                      <p className="text_tax_buy">Taxes and shipping calculated at checkout</p>
                      {error2 && <div className='error_text'>{error2}</div>}
                      <input className="input_part2_jett2" placeholder='Name' name="name2" type="text" id="nam2e" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Phone' name="phone2" type="text" id="phone2" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Email Address' name="email2" type="text" id="email2" required="" onChange={handleChange} />

                      <textarea className="input_part2_jett2" name="description_paperback" onChange={handleChange} placeholder="Order note"></textarea>

                      {/*  ============SHIPPING====================*/}
                      <div>
                        <input id="shipping99" className="form-check-input" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />

                        <label class="form-check-label" for="shipping99">
                          &nbsp; Shipping Address
                        </label>
                      </div>

                      {isChecked && (
                        <div>
                          <input className="input_part2_jett2" placeholder='Street Address' name="street" type="text" id="street" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='City' name="city" type="text" id="city" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='State/Province' name="state_province" type="text" id="state_province" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='Postal/ZIP Code' name="postal_ZIP_code" type="text" id="postal_ZIP_code" required="" onChange={handleChange} />
                          <input className="input_part2_jett2" placeholder='Country' name="country" type="text" id="country" required="" onChange={handleChange} />

                        </div>
                      )}

                      {/*  ================================*/}
                      <div>
                        <center>
                          <div className="mt-2">
                            <button onClick={(e) => { CheckPaperBack(e) }} className="btn_buy_now2 " ><i className="bi bi-lock px-2"></i>PROCEED TO SAFE CHECKOUT</button>
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>


            {/*=============================================  */}
            <div className="col-sm-6 py-4">
              <div className="int_cont1_buy ">
                <center>
                  <img className="w-25" src={'images/book/1.png'} />
                  <h3 className="py-4 text-white">GET THE EBOOK</h3>
                </center>

                <div className="bar_cont1_buy">
                  <center>

                    <div className="text_bar_cont1_buy">
                      <table className="buy_table">
                        <tr>
                          <td><span className="buy_text56">Price:</span> ${ebook}</td>
                          <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td><span className="buy_text56">Quantity: </span></td>
                          <td><input className="buy_input_98" name="qty_ebook" value={input.qty_ebook} type="number" onChange={handleChange} /></td>




                        </tr>
                      </table>
                    </div>
                  </center>
                </div>

                <div className="container px-4 pb-3">
                  <div className="cont_cent_buy_7468">
                    <div className="cont2_cent_buy_7468">
                      <table className="table_buy_345">
                        <tr>
                          <td>Subtotal:</td>
                          <td>$ {Number(ebook).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td className="total_buy">Total:</td>
                          <td className="total_buy">$ {Number(input.qty_ebook * ebook).toFixed(2)} USD</td>
                        </tr>
                      </table>
                      <p className="text_tax_buy">Taxes and shipping calculated at checkout</p>
                      {error && <div className='error_text'>{error}</div>}
                      <input className="input_part2_jett2" placeholder='Name' name="name" type="text" id="name" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Phone' name="phone" type="text" id="phone" required="" onChange={handleChange} />
                      <input className="input_part2_jett2" placeholder='Email Address' name="email" type="text" id="email" required="" onChange={handleChange} />

                      <textarea className="input_part2_jett2" placeholder="Order note" name="description_ebook" onChange={handleChange} ></textarea>
                      <div>
                        <center>
                          <div className="mt-2 mb-4">
                            <button onClick={(e) => { CheckEbook(e) }} className="btn_buy_now " ><i className="bi bi-lock px-2"></i>PROCEED TO SAFE CHECKOUT</button>
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>








          </div>
        </div>



        {/* ======================================================= */}




        {/* ===================================== */}


        <center>
          <div className="bg-black mt-5">
            <div className="col-sm-4 py-5">
              <div className="">
                <img src={"/images/payment_type.png"} className="payment_image_accept" />
              </div>
            </div>
          </div>
        </center>


      </div>
      {/* ===================================== */}

      <div className="last_payment_page984 py-3 mb-2">
        <center>
          <div className="col-sm-10">
            <img src={'/images/buy_footer123.png'} className="img_buy_footer123" />
          </div>
        </center>
      </div>



      <Footer />
    </div>
  )
}


export default Buy100