const reviews = [
  {
    id: 1,
    name: 'Dana',
    // job: 'web developer',
    image:
      'https://juniorpersist.com/images/supporters/B_DANA.png',
    text:
      '"This book is an absolute masterpiece. I love every bit of it!"',
  },
  {
    id: 2,
    name: 'Mansi',
    // job: 'web designer',
    image:
      'https://juniorpersist.com/images/supporters/3.jpeg',
    text:
      '"Reading this book feels like I am watching a movie. It`s such a powerful read."',
  },
  {
    id: 3,
    name: 'Serenity',
    // job: 'intern',
    image:
      'https://juniorpersist.com/images/supporters/new/3.png',
    text:
      '"If you have a dream you`re fighting for, this book will get you to the finish line."',
  },
  {
    id: 4,
    name: 'Pastor Tony',
    // job: 'the boss',
    image:
      'https://juniorpersist.com/images/supporters/new/5.png',
    text:
      '"What an amazing story. What a testimony!"',
  },
  {
    id: 5,
    name: 'Prof. Kasturi',
    // job: 'the boss',
    image:
      'https://juniorpersist.com/images/supporters/new/6.png',
    text:
      '"This book should be in every school and college. It should be read by everyone."',
  },
  {
    id: 6,
    name: 'Rose',
    // job: 'the boss',
    image:
      'https://juniorpersist.com/images/supporters/R_FLORE.png',
    text:
      '"So many young people are lost. This book could be the key to restoring countless lives."',
  },







  {
    id: 7,
    name: 'Bernardo',
    // job: 'the boss',
    image:
      'https://juniorpersist.com/images/supporters/B_Bernardo.png',
    text:
      '"When you read this book, you`ll learn to never take time for granted."',
  },
  {
    id: 8,
    name: 'Carlos',
    // job: 'the boss',
    image:
      'https://juniorpersist.com/images/supporters/C_CARLOS.png',
    text:
      '"Persist, Persist, Persist. is impossible to put down!"',
  },
  {
    id: 9,
    name: 'Billy',
    // job: 'the boss',
    image:
      'https://juniorpersist.com/images/supporters/B_WILLIAM.png',
    text:
      '"The chapters are well organized. It`s a rapid and very impactful read."',
  },
  {
    id: 10,
    name: 'Philippe',
    // job: 'the boss',
    image:
      'https://juniorpersist.com/images/supporters/new/11.png',
    text:
      '"I highly recommend this book to every 18-year-old out there. It will change your life."',
  },
  {
    id: 11,
    name: 'Valerina',
    // job: 'the boss',
    image:
      'https://juniorpersist.com/images/supporters/new/12.png',
    text:
      '"Guys, hurry up and get this book. It`s amazing!"',
  },
  {
    id: 12,
    name: 'Andrew',
    // job: 'the boss',
    image:
      'https://juniorpersist.com/images/supporters/new/13.png',
    text:
      '"If you`re feeling lost and stuck in life, grab this book ASAP!"',
  },
  {
    id: 13,
    name: 'Justin',
    // job: 'the boss',
    image:
      'https://juniorpersist.com/images/supporters/new/15.png',
    text:
      '"This book can inspire millions. It`s that deep!"',
  },
  {
    id: 14,
    name: 'Robert',
    // job: 'the boss',
    image:
      'https://juniorpersist.com/images/supporters/new/16.png',
    text:
      '"This book is evidence that there`s light at the end of the tunnel."',
  },
];

export default reviews;