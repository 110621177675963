import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import '../css/read.css';
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Video_Junior from "../Video_Junior";
import Board_members from "../Board_members";
import Video_student from "../Video_student";
import Slider_double from "../Slider_double";
import AudioPlayer from "./AudioPlayer";

function Reviewer() {


	// =================================
	return (
		<div>
			<Header />



			{/* <div className="main-wrapper read_jett_font_family">
				<div className="book px-3 px-lg-5">
					<div className=" container d-flex align-items-center justify-content-center">
						<div className="row col-sm-10">
							<div className="col-sm-4">
								<div className="left_content">
									<img className='slider_book_imager ' src={"/images/book/3.png"} />
								</div>
							</div>
							<div className="col-sm-8 align-self-center mt-5">
								<div className="mt-4 slider_book_text3">BOOK RELEASE DATE: OCTOBER 15, 2024</div>
								<div className="slider_book_text4 py-2">Chapter Preview: Strange Happenings</div>
								<div className="slider_book_text5 col-sm-10">
									In a world that often dismisses the pursuit of dreams as a mere luxury, for Junior, it was an all-out war between life and death. Growing up in the heart of poverty in Haiti...
								</div>
								<div className="col-sm-10">
									<div className="py-3">

										<div className="py-3">
											<div className="title_rev90">
												<img src={'/reviewer/Jbern.png'} className='img_revw' /> LISTEN NOW
											</div>
										</div>
										<AudioPlayer />
									</div>
								</div>


								<br /><br />
							</div>
						</div>
					</div>
				</div>
			</div> */}


			{/* ==============================Part 1_2==================== */}


			<div className="resume_book space0098">
				<center>
					<div className="col-sm-5">
						<div className='blog_scrol_jett'>
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/png/reviewer-1.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/png/reviewer-2.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/png/reviewer-3.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/png/reviewer-4.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/png/reviewer-5.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/png/reviewer-6.png'} />
							<img className="image_book_mobile jett_scrollmenu_in" src={'/reviewer/png/reviewer-7.png'} />
						</div>
					</div>
				</center>
			</div>






			{/* =========================Part 3============================ */}
			<div className="py-5">
				<div className=" d-flex justify-content-center">
					<div className="col-sm-8  book_down2">JUNIOR’S COMMENCEMENT SPEECH</div>
				</div>
				<div className="black_bar_book_down"></div>
				<center>
					<div className="h6 mt-4 py-3">ALVERNIA UNIVERSITY - DECEMBER 2014</div>

					<Video_Junior />

				</center>
			</div>
			{/* =========================Part============================ */}






			<div className="mb-5">
				<div className=" d-flex justify-content-center">
					<div className="col-sm-4  book_down2">TAKE ACTION</div>
				</div>
				<div className="black_bar_book_down"></div>
				<center>
					{/* <div className="book_down_text2 col-sm-4 mt-4 py-2">Join the fun and make your voice count!</div> */}
					<div className="py-3 mt-4">
						<a href="/BuyDetails" className="btn_buy_book4 px-4">
							PRE-ORDER NOW
							<i class="bi bi-arrow-up-right-square-fill px-2"></i>
						</a>
					</div>
				</center>
			</div>

			{/* =========================Part ============================ */}





			<Footer />
		</div>
	)
}


export default Reviewer