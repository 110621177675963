import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function GeneralTerms() {


  return (
    <div>
      <Header />
      <div className="contact_page ">
        <div className=" container py-5">

          <div className="col-sm-8 m-auto py-3 jett_general009">

            <h3>Privacy Policy for Juniorpersist.com.</h3>
            <h6>Effective Date: September 8, 2024</h6>

            At Juniorpersist.com, accessible from https://juniorpersist.com and our apps available through Google Play and App Store, the privacy of our visitors is a priority. This privacy policy document outlines the types of information that are collected, how we use it, and how we protect it in compliance with our partners’ User Data Policy.
            <br /><br />

            <ol>
              <li className="li_gen009">
                <strong>Information We Collect</strong> <br />
                We may collect the following types of data when you interact with our website or app:
                <br />
                <ul className="bullte_jett003">
                  <li><strong>Personal Information:</strong> When you voluntarily provide it, such as your name, email address, or any other personal details when contacting us or subscribing to newsletters.</li>
                  <li><strong>Non-Personal Information:</strong> Automatically collected data such as your IP address, browser type, and interaction data (e.g., pages visited). </li>
                </ul>
              </li>

              <li className="li_gen009">
                <strong>How We Use Your Information</strong><br />
                The data we collect is used in the following ways:
                <br />
                <ul className="bullte_jett003">
                  <li>To provide, operate, and maintain our website and services.</li>
                  <li>To communicate with you, including responding to inquiries, newsletters, or promotional content if you opt in.</li>
                  <li>To analyze and improve our website's functionality and user experience.</li>
                </ul>
                We do not collect any personal or sensitive user data via the app beyond what is necessary for the app's basic operation and the app experience.
              </li>

              <li className="li_gen009">
                <strong>Sharing of Information</strong> <br />
                Juniorpersist.com does not share or sell any personal or sensitive user data with third parties, except under the following conditions:
                <ul className="bullte_jett003">
                  <li>If required by law, in response to legal requests or government investigations.</li>
                  <li>To protect the rights, privacy, safety, and property of our company or users.</li>
                </ul>
              </li>

              <li className="li_gen009">
                <strong>Data Security</strong><br />
                We implement appropriate technical and organizational measures to protect the personal information collected. While no system can be guaranteed as 100% secure, we strive to use commercially acceptable means to protect your personal data.
              </li>

              <li className="li_gen009">
                <strong>Data Retention and Deletion</strong><br />
                We retain personal data only for as long as necessary to fulfill the purposes outlined in this privacy policy or to comply with legal obligations. Upon request, we will delete your personal data in accordance with applicable law.
              </li>

              <li className="li_gen009">
                <strong>Your Rights</strong><br />
                You have the right to request access, modification, or deletion of the personal data we hold. To exercise your rights, please contact us at the email below.
              </li>

              <li className="li_gen009">
                <strong>Contact Information</strong><br />
                If you have any questions about this privacy policy or the data we collect, please contact us at:
                Email: contact@juniorpersist.com
              </li>

              <li className="li_gen009">
                <strong>Changes to This Privacy Policy</strong><br />
                We may update this privacy policy from time to time. Any changes will be posted on this page with an updated revision date.
              </li>

            </ol>

            <br/>
            <hr />

            <h3>Terms of Use</h3>
            These terms and conditions (collectively, the “Terms of Use”) govern your use of any websites Persist, Persist, Persist. (“Persist,” “we,” “our,” or “us”) owns or operates, including www.juniorpersist.com (the “Site”), your purchase of any of our products or services, or your access to or use of any of our other offerings (collectively, the “Services”). We may change these Terms of Use from time to time, at any time, without providing notice to you, by posting such changes on the Site. BY USING ANY OF THE SERVICES, YOU ACCEPT AND AGREE THAT THESE TERMS OF USE WILL APPLY TO YOUR USE OF SUCH SERVICES. If you do not agree to these Terms of Use, you may not access or otherwise use the Services.
            <br /><br />
            Proprietary Rights. As between you and us, we own, solely and exclusively, all rights, title, and interest in and to the Services; all the content (including, without limitation, audio, text, photographs, illustrations, graphics, visuals, video, copy, and/or software), data, and materials made available through the Services (collectively, the “Content”); the look, feel, design, and organization of the Services; and the compilation of any Content on the Services, including, but not limited to, any copyrights, trademark rights, patent rights, database rights, moral rights, sui generis rights, and other intellectual property and proprietary rights therein. Your use of the Services does not grant to you any ownership of any Content you may access on the Services.
            <br /><br />
            Limited License. Subject to these Terms of Use and your compliance therewith, we grant you a personal, non-exclusive, non-transferable, non-sublicensable, limited right to access, view, download, play, listen to, display, and/or use, as applicable, the Content solely for your personal, non-commercial use. You acknowledge and agree that no title or ownership in the Content is being transferred or assigned to you, and that we, our licensors, and/or our authors retain all right, title, and interest in the Content, including, but not limited to, all copyrights, trademark rights, trade names, audio files, video files, artwork, and any other intellectual property or proprietary rights therein. All rights not expressly granted under these Terms of Use are reserved by us, our licensors, and/or our authors, as applicable.
            <br /><br />
            Prohibited Use. Any commercial downloading, displaying, copying, recording, reproduction, alteration, modification, distribution, publishing, or exploitation of the Services or any Content is strictly prohibited, unless you have received our express written prior permission or the express written prior permission of the applicable rights holder to do so. You may not download, display, copy, record, reproduce, alter, modify, distribute, publish, perform, transfer, create derivative works from, sell, or otherwise exploit any Services or Content other than as expressly set forth in these Terms of Use. Any violation of the foregoing sentence may constitute a violation of copyright or other local, state, national, and international laws, and doing so may subject you to liability for any such unauthorized use of the Services or Content. We will aggressively enforce our intellectual property rights to the fullest extent of the law, including the seeking of criminal prosecution.
            <br /><br />
            Trademarks. The trademarks, trade names, logos, and service marks (collectively, the “Trademarks”) displayed on any Services or Content are registered and unregistered Trademarks of us and others and may not be used unless authorized by the applicable Trademark owner. All Trademarks not owned by us that appear on or through the Services are the property of their respective owners. Nothing contained in the Services should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Trademark displayed in the Services without our written permission or that of the applicable Trademark holder. Your misuse of the Trademarks displayed in the Services and Content is strictly prohibited. We will aggressively enforce our Trademark rights to the fullest extent of the law, including the seeking of criminal prosecution.
            <br /><br />
            User Information. While using the Services, you may be asked to provide certain personalized information to us (“User Information”). Our information collection and use policies with respect to the privacy of such User Information are set forth in our Privacy Policy , which is incorporated herein by reference. You acknowledge and agree that you are solely responsible for the accuracy and content of User Information, and you acknowledge and agree that you will keep your User Information up-to-date.
            <br /><br />
            Submitted Materials. Unless specifically requested, we do not solicit, nor do we wish to receive, any confidential, secret, or proprietary information or other material from you through the Services, by email, or in any other way. Any information, creative works, demos, ideas, suggestions, concepts, methods, systems, designs, plans, techniques, or other materials submitted or sent to us (“Submitted Materials”) will be deemed not to be confidential or secret and may be used by us in any manner consistent with these Terms of Use and our Privacy Policy . Notwithstanding anything to the contrary in these Terms of Use, if you do submit or send Submitted Materials to us, you (i) represent and warrant that (A) the Submitted Materials are original to you, that no other party has any rights thereto, and that any “moral rights” in Submitted Materials have been waived; and (B) that the Submitted Materials, and their use, do not and will not violate the law or violate or infringe the rights of any entity or person; and (ii) grant us and our affiliates a royalty-free, unrestricted, worldwide, perpetual, irrevocable, non-exclusive, fully transferable, fully assignable, and fully sublicensable right and license to use, copy, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and/or display such Submitted Materials (in whole or part) and/or to incorporate them in other works in any form, media, or technology now known or later developed. We cannot be responsible for maintaining any Submitted Materials that you provide to us, and we may delete or destroy any Submitted Materials at any time. This Section 6 does not apply to manuscripts submitted through the Orbit Works submission process.
            <br /><br />
            Your Representations and Warranties. You represent and warrant that, while using the Services, you will not (i) impersonate any person or entity or misrepresent your affiliation with any other person or entity; (ii) insert your own or a third party’s advertising, branding, or other promotional content into any Services or Content (for example, without limitation, in an RSS feed or a podcast received from us or otherwise through the Services) or use, redistribute, republish, or exploit any such Service or Content for any of your commercial or promotional purposes; (iii) gain or attempt to gain unauthorized access to computer systems, materials, or information through the Services; (iv) engage in spidering, “screen scraping,” “database scraping,” harvesting of email addresses, wireless addresses, or other contact or personal information, or any other automatic means of accessing, logging-in, or registering on any Services, or obtaining or accessing lists of users or other information or features on, from, or through any Services, including, without limitation, any information residing on any server or database connected to the Services; (v) use the Services in any manner with the intent to interrupt, damage, disable, overburden, or impair the Services, including, without limitation, sending mass unsolicited messages or “flooding” servers with requests; (vi) use the Services in violation of our or any third party’s intellectual property or other proprietary, personal, or legal rights; (vi) use any Services in violation of any applicable law; (vii) attempt (or encourage or support anyone else’s attempt) to circumvent, reverse engineer, decrypt, or otherwise alter or interfere with the Services or Content or make unauthorized use thereof; or (viii) interfere with any other party’s use and enjoyment of any Service.
            <br /><br />
            Public Forums and Blogs. We may, from time to time, make messaging services, chat services, bulletin boards, message boards, blogs, other forums, and similar services available on or through the Services. In addition to any other rules or regulations that we may post in connection with a particular Service, you agree that you will not upload, post, transmit, distribute, or otherwise publish through the Services any materials which (i) are fraudulent, unlawful, threatening, abusive, harassing, libelous, defamatory, obscene, vulgar, offensive, pornographic, profane, sexually explicit, or indecent, (ii) constitute or encourage conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any local, state, national, or international law, (iii) violate, plagiarize, or infringe the rights of third parties, including, without limitation, copyright, trademark, trade secret, confidentiality, contract, patent, rights of privacy or publicity, or any other proprietary right, (iv) contain a virus, spyware, or other harmful component, (v) contain embedded links, advertising, chain letters, or pyramid schemes of any kind, or (vi) constitute or contain false or misleading indications of origin, endorsement, or statements of fact. You further agree not to impersonate any other person or entity, whether actual or fictitious, including anyone at Persist. You alone are responsible for the content and consequences of any of your activities.
            <br /><br />
            Right to Monitor and Editorial Control. We reserve the right, but do not have an obligation, to monitor and/or review materials, in whole or in part, posted to the Services by users, and we are not responsible for any such materials posted by users. However, we reserve the right, at all times, to disclose any information as necessary to satisfy any law, regulation, or government request, or to edit, refuse to post, or remove any information or materials, in whole or in part, that, in our sole discretion, are objectionable or in violation of these Terms of Use, our policies, or applicable law. We may also impose limits on certain features of the Services or restrict your access to part or all of the Services without notice or penalty if we believe that you are in breach of these Terms of Use or applicable law or for any other reason, in our sole discretion, without notice or liability.
            <br /><br />
            Termination. We may terminate these Terms of Use at any time without notice, in our sole discretion. Upon any such termination, you must destroy all materials obtained from the Services and all copies thereof, whether made under these Terms of Use or otherwise. The provisions of these Terms of Use which by their nature should survive the termination of these Terms of Use shall survive such termination. You agree that we shall not be liable to you or any third party for any termination of your access to the Services or to any such information or files, and shall not be required to make such information or files available to you after any such termination.
            <br /><br />
            Disclaimer of Warranties. TO THE FULLEST EXTENT PERMITTED BY LAW, THE SERVICES ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES OR THE SERVERS THAT MAKE SUCH SERVICES AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE SERVICES OR MATERIALS ON THE SERVICES, IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION RELATING TO YOUR USE OF THE SERVICES. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
            <br /><br />
            Limitation of Liability. UNDER NO CIRCUMSTANCES, INCLUDING, WITHOUT LIMITATION, NEGLIGENCE, SHALL WE BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE SERVICES OR ANY MATERIALS ON THE SERVICES, EVEN IF WE OR AN AUTHORIZED REPRESENTATIVE OF OURS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE) EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING OR USING THE SERVICES. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
            <br /><br />
            Indemnification. You agree to defend, indemnify, and hold us harmless from and against any and all claims, damages, costs, and expenses, including attorneys’ fees, arising from or related to (i) your use of the Services, including, without limitation, your purchase of our products or services; (ii) any User Information or Submitted Materials you provide; (iii) your violation of these Terms of Use or any applicable law; or (iv) your violation of any rights of a third party.
            <br /><br />
            Governing Law; Jurisdiction. These Terms of Use shall be governed by and construed in accordance with the laws of the State of Texas, without regard to its conflicts of law principles. You hereby consent and agree to submit to the exclusive jurisdiction of the courts located in Texas, in all disputes arising out of or relating to the use of the Services.
            <br /><br />
            General. These Terms of Use represent the entire understanding between you and us regarding the Services and supersede all prior agreements and understandings regarding the Services. Our failure to enforce any provision of these Terms of Use shall not be deemed a waiver of such provision nor of the right to enforce such provision. If any provision of these Terms of Use is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms of Use shall remain in full force and effect. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Services or these Terms of Use must be filed within one (1) year after such claim or cause of action arose or be forever barred. The section titles in these Terms of Use are for convenience only and have no legal or contractual effect. All provisions in these Terms of Use regarding representations and warranties, indemnification, disclaimers, and limitations on liability shall survive any termination of these Terms of Use.
            <br /><br />
            Contact. If you have any questions regarding these Terms of Use, please contact us by email at contact@juniorpersist.com.







          </div>

          {/* ================ */}
        </div>






      </div>
      <Footer />
    </div>
  )
}


export default GeneralTerms

