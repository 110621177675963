import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function Login() {

  const [error,setError] = useState("")
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function login(e) {

    e.preventDefault();
    setError("")
    if(email && password){

    console.warn("data", email, password)


    try {
    let item = { email, password }
    let result = await fetch('http://127.0.0.1:8000/api/login', {
    // let result = await fetch('https://juniorpersist.com/backend/public/api/login', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(item)
    });
    result = await result.json();
    localStorage.setItem('user-info', JSON.stringify(result));
    navigate('/')
 


    .then(response => {
      console.log("response", response);
    }).catch(error => {
      console.log("some error occurred", error);
    });



} catch (error) {
  console.log(error);
}
}else{
  setError("Error: all fields required");
}
}

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('user-info')) {
      navigate('/')
    }
  })
  return (
    <div>
      <Header />
      <div className="wrapper fadeInDown mt-2">
        <div id="formContent">

          <div className="fadeIn first">
            <img className='image_login' src={"./images/login.jpeg"} />
          </div>
          <div className="py-3 mt-2">
                  <h4 className="create_text1_jett">ACCESS TO YOUR ACCOUNT</h4>
                  <h6 className="create_text2_jett px-4">Enter your email address and password to access your account.</h6>
          </div>

                  <hr />

          <div className="mt-4 mb-2">
            <div className="form_login">
            {error && <div className='error_text'>{error}</div>}
              <input type="email" id="login" className="fadeIn second" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
              <input type="password" id="password" className="fadeIn third" placeholder="password" onChange={(e) => setPassword(e.target.value)} />
             
             <div className="py-4">
              <input type="submit" className="fadeIn fourth" value="Log In" onClick={(e)=>login(e)} />
             
             </div>
            </div>

            {/* <div className="mb-3">
              <a className="text-center forget_test " href="#">Forgot Password?</a>
            </div> */}

          </div>

          <div id="formFooter">
            <div className="text-center">
              <Link className="log_jett_text1" to="../register">
                Don't have an account yet? <span className="log_jett_text2">Create an account </span>
              </Link>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  )
}


export default Login
