import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function Contact() {


  return (
    <div>
      <Header />
      <div className="contact_page">
        <div className="about_slider_page_jett22 ">

          <div className="row py-3">
            <div className="col-sm-7">
              <center>
                <img src={'images/img/contact_page.png'} className="img_about9857" />
              </center>
            </div>
            <div className="col-sm-5   align-self-center">
              <div className="text_slider ">

                <div className="new_contact py-5">
                  <div className="new_about_part1">
                    <div className="new_about_text1">CONTACT JUNIOR</div>
                    <div className="new_about_text2 ">Get in contact with us regarding booking enquiries, events, media appearances, resources and more using the links below.</div>
                  </div>

                  <div className="new_about_part2 mt-3">
                    <div className="row">
                      <div className="col-sm-10 py-2">
                        <span className="">

                          <div className="h4 text-white">NEVER MISS A THING!</div>
                          <a href='https://lp.constantcontactpages.com/sl/mt3B9Qg/persistbook' target="_blank">
                            <div className="contact_box1 mt-2">SIGN UP FOR OUR NEWSLETTER</div>
                          </a>
                          <Link to={'/Speaking'}>
                            <div className="new_about_part2_box1 mt-2">BOOK JUNIOR FOR YOUR NEXT EVENT</div>
                          </Link>
                          <div className="new_about_part2_box1 mt-2">
                            <div className=" jett_scrollmenu">

                              <div className="col-sm-2 jett_scrollmenu_in">
                                <a className="media_fa" href="https://www.facebook.com/juniorpersist" target="_blank">
                                  <i className=" fa fa-facebook fa-lg"></i>
                                </a>
                              </div>
                              <div className="col-sm-2 jett_scrollmenu_in">
                                <a className="media_fa" href="https://www.instagram.com/jrpersist/" target="_blank">
                                  <i className=" fa fa-instagram fa-lg "></i>
                                </a>
                              </div>
                              

                              <div className="col-sm-2 jett_scrollmenu_in ">
                                <a className="media_fa" href="https://twitter.com/Juniorpersist" target="_blank">
                                  <i className=" fa fa-twitter fa-lg"></i>
                                </a>
                              </div>
                              <div className="col-sm-2 jett_scrollmenu_in">
                                <a className="media_fa" href="https://www.linkedin.com/in/junior-bernard-40081a304" target="_blank">
                                  <i className=" fa fa-linkedin fa-lg"></i>
                                </a>
                              </div>
                              <div className="col-sm-2 jett_scrollmenu_in">
                                <a className="media_fa" href="https://www.youtube.com/@juniorpersist" target="_blank">
                                  <i className=" fa fa-youtube fa-lg"></i>
                                </a>
                              </div>
                              <div className="col-sm-2 jett_scrollmenu_in">
                              <a className="media_fa" href="https://www.tiktok.com/@juniorpersist" target="_blank">
                                <i className="bi bi-tiktok bi-lg"></i>
                              </a>
                            </div>
                            </div>
                          </div>
                        </span>
                      </div>


                    </div>
                  </div>

                </div>


              </div>
            </div>
          </div>

          {/* ================ */}
          <div className="new_about_bar_menu ">
            <center>
              <div className="col-sm-8 ">

                <div className="row">
                  <div className="col-sm-2 py-3"> </div>
                  <div className="col-sm-4 py-3">
                    <a href="#CONTACT" className="new_about_bar_menu_btn">CONTACT JUNIOR</a>
                  </div>
                  {/* <div className="col-sm-4 py-3">
                    <a href="#SERVICES" className="new_about_bar_menu_btn">OUR SERVICES</a>
                  </div> */}
                  <div className="col-sm-4 py-3">
                    <a href="https://www.facebook.com/juniorpersist" target="_blank" className="contact_btn1">ENGAGE WITH JUNIOR ONLINE!</a>
                  </div>
                  <div className="col-sm-2 py-3"> </div>
                </div>
              </div>
            </center>
          </div>



        </div>


        {/* ==============================Part 1_2==================== */}


        <div id="CONTACT" className="d-flex justify-content-center py-4 cont_about_part2">
          <div className="col-sm-11 about_part2">
            <center>
              <div className='row px-5 py-4 mb-4 mt-4'>

                <div className="col-sm-3 py-3">
                  <div className="contact_part2_box1">
                    <h5 className="text-white">SPEAKING OPPORTUNITIES</h5>
                    <div className="contact_part2_text1">
                      Invite Junior to speak at your next event and inspire your audience!
                    </div>
                    <div className="contact_part2_text1 mt-2 email_contact009">
                      Email: speaking@juniorpersist.com
                    </div>
                  </div>
                </div>

                <div className="col-sm-3 py-3">
                  <div className="contact_part2_box1">
                    <h5 className="text-white">PRESS</h5>
                    <div className="contact_part2_text1">
                      For all interviews, media opportunities, and anything else press-related:
                    </div>
                    <div className="contact_part2_text1 mt-2 email_contact009">
                      Email: press@juniorpersist.com
                    </div>
                  </div> 
                </div>

                <div className="col-sm-3 py-3">
                  <div className="contact_part2_box1">
                    <h5 className="text-white">BOOK ORDERS</h5>
                    <div className="contact_part2_text1">
                      Do you have book-related questions? Contact us today!
                    </div>
                    <div className="contact_part2_text1 mt-2 email_contact009">
                      Email: sales@juniorpersist.com
                    </div>
                  </div>
                </div>

                <div className="col-sm-3 py-3">
                  <div className="contact_part2_box2">
                    <h5 className="text-white">GENERAL INQUIRIES</h5>
                    <div className="contact_part2_text1">
                      Feel free to reach out about anything that interests you using the email below.
                    </div>
                    <div className="contact_part2_text1 mt-2 email_contact009">
                      Email: contact@juniorpersist.com
                    </div>
                  </div>
                </div>

              </div>
            </center>
          </div>
        </div>
        {/* ============================Part */}
        {/* <div id="SERVICES" className="px-2 py-3">
          <div className="contact_part3_text1">EXPLORE ALL</div>
          <div className="contact_part3_text2 py-2 mb-5">Explore the websites below to discover more about Junior’s work and  engage with the Pranzel community!</div>

          <center>
            <div className="contact_part3_box1 mt-4 col-sm-8">
              <div className="row">

                <div className="col-sm-3">
                  <div className="contact_part3_box2">
                    <img src={'/images/logo_all_pages/logo_elite_school.png'} className="contact_part3_img_logo"></img>
                  </div>
                </div>
                <div className="col-sm-9">
                  <div className="contact_part3_box3">
                    <div className="contact_part3_text2 text_jett9874537"> We teach the in-demand skills necessary to take<br /> Haitians from beginner to industry-ready in 18 months.</div>

                    <a href="https://pranzeleliteschool.com/" target="_blank" className="contact_part3_btn1">VISIT WEBSITE</a>
                  </div>
                </div>

              </div>
            </div>

            <div className="contact_part3_box1 mt-4 col-sm-8">
              <div className="row">

                <div className="col-sm-3">
                  <div className="contact_part3_box2">
                    <img src={'/images/logo_all_pages/pranzel_inspiration.png'} className="contact_part3_img_logo"></img>
                  </div>
                </div>
                <div className="col-sm-9">
                  <div className="contact_part3_box3">
                    <div className="contact_part3_text2 text_jett9874537 ">Support education for youth nationwide by shopping<br /> Haiti-themed apparel.</div>
                    <a href="https://pranzelinspiration.com/" target="_blank" className="contact_part3_btn1">VISIT WEBSITE</a>
                  </div>
                </div>

              </div>
            </div>

            <div className="contact_part3_box1 mt-4 col-sm-8">
              <div className="row">

                <div className="col-sm-3">
                  <div className="contact_part3_box2">
                    <img src={'/images/logo_all_pages/pranzel_blog.png'} className="contact_part3_img_logo"></img>
                  </div>
                </div>
                <div className="col-sm-9">
                  <div className="contact_part3_box3">
                    <div className="contact_part3_text2 text_jett9874537">Discover Pranzel Elite School's Initiatives and Our Efforts<br /> to Unite People in Support of Haiti.</div>
                    <a href="https://www.pranzelblog.com/" target="_blank" className="contact_part3_btn1">VISIT WEBSITE</a>
                  </div>
                </div>

              </div>
            </div>
          </center>
        </div> */}
        {/* ============================Part4  */}

        {/* <div className="py-4 contact_text10 mt-5" id="IMPACT">
          ENGAGE WITH JUNIOR ON ONLINE!
        </div>


        <div className="podcast_part3">
          <center>
            <div className="podcast_part3_box1_other col-sm-5">LISTEN TO THE IMPACT ON FIRE PODCAST</div>
            <img src={'/images/icons/ayisyenkapab2.png'} className="img_podcast2 py-4" />
          </center>
        </div> */}




        {/* <center>
          <div className="row bg-black col-sm-5">
            <div className="col-sm-4">
              <div className="podcast_part3_text1">
                <a className="bi_jett" href="https://open.spotify.com/show/7h4HLkd04yXXiMFeiYSJHH" target="_blank">
                  <table>
                    <tr>
                      <td><i className="bi bi-spotify"></i></td>
                      <td><div className="podcast_part3_text2 px-2">SPOTIFY</div></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="podcast_part3_text1">
                <a className="bi_jett" href="https://podcasts.apple.com/us/podcast/impact-on-fire/id1723329946" target="_blank">
                  <table>
                    <tr>
                      <td><i className="bi bi-broadcast-pin"></i></td>
                      <td><div className="podcast_part3_text2 px-2">APPLE</div></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="podcast_part3_text1">
                <a className="bi_jett" href="https://www.youtube.com/@juniorpersist" target="_blank">
                  <table>
                    <tr>
                      <td><i className="bi bi-youtube"></i></td>
                      <td><div className="podcast_part3_text2 px-2">YOUTUBE</div></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>
          </div>
        </center> */}

        {/* ============================Part 2 */}
        {/* <center>
          <div className="col-sm-4 py-4 mt-2">
            <div className="new_about_double_box_1_text1 text-black">Junior's Social Media in English</div>
            <div className="new_about_part2_box1 mt-1 py-3">
              <div className="d-flex justify-content-center jett_scrollmenu">

                <div className="col-sm-2 jett_scrollmenu_in">
                  <a className="media_fa" href="https://www.facebook.com/juniorpersist" target="_blank">
                    <i className=" fa fa-facebook fa-lg"></i>
                  </a>
                </div>
                <div className="col-sm-2 jett_scrollmenu_in">
                  <a className="media_fa" href="https://www.instagram.com/juniorpersist" target="_blank">
                    <i className=" fa fa-instagram fa-lg"></i>
                  </a>
                </div>
                <div className="col-sm-2 jett_scrollmenu_in">
                  <a className="media_fa" href="https://twitter.com/Juniorpersist" target="_blank">
                    <i className=" fa fa-twitter fa-lg"></i>
                  </a>
                </div>
                <div className="col-sm-2 jett_scrollmenu_in">
                  <a className="media_fa" href="https://www.linkedin.com/in/junior-bernard-815825146/" target="_blank">
                    <i className=" fa fa-linkedin fa-lg"></i>
                  </a>
                </div>
                <div className="col-sm-2 jett_scrollmenu_in">
                  <a className="media_fa" href="https://www.youtube.com/@juniorpersist" target="_blank">
                    <i className=" fa fa-youtube fa-lg"></i>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </center> */}
        {/* ========================================================== */}


        {/* <div className="podcast_part3 mt-5">
          <center>
            <div className="podcast_part3_box1 col-sm-5 py-4">VIN TANDE PODCAST AYISYEN KAPAB LA</div>
            <img src={'/images/icons/ayisyenkapab2.png'} className="img_podcast2 py-4" />
          </center>
        </div>

        <center>
          <div className="row bg-black col-sm-5">
            <div className="col-sm-4">
              <div className="podcast_part3_text1">
                <a className="bi_jett" href="https://open.spotify.com/show/673jFDD5YYnohsVpSzXosK" target="_blank" >
                  <table>
                    <tr>
                      <td><i className="bi bi-spotify"></i></td>
                      <td><div className="podcast_part3_text2 px-2">SPOTIFY</div></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="podcast_part3_text1">
                <a className="bi_jett" href="https://podcasts.apple.com/us/podcast/ayisyen-kapab/id1703700576" target="_blank" >
                  <table>
                    <tr>
                      <td><i className="bi bi-broadcast-pin"></i></td>
                      <td><div className="podcast_part3_text2 px-2">APPLE</div></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="podcast_part3_text1">
                <a className="bi_jett" href="https://www.youtube.com/@juniorpranzel" target="_blank" >
                  <table>
                    <tr>
                      <td><i className="bi bi-youtube"></i></td>
                      <td><div className="podcast_part3_text2 px-2">YOUTUBE</div></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>
          </div>
        </center> */}

        {/* ============================Part 2 */}
        {/* <center>
          <div className="col-sm-4 py-4 mt-2">
            <div className="new_about_double_box_1_text1 text-black">Junior's Social Media in Creole</div>
            <div className="new_about_part2_box1 mt-1 py-3">
              <div className="d-flex justify-content-center jett_scrollmenu">

                <div className="col-sm-2 jett_scrollmenu_in">
                  <a className="media_fa" href="https://www.facebook.com/juniorpranzel/ " target="_blank">
                    <i className=" fa fa-facebook fa-lg"></i>
                  </a>
                </div>
                <div className="col-sm-2 jett_scrollmenu_in">
                  <a className="media_fa" href=" https://www.instagram.com/juniorpranzel" target="_blank">
                    <i className=" fa fa-instagram fa-lg"></i>
                  </a>
                </div>
                <div className="col-sm-2 jett_scrollmenu_in">
                  <a className="media_fa" href="https://twitter.com/juniorpranzel" target="_blank">
                    <i className=" fa fa-twitter fa-lg"></i>
                  </a>
                </div> */}
        {/* <div className="col-sm-2 jett_scrollmenu_in">
  <a className="media_fa" href="https://www.tiktok.com/@juniorpranzel" target="_blank">
    <i className=" fa fa-tictoc fa-lg"></i>
  </a>
</div> */}
        {/* <div className="col-sm-2 jett_scrollmenu_in">
                  <a className="media_fa" href="https://www.youtube.com/@juniorpranzel" target="_blank">
                    <i className=" fa fa-youtube fa-lg"></i>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </center> */}
        {/* ============================Part 2 */}

        {/* ==============================Part 3==================== */}




      </div>
      <Footer />
    </div>
  )
}


export default Contact

