import React, { useEffect, useRef, useState } from 'react';
import Header from "../Header";
import Footer from "../Footer";
import SubmitVideo from '../SubmitVideo';


function Reaction() {





  return (
    <div>
      <Header />



    
    <SubmitVideo />




      <br /><br /><br /><br />


      <Footer />
    </div>
  )
}


export default Reaction;








