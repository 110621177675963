import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Header from "../Header";
import { Footer } from "flowbite-react";
import Menu from "./Menu";
import { NumericFormat } from 'react-number-format';
import { Result } from "postcss";


function Preorder() {

  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);







  // ====User Login only
  // const navigate = useNavigate();
  // const user = JSON.parse(localStorage.getItem('user-info'));

  // useEffect(() => {
  //   if (!localStorage.getItem('user-info')) {
  //     navigate('/')
  //   }
  // })
  // =============+DELETE==========================


  async function getData() {

    let result = await fetch("https://juniorpersist.com/backend/public/api/listpreorder");
    // let result = await fetch("http://127.0.0.1:8000/api/listpreorder");
    result = await result.json();
    setData(result)

    // const fetchInfo = () => {
    //   return fetch(url)
    //     .then((res) => res.json())
    //     .then((d) => setData(d))
    // }
  }


  return (
    <div>
      <Header />


      <div className="dash_body py-4">
        <div className="page-content container note-has-grid ">
          <Menu />
          <div className="tab-content bg-transparent">
            <div id="note-full-container" className="note-has-grid row">


              <div className="add_button container w-50">
                <h2 className="text-center">Pre-Orders List</h2>
                <hr />
              </div>


              <div className="container mt-3 mb-5">
                <div className="d-flex justify-content-center row">
                  <div className="col-md-10">

                    {
                      data.map((dataObj) =>
                        <div className="row p-2 bg-white border rounded mb-3">
                          <div className="col-md-2 mt-1"><i className="bi bi-cart h1"></i></div>
                          <div className="col-md-3 mt-1">
                            <h5>{dataObj.name}</h5>
                            <div className="mt-1 mb-1 spec-1">
                              <span className="dot"></span>
                              <span>Phone: {dataObj.phone}</span>
                              <span className="dot"></span>
                              <span>Email: {dataObj.email}</span>
                            </div>
                            <p className="text-justify text-truncate para mb-0">
                              {dataObj.description}
                            </p>
                          </div>
                          <div className="col-md-3 mt-1">
                            <div>
                              <span className="dot"></span>
                              <span>Type: {dataObj.type}</span>
                            </div>
                            <div>
                              <span className="dot"></span>
                              <span>Qty: {dataObj.qty}</span>
                            </div>
                            <div>
                              <span className="dot"></span>
                              <span>Date: {dataObj.created_at}</span>
                            </div>
                          </div>
                          <div className="align-items-center align-content-center col-md-3 border-left mt-1">
                            <div className="d-flex flex-row align-items-center">
                              <h4 className="mr-1">$ {dataObj.amount}</h4>
                            </div>
                            <div className="d-flex flex-column mt-4">
                              <Link to={"../admin/detailsproduct/" + dataObj.id} className="btn btn-primary btn-sm" >Details</Link>

                            </div>
                          </div>
                        </div>

                      )}
                  </div>
                </div>
              </div>


            </div>
          </div>


        </div>

      </div>



      <Footer />
    </div>
  )
}



export default Preorder;


