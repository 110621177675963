import { Link } from 'react-router-dom'

function Video_Junior() {



  return (
    // ========Start Join
    <div>

      <div className="content_video_2 col-sm-5 jett_block_double_slider">
        <iframe className='iframe_part2_about mt-1' src="https://player.vimeo.com/video/902817513?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"></iframe>
      </div>

    </div>
    // ========End header
  )
}

export default Video_Junior