import { Link } from 'react-router-dom'

function Menu() {
  return (
    // ========Start Menu
    <ul className="nav nav-pills p-3 bg-white mb-3 rounded-pill align-items-center">
      <li className="nav-item">
        <Link to="../admin/dash" className="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 active jett_menu_dash" id="all-category">
          <i className="bi bi-speedometer px-1"></i><span className="d-none d-md-block">Dashboard</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link to="../admin/products" className="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2  jett_menu_dash" id="all-category">
          <i className="bi bi-box px-1"></i><span className="d-none d-md-block">Products</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link to={'../admin/users'} className="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 jett_menu_dash" id="note-business">
          <i className="bi bi-people px-1"></i><span className="d-none d-md-block">Users</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link to={'../admin/preorder'} className="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 jett_menu_dash" id="note-business">
          <i className="bi bi-cart-check px-1"></i><span className="d-none d-md-block">Pre-Order</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link to={'../admin/SpeakingAdmin'} className="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 jett_menu_dash" id="note-business">
          <span className="d-none d-md-block">Speaking</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link to={'../admin/Way_to_give'} className="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 jett_menu_dash" id="note-business">
          <span className="d-none d-md-block">Way to Give</span>
        </Link>
      </li>
      <li className="nav-item">
        <span className="dropdown ">
          <span className=" dropdown-toggle nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 jett_menu_dash" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            Podcast
          </span>
          <ul className="dropdown-menu">
            <li><Link to={'../admin/Podcast_English'} className="dropdown-item jett_menu_dash" > Podcast Impact On Fire</Link></li>
            <li><Link to={'../admin/Podcast_Ayisyen'} className="dropdown-item jett_menu_dash" > Podcast Ayisyen Kapab</Link></li>
          </ul>
        </span>
      </li>


    </ul>

    // ========End Menu
  )
}

export default Menu