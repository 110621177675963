import './css/App.css';
import './css/App_mobile.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle'

import "bootstrap-icons/font/bootstrap-icons.css";
import 'font-awesome/css/font-awesome.min.css';

import { DatePicker } from 'antd';



import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
 

import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import Home from './pages/Home'
import Books from './pages/Books'
import Buy from './pages/Buy'
import Buy_lulu from './pages/Buy_lulu'
import Buy100 from './pages/Buy100'
import Buy_checkout from './pages/Buy_checkout'
import PreOrder_success from './pages/PreOrder_success'
import Speaking from './pages/Speaking'
import Influencer from './pages/Influencer'
import Advisor from './pages/Advisor'
import Tour from './pages/Tour'
import Video from './pages/Video'
import Fundraising from './pages/Fundraising'
import About from './pages/About'
import Contact from './pages/Contact'
import Donate from './pages/Donate'
import Donate_payment250 from './pages/Donate_payment250'
import Donate_payment500 from './pages/Donate_payment500'
import Donate_payment1000 from './pages/Donate_payment1000'
import ProjetDetails from './pages/ProjetDetails'
import Donate_Success from './pages/Donate_Success'
import Ebook from './pages/Ebook'
import Community_contribution from './pages/Community_contribution'
import Church_contribution from './pages/Church_contribution'
import Reviewer from './pages/Reviewer'
import Preview from './pages/Preview'
import Podcast_ayisyen_kapab from './pages/Podcast_ayisyen_kapab'
import Podcast_impact_on_fire from './pages/Podcast_impact_on_fire'
import Ways_to_contribute from './pages/Ways_to_contribute'
import Test from './pages/Test'
import Blog from './pages/Blog'
import PaymentForm from './pages/PaymentForm';
import BuyBook from './pages/BuyBook';

import Special_access001 from './pages/Special_access001'

import Login from './pages/Login'
import Profil from './pages/Profil'
import LoginInfluencer from './pages/LoginInfluencer'
import Register from './pages/Register'
import Protected from './Protected'
import Dash from './admin/Dash'
import Product from './admin/Products'
import AddProduct from './admin/AddProduct'
import DetailsProduct from './admin/DetailsProduct'
import SearchProduct from './admin/SearchProduct'
import Endorsements from './pages/Endorsements';
import Test2 from './pages/Test2';
import Test3 from './pages/Test3';
import Success_message from './pages/Success_message';
import Users from './admin/Users';
import Preorder from './admin/Preorder';
import Reaction from './pages/Reaction';
import Way_to_give from './admin/Way_to_give';
import SpeakingAdmin from './admin/SpeakingAdmin';
import Podcast_English from './admin/Podcast_English';
import Podcast_Ayisyen from './admin/Podcast_Ayisyen';
import BuyInfluencer from './pages/BuyInfluencer';
import Press from './pages/Press';
import Withdraw_success from './pages/Withdraw_success';
import Buy_checkout_no_influencer from './pages/Buy_checkout_no_influencer';
import StripeApp from './pages/StripeApp';
import SpreadTheWord from './pages/SpreadTheWord';
import Buy_inspire from './pages/Buy_inspire';
import InspireSchools from './pages/InspireSchools';
import InspireCompanies from './pages/InspireCompanies';
import InspireChurches from './pages/InspireChurches';
import InspireBook_clubs from './pages/InspireBook_clubs';
import Theletter from './pages/Theletter';
import VideoSeries from './pages/VideoSeries';
import EliteAccess from './pages/EliteAccess';
import Subscribe from './pages/Subscribe';
import Millioncopyjourney from './pages/Millioncopyjourney';
import GeneralTerms from './pages/GeneralTerms';
import Cloudinary from './pages/Cloudinary';
import Success_message_reaction from './pages/Success_message_reaction';

import Success_messageIA from './pages/Success_messageIA';
import BuyDetails from './pages/BuyDetails';
import BuyAudiobookEbook from './pages/BuyAudiobookEbook';
import BuyPhysicalBook from './pages/BuyPhysicalBook';
import Buy25 from './pages/Buy25';
import ClaimSpecial from './pages/ClaimSpecial';
import VIP from './pages/VIP';
import Success_message_vip_submit_evident from './pages/Success_message_vip_submit_evident';





function App() {
  return (
    
    <div className="App">
  
      <BrowserRouter>
          
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/books" element={<Books />} />
                <Route path="/buy" element={<Buy />} />
                <Route path="/BuyAudiobookEbook" element={<BuyAudiobookEbook />} />
                <Route path="/BuyPhysicalBook" element={<BuyPhysicalBook />} />
                <Route path="/buy_lulu" element={<Buy_lulu />} />
                <Route path="/Buy25" element={<Buy25 />} />
                <Route path="/Buy_inspire" element={<Buy_inspire />} />
                <Route path="/buy100" element={<Buy100 />} />
                <Route path="/Buy_checkout" element={<Buy_checkout />} />
                <Route path="/Buy_checkout_no_influencer" element={<Buy_checkout_no_influencer />} />
                <Route path="/PreOrder_success" element={<PreOrder_success />} />
                <Route path="/VIP" element={<VIP />} />
                <Route path="/endorsements" element={<Endorsements />} />
                <Route path="/speaking" element={<Speaking />} />
                <Route path="/SpreadTheWord" element={<SpreadTheWord />} />
                <Route path="/ClaimSpecial" element={<ClaimSpecial />} />

                <Route path="/InspireSchools" element={<InspireSchools />} />
                <Route path="/InspireCompanies" element={<InspireCompanies />} />
                <Route path="/InspireChurches" element={<InspireChurches />} />
                <Route path="/InspireBook_clubs" element={<InspireBook_clubs />} />
                <Route path="/Theletter" element={<Theletter />} />
                <Route path="/VideoSeries" element={<VideoSeries />} />
                <Route path="/EliteAccess" element={<EliteAccess />} />
                <Route path="/Subscribe" element={<Subscribe />} />
                <Route path="/Millioncopyjourney" element={<Millioncopyjourney />} />
                <Route path="/GeneralTerms" element={<GeneralTerms />} />
                <Route path="/BuyDetails" element={<BuyDetails />} />

                <Route path="/influencer" element={<Influencer />} />
                <Route path="/advisor" element={<Advisor />} />
                <Route path="/tour" element={<Tour />} />
                <Route path="/video" element={<Video />} />
                <Route path="/fundraising" element={<Fundraising />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/donate" element={<Donate />} />
                <Route path="/Donate_payment250" element={<Donate_payment250 />} />
                <Route path="/Donate_payment500" element={<Donate_payment500 />} />
                <Route path="/Donate_payment1000" element={<Donate_payment1000 />} />
                <Route path="/ProjetDetails" element={<ProjetDetails />} />
                <Route path="/donate_success" element={<Donate_Success />} />
                <Route path="/Ebook" element={<Ebook />} />
                <Route path="/Community_contribution" element={<Community_contribution />} />
                <Route path="/Church_contribution" element={<Church_contribution />} />
                <Route path="/Reviewer" element={<Reviewer />} />
                <Route path="/Preview" element={<Preview />} />
                <Route path="/Podcast_ayisyen_kapab" element={<Podcast_ayisyen_kapab />} />
                <Route path="/Podcast_impact_on_fire" element={<Podcast_impact_on_fire />} />
                <Route path="/Ways_to_contribute" element={<Ways_to_contribute />} />
                <Route path="/Reaction" element={<Reaction />} />
                <Route path="/Test" element={<Test />} />
                <Route path="/Test2" element={<Test2 />} />
                <Route path="/Test3" element={<Test3 />} />
                <Route path="/Blog" element={<Blog />} />
                <Route path="/b/:id" element={<BuyInfluencer />} />
                
                <Route path="/Success_messageIA" element={<Success_messageIA />} />
                <Route path="/Success_message" element={<Success_message />} />
                <Route path="/Success_message_reaction" element={<Success_message_reaction />} />
                <Route path="/Success_message_vip_submit_evident" element={<Success_message_vip_submit_evident />} />
                
                <Route path="/special_access001" element={<Special_access001 />} />


                <Route path="/logininfluencer" element={<LoginInfluencer />} />
                <Route path="/login" element={<Login />} />
                <Route path="/profil" element={<Profil />} />
                <Route path="/register" element={<Register />} />

                <Route path="/admin/dash" element={<Dash />} />
                <Route path="/admin/products" element={<Product />} />
                <Route path="/admin/addproduct" element={<AddProduct />} />
                <Route path="/admin/detailsproduct/:id" element={<DetailsProduct />} />
                <Route path="/admin/searchproduct" element={<SearchProduct />} />

                <Route path="/admin/users" element={<Users />} />
                <Route path="/admin/Preorder" element={<Preorder />} />
                
                <Route path="/admin/Way_to_give" element={<Way_to_give />} />
                <Route path="/admin/SpeakingAdmin" element={<SpeakingAdmin />} />
                <Route path="/admin/Podcast_English" element={<Podcast_English />} />
                <Route path="/admin/Podcast_Ayisyen" element={<Podcast_Ayisyen />} />

                <Route path="/BuyBook" element={<BuyBook />} />
                <Route path="/Press" element={<Press />} />
                <Route path="/Withdraw_success" element={<Withdraw_success />} />
                <Route path="/StripeApp" element={<StripeApp />} />
                <Route path="/Cloudinary" element={<Cloudinary />} />

                {/* <Route path="/addproduct" element={<AddProduct />}  >
                  <Protected Cmp={AddProduct} />
                </Route> */}
            </Routes>
      
      </BrowserRouter>
    </div>
  );
}

export default App;
