// App.js
import React from 'react';
import EpubReader from '../EpubReader';

function App() {
 

  return (
    <div className="App">
      <center>

      <div className='col-sm-6 m-auto'>
      <EpubReader  />
      </div>
      </center>
    </div>
  );
}

export default App;



