import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
function Success_messageIA() {


  return (
    <div>
      <Header />

      <div className="bookSucIA">
        <div class="row g-0">

          <div class="col-sm-6 ">
            <div className="bookSucIA_box1">
              <div className="success-inner text-white text-center py-5">
                <div className="py-4">

                  <h2 className="text-white py-2">
                    <i className="fa fa-check"></i><br /><span>Your information has been sent successfully!</span>
                  </h2>
                  <p className="jett87345_1 pb-3">
                    Thank you for submitting this form. A member of our team will be in touch with you shortly! Until then, stay awesome!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 ">
            <div className="bookSucIA_box2">
              <center>
                <img className="bookSucIA_img1" src={'/images/img10.png'} />
              </center>
            </div>
          </div>

        </div>
      </div>






      <div className="bookSucIA_box3">
      <div className="bookSucIA_box3_in">
      <div className="bookSucIA_box3_text1">Download the App</div>
      <div className="bookSucIA_box3_text2">For the ultimate Audiobooks.com experien, download our easy-to-use app for iOS or Android</div>
      <div className="bookSucIA_box3_text3">Access exclusive content recommendations, 10,000 free audiobooks, and a community of book lovers in our easy-tu-use app.</div>

      <div className="bookSucIA_box3_img py-3">
        <img src={'/images/mobile/mobile1.svg'} className="img_mobile1" />
        <img src={'/images/mobile/mobile2.svg'} className="img_mobile2" />
      </div>
        
      </div>
      </div>






      
      <Footer />
    </div>
  )
}


export default Success_messageIA